// DefaultQuestions.js
import React from "react";

const DefaultQuestions = ({ sendMessage }) => {
  const questions = [
    "What should be range to get Fair Score?",
    "What should be range to get Very Good Score?",
    "What should be range to get Excellent Score?",
  ];

};

export default DefaultQuestions;