import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, Grid, Stack, Typography, useMediaQuery  } from '@mui/material';
import { useDispatch } from 'react-redux';
import { fetchData } from '../redux/apiSlice';
import { useNavigate,Link,useParams } from 'react-router-dom';
import AuthUser from "../Components/Auth/AuthUser";
import config from '../AppConfig';
import LanguageIcon from '@mui/icons-material/Language';
import { useTheme } from '@mui/material/styles';


function EnrollHeader(props) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const [cobrandLogo, setCobrandLogo] = useState(null);
    const [creditSnapshotObj, setCreditSnapshotObj] = useState(null);
    const { http } = AuthUser();
    const [softwareLogo, setSoftwareLogo] = useState();
    const [initialLogo, setInitialLogo] = useState('test');
    const { referencecode } = useParams();
    const [refer, setRefer] = useState();
    const [logoName, setLogoName] = useState();
    const [changeLineColor, setChangeLineColor] = useState();
    const [hideNavigation, setHideNavigation] = useState(props.hideNavigation != undefined ? props.hideNavigation : 'false');
    const [isMobileParams, setIsMobileParams] = useState(props.isMobileParams != undefined ? props.isMobileParams : 'false');
    
    const getAidDetails = async () => {
        // const apiUrl = "/getCobrandAid/details/"+props.aid;
        const apiUrl = "/getCobrandAid/details/"+props.aid+'/'+props.logoName;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res){
            if(res.success == true){
                setLogoName(res.logoName);
                setCobrandLogo(res.companyLogo);
                props.onValueChange(res.creditSnapshot);
                setCreditSnapshotObj(res.creditSnapshot);
                if (localStorage.getItem("credentialsStatus") != null) {
                    localStorage.removeItem("credentialsStatus");
                }
                localStorage.setItem("credentialsStatus", res.credentialsStatus)
                localStorage.setItem("cobrandCompanyName", res.cobrand_company_name)
            }else{
                if (localStorage.getItem("credentialsStatus") != null) {
                    localStorage.removeItem("credentialsStatus");
                }
            }
        }
    }

    const getAffiliateInfo = async () => {
        localStorage.setItem("linkedAccFlag", 0);
        const apiUrl = "/getAffiliateInfo/"+props.aid;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if(res){
            // console.log("info :" +res);  
            if(res.success == true){  
                localStorage.setItem("linkedAccFlag", 1);

            }else{
                localStorage.setItem("linkedAccFlag", 0);
            }
        }
    }
    
    const getSoftwareChannelImage = () =>{
        var trimmedString = undefined;
        if (referencecode == "" || referencecode == undefined) {
            
		} else {
			trimmedString = referencecode.replace(/=+$/, ''); // Remove trailing '=' characters
            trimmedString = decodeBase64(trimmedString);
			setRefer(trimmedString);
		}
        
        if(trimmedString != undefined) {
            http.get('/getSoftwareChannelImage?softwareName='+trimmedString).then((res) => {
                setSoftwareLogo(res.data);
                setInitialLogo("original");
            });
        }else{
            setInitialLogo("original");
        }
    }

    useEffect(()=>{
        // alert(props.customColorArray.primaryColor)
        setChangeLineColor(localStorage.getItem("customColor"))
        getAidDetails();
        getSoftwareChannelImage(); 
        getAffiliateInfo(); 
    },[props.aid]);

    useEffect(()=>{
        if(props.hideNavigation != undefined) {
            setHideNavigation(props.hideNavigation);
        }
     },[props.hideNavigation]);
 
     useEffect(()=>{
        if(props.isMobileParams != undefined) {
         setIsMobileParams(props.isMobileParams);
        }
     },[props.isMobileParams]);

    const decodeBase64 = (str) => {
		try {
			return atob(str);
		} catch (error) {
			console.error('Error decoding base64:', error);
			return '';
		}
	};


    const navigate = useNavigate();
    return (
        hideNavigation === 'false' && isMobileParams === 'false' ?
        <Box id="primaryColor" sx={{ flexGrow: 1, boxShadow: 0, justifyContent: "left", alignItems: "left", borderBottomWidth:'3px',borderBottomStyle:'solid',borderBottomColor:props.customColorArray?`${props.customColorArray.primaryColor}`: "#30BE2D" }}>
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ padding: "0 2%", margin: "0" }} mb={2}>
                
                <Grid item lg={12} md={12} sm={12} xs={12}  >
                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{display:"flex",justifyContent:"flex-start"}}>
                        <Grid item lg={window.location.href.includes("login")?8:12} md={window.location.href.includes("login")?8:12} sm={12} xs={12} className="headerlogo" sx={{display:"flex",justifyContent:"start"}}>
                            {
                                logoName == 'creditSnapShot'? 
                                    <img style={{height: 90, width: 300}} src={config.BACKEND_URL+'/images/companyLogo/'+cobrandLogo}  />
                                :
                                    initialLogo == "test" ? '' : 
                                        // softwareLogo && refer !== "ClientDisputeManager"? 
                                        // <>
                                        //     <Typography variant="caption" sx={{margin:'1% 2% 0 0'}}><i>Partnered<br />with</i></Typography>
                                        //     <Divider orientation="vertical" style={{ height: '100%', width: '1px', margin:'0 2% 0 0' }} />
                                        //     <img alt="#" style={{height:"60px"}} src={config.BACKEND_URL+'images/SoftwareChannelMasterLogo/'+ softwareLogo} />
                                        // </>
                                        // :  
                                        (softwareLogo && props.logoName === "referral") || (logoName === 'cobranding' && cobrandLogo) ?
                                        <Grid container direction="row" justifyContent={isMobile ? "center" : "space-between"} alignItems="center" spacing={4} sx={{ display:isMobile ?'block':''}}>
                                            <Grid item sx={{ display:isMobile ?'flex !important':'',justifyContent:isMobile ?'center !important':''}}>
                                                <Stack  id="userwayBtn" tabIndex="0" direction="row" className={isMobile ? "" : "userWayCss"} sx={{justifyContent:'space-evenly',
                                                    alignSelf:"center",border:'1px solid gray',padding:'0.5%',borderRadius:'5px',cursor:'pointer', margin: '0'
                                                }} >                                
                                                        <LanguageIcon />
                                                        <Typography>en/esp/fr/...</Typography>                                
                                                </Stack>  
                                            </Grid>
                                            <Grid item sx={{paddingTop : isMobile ? "1% !important" : '' }}>
                                                <img alt="#" src={require('../assets/images/bureau-logos.png')}  style={{height:'60px'}} className='headerLogoImg'/>                                   
                                            </Grid>
                                        </Grid>
                                        :
                                        <>
                                            <img alt="#" src={require('../assets/images/bureau-logos.png')}  className='headerLogoImg'/>                                   
                                        </>
                            }
                           
                        </Grid>
                        {window.location.href.includes("login") && !window.location.href.includes("epicproreport")
                            ? 
                            <Grid item lg={4} md={4} sm={12} xs={12} className="headerlogo" pt={1} pb={1}>
                                <Button onClick={() => {
                                    navigate("/register");
                                    localStorage.setItem('origin', "mfsnhomepage");
                                }} variant="contained">Become an Affiliate</Button>

                            </Grid>
                            :
                            <></>
                           
                        }
                        {
                        //     logoName == 'cobranding'? 
                        //     cobrandLogo ?
                        //     <Stack  id="userwayBtn" tabIndex="0" direction="row" className="userWayCss" sx={{justifyContent:'space-evenly',
                        //         alignSelf:"center",border:'1px solid gray',padding:'0.5%',borderRadius:'5px',cursor:'pointer',
                        //     }}>                                
                        //             <LanguageIcon />
                        //             <Typography>en/esp/fr/...</Typography>                                
                        //     </Stack>  
                        // : null
                        // : null

                        }
                    </Grid>
                </Grid>
            </Grid>
        </Box>
        :
        ''
    );
}
export default EnrollHeader;