import React, {useState} from 'react';
import DefaultLayout from '../../../Components/DefaultLayout';
import { Grid,Stack,useMediaQuery } from '@mui/material';
import { Typography, Card, List, ListItem, ListItemDecorator, ListItemContent, Button } from '@mui/joy';
import AuthUser from '../../../Components/Auth/AuthUser';
import swal from "sweetalert";
import Freez from '../../../Components/Loader/Loader'
import Backdrop from '@mui/material/Backdrop';
import { useDispatch } from 'react-redux';
import {  postData } from '../../../redux/apiSlice';
import {  useNavigate } from "react-router-dom";
function Credit(props) {
  //Neccessary variables declearation start
  const dispatch = useDispatch();
  //loader function and variable start
  const [freez, setFreez] = React.useState(false);
  const [bounce, setBounce] = useState(props.bounceFlag);
      const isMobile = useMediaQuery('(max-width:600px)');
  
  const handleFreezStop = () => {
    setFreez(false);
  };
  const handleFreezStart = () => {
    setFreez(true);
  };
  //loader function and variable end
  //Snapshot start
  const enrollSnapshot = async() => {
    handleFreezStart();
    const apiUrl = '/updateSnapshotTerms';
    const args = {
        status:1,
    }
    const res = await dispatch(postData({apiUrl,args})).unwrap();
    if(res) {
      if (res.success === true) {
        handleFreezStop();
        window.location.reload();
      } else {
        handleFreezStop();
        swal({
          title: "Failed",
          text: res.message,
          icon: "error",
          button: "Ok",
        });
      }
    }else {
      handleFreezStop();
      swal({
        title: "Failed",
        text: res.message,
        icon: "error",
        button: "Ok",
      });
    }
  }
  //Snapshot end
  function ContentLayout() {
    return (
      <>
        <Card variant='outlined'>
          <Grid container item lg={12} md={12} sm={12} xs={12}>
            <Typography level="h5" sx={{ color: "#ED7E2E" }}>LEADS!$$$   LEADS!$$$   LEADS!$$$</Typography>
          </Grid>
          <Grid container item lg={12} md={12} sm={12} xs={12}>
            <Grid container item lg={12} md={12} sm={12} xs={12}>
              <Typography level="h4" color="primary">GROW YOUR BUSINESS</Typography>
            </Grid>
            <Grid item lg={7} md={7} sm={12} xs={12}>
              <Typography>With <b>Credit Snapshot,</b> a brand new lead generation tool that is available <b>ONLY TO OUR AFFILIATES!</b></Typography>
              <Typography>Convert more visitors from your advertising</Typography>
              <Typography>referrals or social media. Make your website interactive with <b>This INCREDIBLE FREE OFFER!</b></Typography>
              <Typography level="h4" color="primary">What’s the offer?</Typography>
              <List
                aria-labelledby="ellipsis-list-demo"
                size="sm"
                sx={{"--ListItem-minHeight": "20px",
                '--ListItemDecorator-size': '20px',}}
              >
                <ListItem >
                  <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 1 }}>
                    <img src={require("../../../assets/images/DashIcon.png")} />
                  </ListItemDecorator>
                  <ListItemContent sx={{ mr: 0.5 }}><Typography><b>FREE</b> Equifax Credit score</Typography></ListItemContent>
                </ListItem>
                <ListItem>
                  <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 1 }}>
                    <img src={require("../../../assets/images/DashIcon.png")} />
                  </ListItemDecorator>
                  <ListItemContent sx={{ mr: 0.5 }}><Typography><b>FREE</b> credit report summary of negative items</Typography></ListItemContent>
                </ListItem>
                <ListItem>
                  <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 1 }}>
                    <img src={require("../../../assets/images/DashIcon.png")} />
                  </ListItemDecorator>
                  <ListItemContent sx={{ mr: 0.5 }}><Typography><b>FREE</b> debt utilization calculation</Typography></ListItemContent>
                </ListItem>
                <ListItem>
                  <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 1 }}>
                    <img src={require("../../../assets/images/DashIcon.png")} />
                  </ListItemDecorator>
                  <ListItemContent sx={{ mr: 0.5 }}><Typography><b>Pages can be translated with one click into 50+ other languages!</b></Typography></ListItemContent>
                </ListItem>
              </List>
            </Grid>
            <Grid item lg={5} md={5} sm={12} xs={12}>
              <Typography level="h4" color="primary" mb={1}>Credit Snapshot Credit Report Summary</Typography>
              <img src={require("../../../assets/images/Scorepagenew.png")} width={'100%'} height='auto' />
            </Grid>
          </Grid>
        </Card>
        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Card variant="plain" sx={{boxShadow:"none !important"}}>
              <Typography level="h4" color="primary">What do I get?</Typography>
              <List
                aria-labelledby="ellipsis-list-demo"
                size="sm"
              >
                <ListItem>
                  <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 1 }}>
                    <img src={require("../../../assets/images/DashIcon.png")} />
                  </ListItemDecorator>
                  <ListItemContent sx={{ mr: 0.5 }}><Typography>More leads with verified email addresses and verified phone numbers</Typography>
                  </ListItemContent>
                </ListItem>
                <ListItem>
                  <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 1 }}>
                    <img src={require("../../../assets/images/DashIcon.png")} />
                  </ListItemDecorator>
                  <ListItemContent sx={{ mr: 0.5 }}><Typography>End to end customer experience branded with your logo and phone number </Typography>
                  </ListItemContent>
                </ListItem>
                <ListItem>
                  <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 1 }}>
                    <img src={require("../../../assets/images/DashIcon.png")} />
                  </ListItemDecorator>
                  <ListItemContent sx={{ mr: 0.5 }}><Typography>Alerts - get a text when a new lead comes in </Typography>
                  </ListItemContent>
                </ListItem>
                <ListItem>
                  <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 1 }}>
                    <img src={require("../../../assets/images/DashIcon.png")} />
                  </ListItemDecorator>
                  <ListItemContent sx={{ mr: 0.5 }}><Typography>Credit Snapshot accounts automatically expire. Consumers can upgrade to your affiliate link through you OR 
                      directly from the Credit Snapshot results page - so you’ll be eligible to earn commission!
                  </Typography>
                  </ListItemContent>
                </ListItem>
              </List>
            </Card>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Card variant="plain" sx={{boxShadow:"none !important"}} >
              <Typography level="h4" color="primary">How do I use it?</Typography>
              <List
                aria-labelledby="ellipsis-list-demo"
                size="sm">
                <ListItem>
                  <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 1 }}>
                    <img src={require("../../../assets/images/DashIcon.png")} />
                  </ListItemDecorator>
                  <ListItemContent sx={{ mr: 0.5 }}><Typography>Register for Credit Snapshot right from this page</Typography>
                  </ListItemContent>
                </ListItem>
                <ListItem>
                  <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 1 }}>
                    <img src={require("../../../assets/images/DashIcon.png")} />
                  </ListItemDecorator>
                  <ListItemContent sx={{ mr: 0.5 }}><Typography>Once approved, use the unique link we provide to post the offer immediately on
                    your social media, or to use in email marketing campaigns, or to set up a
                    customizable pop-up on your website</Typography>
                  </ListItemContent>
                </ListItem>
                <ListItem>
                  <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 1 }}>
                    <img src={require("../../../assets/images/DashIcon.png")} />
                  </ListItemDecorator>
                  <ListItemContent sx={{ mr: 0.5 }}><Typography>Set up is only 3 easy steps and takes just a few minutes!</Typography>
                  </ListItemContent>
                </ListItem>
                <ListItem>
                  <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 1 }}>
                    <img src={require("../../../assets/images/DashIcon.png")} />
                  </ListItemDecorator>
                  <ListItemContent sx={{ mr: 0.5 }}><Typography>See and manage all your  leads in the affiliate portal</Typography>
                  </ListItemContent>
                </ListItem>
              </List>
            </Card>
          </Grid>
        </Grid>
        <Card mt={1} variant="plain" sx={{boxShadow:"none !important"}}  >
          <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
            <Grid item lg={6} md={6} sm={12} xs={12} sx={{flexGrow:1}}>
            <Card variant="plain" sx={{boxShadow:"none !important"}} >
              <Typography level="h4" color="primary">What’s the cost</Typography>
              <Typography sx={{fontWeight:500,mb:1,mt:1}}>Credit Snapshot usage and your branded website is <b>FREE!*</b>
               </Typography>
              <Typography sx={{lineHeight:"250%"}}>The Credit Snapshot consumer is qualified and has shown genuine intent to seek your help!  The feedback we get is that these leads are high converting.  Just think if you had to spend your own money on advertising and other costs to get these - the cost would be at least $10 - $15 each!</Typography>
             </Card>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12} sx={{flexGrow:1}}>
            <Card variant="plain" sx={{boxShadow:"none !important"}}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography level="h4" color="primary">What’s New</Typography>
                <List aria-labelledby="ellipsis-list-demo" size="sm">
                    <ListItem style={{ display: 'flex', alignItems: 'center' }}>
                      <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 1 }}>
                        <img
                          src={require("../../../assets/images/DashIcon.png")}
                          alt="Dash Icon"
                          // Adjust size if needed
                        />
                      </ListItemDecorator>
                      <ListItemContent>
                        <Typography >
                          <Stack direction="row" alignItems="center" spacing={1} sx={{display:isMobile?"flow":"flex"}}>
                            <img
                              src={require("../../../assets/images/Chats.png")}
                              style={{ width: '27px' }}
                              alt="Chats Icon"
                            />
                            <img
                              src={require("../../../assets/images/Emailing.png")}
                              style={{ width: '27px' }}
                              alt="Emailing Icon"
                            />&nbsp;Chat and Email Features-Interact Directly With Leads
                          </Stack>
                        </Typography>
                      </ListItemContent>
                    </ListItem>
                    <ListItem style={{ display: 'flex', alignItems: 'center' }}>
                      <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 1 }}>
                        <img
                          src={require("../../../assets/images/DashIcon.png")}
                          
                          alt="Dash Icon"
                        />
                      </ListItemDecorator>
                      <ListItemContent>
                        <Typography>
                          <Stack direction="row" alignItems="center" spacing={1} sx={{display:isMobile?"flow":"flex"}}>
                            <img
                              src={require("../../../assets/images/playing.png")}
                              style={{ width: '27px' }}
                              alt="Play Icon"
                            />&nbsp;Upload Your Company Videos -Build Trust And Start Building Up To The Close
                          </Stack>
                        </Typography>
                      </ListItemContent>
                    </ListItem>

                    <ListItem style={{ display: 'flex', alignItems: 'center' }}>
                      <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 1 }}>
                        <img
                          src={require("../../../assets/images/DashIcon.png")}
                         
                          alt="Dash Icon"
                        />
                      </ListItemDecorator>
                      <ListItemContent>
                        <Typography>
                          <Stack direction="row" alignItems="center" spacing={1} sx={{display:isMobile?"flow":"flex"}}>
                            <img
                              src={require("../../../assets/images/LinkIcon.png")}
                              style={{ width: '27px' }}
                              alt="Link Icon"
                            />&nbsp;Your CRM Link For Direct Onboarding Of The Lead To Your Own System
                          </Stack>
                        </Typography>
                      </ListItemContent>
                    </ListItem>

                      <ListItem style={{ display: 'flex', alignItems: 'center' }}>
                        <ListItemDecorator sx={{ alignSelf: 'flex-start', m: 1 }}>
                          <img
                            src={require("../../../assets/images/DashIcon.png")}
                           
                            alt="Dash Icon"
                          />
                        </ListItemDecorator>
                        <ListItemContent>
                          <Typography>
                            <Stack direction="row" alignItems="center" spacing={1} sx={{display:isMobile?"flow":"flex"}}>
                              <img
                                src={require("../../../assets/images/Automationicon.png")}
                                style={{ width: '27px' }}
                                alt="Automation Icon"
                              />&nbsp;Zapier Integration
                            </Stack>
                          </Typography>
                        </ListItemContent>
                      </ListItem>
                </List>

              </Grid>
              {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                <img src={require("../../../assets/images/creditregister.png")} width="300" height="300" />
              </Grid> */}
              </Card>
            </Grid>
          </Grid>
        </Card>
        <Card variant="plain" sx={{boxShadow:"none !important"}}>
            <Grid container item lg={12} md={12} sm={12} xs={12}  sx={{mb:1}}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
              <Card variant="plain" sx={{boxShadow:"none !important",minHeight: isMobile ? "300px" : "200px", maxHeight: isMobile ? "350px" : "250px"}}>
              <Typography level="h4" color="primary" mb={2}>Enable Credit Snapshot Now</Typography>
              
              <Button onClick={enrollSnapshot} disabled={bounce} sx={{
                    bgcolor: "black",
                    color: "white",
                    borderRadius:"10px",
                    fontSize:"19px",
                    fontWeight:"bold",
                    fontFamily:'rubik "serif"',
                    minWidth: 150,
                    maxWidth:200,
                    animation: "blink 1s infinite",
                    "@keyframes blink": {
                      "0%": { bgcolor: "black", color: "white" },
                      "50%": { bgcolor: "#FF9300", color: "white" },
                      "100%": { bgcolor: "black", color: "white" },
                    },
                    "&:hover": {
                      bgcolor: "#FF9300",
                      color:"white"
                    },
                  }}>ENABLE NOW</Button>
                 {bounce ? <Typography sx={{mt:1,mb:1,color:"red"}}><b>Not able to generate your document as the communication email is invalid. Please update it from company details panel</b></Typography> : ''}
              <Typography sx={{mt:1,mb:1}}><b>Question?</b></Typography>
              <Typography sx={{mt:1,mb:1}}><b>Call or text us: 1-888-548-2008</b></Typography>
              <Typography sx={{mt:1,mb:1,color:"grey"}}><b><i>*Terms and Conditions Apply. We reserve the right to make changes at any time.</i></b>   </Typography> 
              </Card>
              </Grid> 
              <Grid container item lg={6} md={6} sm={12} xs={12}>
                  <Card variant="plain" sx={{width:"100%",minHeight: isMobile ? "300px" : "200px", maxHeight: isMobile ? "350px" : "250px" }}>
                  <Typography level="h6" color="primary">Customer Experience</Typography>
                      <iframe
                      width="100%"
                      height="200px"
                      src={require("../../../assets/images/CustomerExperience.mp4")}
                      title="Video Player"
                      // frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      ></iframe>
                  </Card>
              </Grid>
            </Grid>
             
        </Card>
      </>
    );
  }
  return (
    <div>
      <DefaultLayout content={<ContentLayout />} />
      {/* loader code start */}
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={freez}
          onClick={handleFreezStop}
        >
          <Freez />
        </Backdrop>
      </div>
      {/* loader code end */}
    </div>
  );
}


export default Credit;