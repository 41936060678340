import React, { useState, useEffect } from "react";
import DefaultLayout from '../../../Components/DefaultLayout';
import AuthUser from '../../../Components/Auth/AuthUser';
import { DialogContent, Grid, Select, FormControl, MenuItem, InputLabel, Box, TextField, Dialog, InputAdornment, DialogActions, OutlinedInput, Backdrop, DialogTitle, Tab, useMediaQuery } from '@mui/material';
import { Typography, Card, CardContent, CardOverflow, Button, List, ListItem, ListItemDecorator, ListItemContent } from '@mui/joy';
import IconButton from '@material-ui/core/IconButton';
import config from '../../../AppConfig';
import * as Yup from 'yup';
import swal from "sweetalert";
import {  useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';

import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Freez from '../../../Components/Loader/Loader';

import ContentElement from "./ContentElement";
import { snapshot ,updateCrsSignupEnabled } from '../../../redux/authSlice';
import jsPDF from 'jspdf';
import { exampleHTML } from './CreditTermsAndCondition';
import { styled } from '@mui/material/styles';
import CompanyDetails from '../../../Components/CreditSnapshot/CompanyDetails.js';
import UploadSnapshotLogo from '../../../Components/CreditSnapshot/UploadSnapshotLogo.js';
import SnapshotSocialMediaLink from '../../../Components/CreditSnapshot/SnapshotSocialMediaLink.js';
import SnapshotPopupSetUp from '../../../Components/CreditSnapshot/SnapshotPopupSetUp.js';
import SnapshotCodeToSetPopup from '../../../Components/CreditSnapshot/SnapshotCodeToSetPopup.js';
import {TabContext,TabPanel,TabList} from '@mui/lab';
import CloseIcon from "@mui/icons-material/Close";

let logoFlag = 0;
let blankValueIndexes1 = [];
let blankValueIndexes2 = [];
let operationalhoursvalues = [];
let selectedaid;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 350,
        },
    },
};


let customColorArray = {};
function CreditSnapFirst(props) {
    //fetch snapshot details start
    const dispatch = useDispatch();
    //coman variables
    const { user } = AuthUser();
    const [creditinfo, setCreditInfo] = useState([]);
    const [terms,setTermsData]=useState([]);
    const [pids, setPids] = useState([]);
    const [firstlogoUrl, setFirstLogoUrl] = useState(null);
    const [defaultPhoneNumber, setdefaultPhoneNumber] = useState();
    const [receivedphone, setReceivedPhone] = useState();
    const [defaultEmail, setdefaultEmail] = useState();
    const [receivedemails, setReceivedemails] = useState();
    const [receivedpid, setReceivedPid] = useState();
    const [receivedaid, setReceivedAid] = useState([]);
    const [firstAid, setFirstAid] = useState([]);
    const [receivedshift, setReceivedShift] = useState([{ label: "Monday-Friday", timings: [{ start: "09:00", end: "18:00" }] },
    { label: "Saturday-Sunday", timings: [{ start: "09:00", end: "18:00" }] },
    ]);
    const isMobile = useMediaQuery('(max-width:600px)');
    const [link, setLink] = useState();
    const [companyId, setCompanyId] = useState();
    const [popupHtml, setPopupHtml] = useState();
    const [snapshotToken, setSnapshotToken] = useState();
    const [snapshotStr, setSnapshotStr] = useState();
    const [value, setValue] = React.useState('1');
    const [freez, setFreez] = React.useState(false);
    const [business, setBusiness] = useState();
    const [aidlist1, setAidList1] = useState([]);
    const [defaultOutOfOfficeMsg, setDefaultOutOfOfficeMsg] = useState('');
    const getAidlist = async (companyid) => {

        const apiUrl = '/fetchCompanyAIDList/' + companyid;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            const uniqueData = Array.from(new Set(res.data.map(item => item.id))).map(
                id => res.data.find(item => item.id === id)
            );
            setAidList1(uniqueData);
            setFirstAid(res.data ? res.data[0].id : '');
        }
    }

    //fetch Company AID list end

    const fetchCreditInfo = async () => {
        const apiUrl = '/fetchCreditInfo';
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            setFirstLogoUrl(res.data.company.company_logo_url);
            setCreditInfo(res);
            console.log("credit_snapshot_link:"+res.data.credit_snapshot_link);
            setTermsData(res.data);
            setPids(res.data.pid);
            setBusiness(res.data.company.company_name)
            setCompanyId(res.data.company.id)
            setdefaultPhoneNumber(res.data.reference_affiliates.phone_no);
            setdefaultEmail(res.data.reference_affiliates.communication_email);
            // setReceivedPhone(res.data.snapshot_phone_no);
            setReceivedPid(res.data.upgrade_pid);
            setReceivedAid(res.data.aidid);
            setSnapshotToken(res.data.snapshot_token);
            setSnapshotStr(`<script>window.user_token = '` + res.data.snapshot_token + `';var scriptTag  = document.createElement('script');
            scriptTag.type = 'text/javascript';scriptTag.src = 'https://api.myfreescorenow.com/public/js/snapshot.js';
            var s= document.getElementsByTagName('script')[0];s.parentNode.insertBefore(scriptTag,s);</script>`)

            setLink(config.BASE_URL + res.data.link);

            await dispatch(snapshot(res.data));
            await dispatch(updateCrsSignupEnabled(res.data));
            localStorage.removeItem('SnapShotStatus');
            localStorage.setItem('SnapShotStatus', res.data.snapshot_status);
            localStorage.setItem('creditSnapshotLink', res.data.credit_snapshot_link);
            localStorage.setItem('crsSignupEnabled', res.data.crs_signup_enabled);

            getAidlist(res.data.company.id);

            if (res.data.working_hours != null) {
                setReceivedShift(res.data.working_hours);
            }
            getPopupHtml(res.data.company.id);
            if (res.data) {
                const attr = JSON.parse(res.data.snapshot_popup_attribute_json);
                customColorArray = {
                    popupHeaderBackground: attr ? attr.popupHeaderBackground : '#00BBD3',
                    popupHeaderText: attr ? attr.popupHeaderText : '#000000',
                    popupBodyBackground: attr ? attr.popupBodyBackground : '#ffffff',
                    popupBodyText: attr ? attr.popupBodyText : '#000000',
                    popupButtonBackground: attr ? attr.popupButtonBackground : '#FF9400',
                    popupButtonText: attr ? attr.popupButtonText : '#FFFFFF',
                    popupIconColor: attr ? attr.popupIconColor : '#ff9400'
                }
            }
            const phoneNumberArray = res.data.snapshot_phone_no;
            if(phoneNumberArray != null) {
                if (Object.values(phoneNumberArray).length > 0) {
                    const formattedData = phoneNumberArray.map(item => ({
                        ...item,
                        "number": formatPhoneNumber(item.number)
                    }));
                    setReceivedPhone(formattedData);
                }
            }
          

            const emailArray = res.data.snapshot_email;
            if (emailArray!= null && Object.values(emailArray).length > 0) {
                const formattedData = emailArray.map(item => ({
                    ...item,
                    "email": item.email
                }));
                setReceivedemails(formattedData);
            }
           
        }
    }

    //fetch snapshot details end
    //useeffect start
    useEffect(() => {
        fetchCreditInfo();
    }, [])

    useEffect(() => {
        var message = '';
        var weekOff = '';
        if(receivedshift && receivedshift[0]) {
            const weekdaysStartingHours = (Number(receivedshift[0]['timings'][0]['start'].split(':')[0]) % 12) || 12;
            const weekdaysStartingMinutes = ((receivedshift[0]['timings'][0]['start'].split(':')[1]));
            const weekdaysStartingHoursPeriod = (Number(receivedshift[0]['timings'][0]['start'].split(':')[0])) >= 12 ? "PM" : "AM";
            
            const weekdaysEndingHours = (Number(receivedshift[0]['timings'][0]['end'].split(':')[0]) % 12) || 12;
            const weekdaysEndingMinutes = (receivedshift[0]['timings'][0]['end'].split(':')[1]);
            const weekdaysEdingHoursPeriod = ((receivedshift[0]['timings'][0]['end'].split(':')[0])) >= 12 ? "PM" : "AM";
            message = `Thank you for contacting us. Our offices are closed now. Our work hours are between ${weekdaysStartingHours + ':' + weekdaysStartingMinutes} ${weekdaysStartingHoursPeriod} and ${weekdaysEndingHours + ':' + weekdaysEndingMinutes} ${weekdaysEdingHoursPeriod} on weekdays`;
        }
        if(receivedshift && receivedshift[1]) {
            weekOff = 1;
            const weekendStartingHours = (Number(receivedshift[1]['timings'][0]['start'].split(':')[0]) % 12) || 12;
            const weekendStartingMinutes = ((receivedshift[1]['timings'][0]['start'].split(':')[1]));
            const weekendStartingHoursPeriod = (Number(receivedshift[1]['timings'][0]['start'].split(':')[0])) >= 12 ? "PM" : "AM";
            
            const weekendEndingHours = (Number(receivedshift[1]['timings'][0]['end'].split(':')[0]) % 12) || 12;
            const weekendEndingMinutes = (receivedshift[1]['timings'][0]['end'].split(':')[1]);
            const weekendEdingHoursPeriod = ((receivedshift[1]['timings'][0]['end'].split(':')[0])) >= 12 ? "PM" : "AM";
            message = message + ` and between ${weekendStartingHours + ':' + weekendStartingMinutes} ${weekendStartingHoursPeriod} and ${weekendEndingHours + ':' + weekendEndingMinutes} ${weekendEdingHoursPeriod} on weekends. `;
        }

            if(weekOff == 0) {
                message = message +".";
            }
            message = message + "Please contact us during the same.";
            setDefaultOutOfOfficeMsg(message);
       
    }, [receivedshift])

    const getPopupHtml = async (id) => {
        const apiUrl = '/getPopupHtml/' + id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            if (res.success) {
                setPopupHtml(res.data);
            }
        }
    }

    function formatPhoneNumber(phoneNumber) {
        const cleaned = ('' + phoneNumber).replace(/\D/g, ''); // Remove non-digits
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }

        return phoneNumber; // Return original input if it doesn't match the expected format
    }

    	//pdf start here
    const handleDownloadPDF = async (data) => {
        // console.log(data);
    const firstName = data ? data.reference_affiliates.first_name ? data.reference_affiliates.first_name:"" : "";
    const lastName = data ? data.reference_affiliates.last_name ? data.reference_affiliates.last_name:"" : "";
    const fullName= firstName + " " +lastName;
    const companyName = data ? data.company.company_name ? data.company.company_name :"" : "";
    const title =  data ? data.company.title ? data.company.title :"" : "";
    const updateDate = data ? data.updated_at ? data.updated_at :"" : "";
    const sign = data ? data.sign_image : "" ;
    const executiveSign = data ? data.executive : "" ;
    const dateFormat = new Date(updateDate);
    const options = { timeZone: 'America/Los_Angeles', month: '2-digit', day: '2-digit', year: 'numeric' };
    const formatter = new Intl.DateTimeFormat('en-US', options);
        const [
          { value: month },,
          { value: day },,
          { value: year }
        ] = formatter.formatToParts(dateFormat);
    
    const formattedDate = `${month}/${day}/${year}`;
    const advertiser = {
        adverstise: companyName,
        signature: fullName,
        printName: fullName,
        title: title,
        date: formattedDate
    };

    const sample =` <div style="font-size:16px;padding: 5px 0px;width:750px;text-align:justify;"> 
    <p>
    <span style="font-size: 18px; text-align: center;"><b>Standard Terms and Conditions for Lead Generation Service: Credit Snapshot (Last revised Jan 12 2025)</b></span>
    </p>
    <br><br>
        ${exampleHTML(advertiser)}
        <br>
        <div class="forMD-12" style="display: flex; flex-wrap: wrap; margin-top: 2px;">
        <div class="forMD-6" style="flex: 1; min-width: 65%;">
            <p style="margin: 0; padding: 2px;"><strong>Advertiser: ${companyName}</strong></p>
            <p style="margin: 0; padding: 2px; display: flex; align-items: center;">
                <strong>Signature:</strong>
                ${sign ? 
                    `<img src="${sign}" alt="Signature" style="width: 40%; height: auto;" />` 
                    : 
                    `<span style="margin-left: 10px;">${fullName}</span>`
                }
            </p>
            <p style="margin: 0; padding: 2px;"><strong>Print Name: ${fullName}</strong></p>
            <p style="margin: 0; padding: 2px;"><strong>Title: ${title}</strong></p>
            <p style="margin: 0; padding: 2px;"><strong>Date: ${formattedDate}</strong></p>
        </div>
        <div class="forMD-6" style="flex: 1; min-width: 35%;">
            <p style="margin: 0; padding: 2px;"><strong>Vendor: MyFreeScoreNow Inc.</strong></p>
            <p style="margin: 0; padding: 2px; display: flex; align-items: center;">
                <strong>Signature:</strong>
                <img src="${executiveSign}" alt="Executive Signature" style="width: 45%; height: auto; margin-left: 10px;" />
            </p>
            <p style="margin: 0; padding: 2px;"><strong>Print Name: Bruce J. Cornelius</strong></p>
            <p style="margin: 0; padding: 2px;"><strong>Title: President</strong></p>
            <p style="margin: 0; padding: 2px;"><strong>Date: ${formattedDate}</strong></p>
        </div>
      </div>
      
    </div>`
    var pdf = new jsPDF("p", "px", "a4");
		const paddingTop = 20;
    const paddingBottom = 30;
    pdf.html(sample, {
			margin: [ 0,10,30,10],
      callback: function (pdf) {
        const totalPages = pdf.internal.getNumberOfPages();
				const totalPadding = paddingTop + paddingBottom;				
				for (let i = 0; i < totalPages; i++) {
					pdf.setPage(i + 1);
					
					pdf.text(
						`Page ${i + 1} of ${totalPages}`,
						pdf.internal.pageSize.width / 2,
						pdf.internal.pageSize.height - 10,
						{ align: 'center',fontSize: '12px' }
					);
					pdf.internal.pageSize.height += totalPadding; // Adjust the page height
				}
        pdf.save('Agreement_Copy.pdf');
      },
      x: 30,
      y: 30,
      autoPaging: 'text',
      html2canvas: { scale: 0.50 },
			width: pdf.internal.pageSize.getWidth() - 20,
    });
  };
  //pdf end here

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function ContentLayout() {
      return(
        <>
        
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{display:"flex", justifyContent:"space-between"}} >
                <Grid item lg={5} md={5} sm={5} xs={5} sx={{textAlign:"left"}}>
                    <Grid container item lg={12} md={12} sm={12} xs={12}>
                        <Typography level="h2" color="black">
                            Credit Snapshot
                        </Typography>
                    </Grid>
                    <Grid  item lg={12} md={12} sm={12} xs={12} sx={{textAlign:"left", marginTop: isMobile ?"10px":"32px"}}>
                            {user.roles_id == 3 ? 
                            <Button color="primary" sx={{ fontWeight:"bold"}} onClick={() => handleDownloadPDF(terms)}
                            >DOWNLOAD AGREEMENT COPY</Button>
                            :''
                            }
                    </Grid>
                </Grid>
                <Grid item lg={7} md={7} sm={7} xs={7} sx={{textAlign:"right"}}>
                    <Grid container item lg={12} md={12} sm={12} xs={12} >
                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{textAlign:"right"}}>
                            <Grid item lg={6} md={6} sm={6} xs={6} sx={{textAlign:"left"}}></Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6} sx={{textAlign:"right"}}>
                                    <Card variant="outlined" sx={{width:"100%",minHeight: isMobile ? "150px" : "150px", maxHeight: isMobile ? "150px" : "150px", padding: 0 }}>
                                    <a onClick={handleOpen} href="#" style={{textDecoration:"none"}}><Typography sx={{textAlign:"center"}} level="h6" color="primary" >How To Set Up Credit Snapshot</Typography></a>
                                    <div onClick={handleOpen} style={{ cursor: "pointer", marginTop: "10px", marginRight: "10px", marginLeft: "10px" }}>
                                        <iframe
                                        width="100%"
                                        height={isMobile ? "80px" : "100px"}
                                        src={require("../../../assets/images/HowToSetUpCreditSnapShot.mp4")}
                                        title="Video Player"
                                        // frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        ></iframe>
                                        </div>
                                    </Card>
                                </Grid>
                            </Grid>
                    </Grid>
                </Grid>
            </Grid>
            
            <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Typography level="h4">
                        Get Started In Just A Few Easy Steps
                    </Typography>
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12} >
            </Grid>         
        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1} >
            <TabContext value={value} sx={{width:"100%"}}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider',width:"100%" }}>
                    <Grid container item lg={12} md={12} sm={12} xs={12} >
                            <TabList onChange={handleChange} aria-label="secondary tabs example" className="tab-list"
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                               sx={{
                                '& .MuiTab-root.Mui-selected': {
                                  color: 'success.main', // Set active tab text color
                                  
                                },
                                '& .MuiTabs-indicator': {
                                  backgroundColor: 'success.main', // Set active tab indicator color
                                },
                              }}
                                
                            >
                                <Tab label="1. Setup Credit Snapshot" value="1" sx={{fontWeight: "bold", fontSize : "16px"}} />
                                <Tab label="2. Edit Or Upload Logo" value="2" sx={{fontWeight: "bold",fontSize : "16px"}}/>
                                <Tab label="3. Get Your Link" value="3" sx={{fontWeight: "bold",fontSize : "16px"}}/>
                                <Tab label="4. Promote Your Link" value="4" sx={{fontWeight: "bold",fontSize : "16px"}}/>
                                {/* <Tab label="5. Code To Add Popup" value="5" sx={{fontWeight: "bold"}}/> */}
                            </TabList>
                    </Grid>
                </Box>
                <TabPanel value="1" sx={{width:"100%"}}><CompanyDetails  receivedphone ={receivedphone} defaultPhoneNumber={defaultPhoneNumber} receivedemails={receivedemails} defaultEmail={defaultEmail} receivedpid={receivedpid} receivedaid={receivedaid} business={business} creditinfo={creditinfo} pids={pids} aidlist1={aidlist1} firstAid={firstAid} receivedshift={receivedshift} fetchCreditInfo={fetchCreditInfo} defaultOutOfOfficeMsg={defaultOutOfOfficeMsg}/></TabPanel>
                <TabPanel value="2" sx={{width:"100%"}} ><UploadSnapshotLogo creditinfo={creditinfo} firstlogoUrlValue={firstlogoUrl} companyId={companyId} fetchCreditInfo={fetchCreditInfo}/></TabPanel>
                <TabPanel value="3" sx={{width:"100%"}}><SnapshotSocialMediaLink creditinfo={creditinfo} firstlogoUrlValue={firstlogoUrl} companyId={companyId} receivedpid={receivedpid} link={link}/></TabPanel>
                <TabPanel value="4" sx={{width:"100%"}}><SnapshotPopupSetUp firstlogoUrl={firstlogoUrl} receivedpid={receivedpid} creditinfo={creditinfo} companyId={companyId} link={link} fetchCreditInfo={fetchCreditInfo} customColorArray={customColorArray} snapshotStr={snapshotStr}/></TabPanel>
                {/* <TabPanel value="5" sx={{width:"100%"}}><SnapshotCodeToSetPopup firstlogoUrl={firstlogoUrl} receivedpid={receivedpid} snapshotStr={snapshotStr}/></TabPanel> */}
            </TabContext>
           {/* MUI Dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        {/* Close button */}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon sx={{color: "white"}}/>
        </IconButton>

        {/* Dialog Content with Video */}
        <DialogContent style={{ padding: 0 }}>
          <iframe
            width="100%"
            height="400px"
            src={require("../../../assets/images/HowToSetUpCreditSnapShot.mp4")}
            title="Video Player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{ border: "none" }}
          ></iframe>
        </DialogContent>
      </Dialog>
        </Grid>
    </>
      )
            }

    return (
        <div>
            <DefaultLayout  content={<ContentLayout/>} />
            {/* loader code start */}
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                // onClick={handleFreezStop}
                >
                    <Freez />
                </Backdrop>
            </div>
            {/* loader code end */}
        </div>
    );
}

export default CreditSnapFirst;