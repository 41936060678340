import { createChatBotMessage } from 'react-chatbot-kit';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Grid,
} from '@mui/material';
import { Typography, Sheet, Card, CardOverflow } from '@mui/joy';
const affChatConfig = {
  botName: "chatbot",
  initialMessages: [createChatBotMessage(`Hello! You might receive some messages from the chatbot. Please be available to check the upcoming messages.`),
    createChatBotMessage(`Please Enter Your Name To Continue`),
  ],
  state: {
    messages: [], // To store custom state if needed
    isFirstMessage: true,
    isAffliate: false,
  },
  customStyles: {
    botMessageBox: { backgroundColor: "#376B7E" },
    chatButton: { backgroundColor: "#376B7E" },
  },
  customComponents: {
    header: () => 
      
    <CardOverflow
    sx={{
      backgroundColor: "#435AC2",
      borderRadius: "3px",
      padding: "3%",
      cursor: "pointer" ,
      
    }}
  >
    <Grid container item lg={12} md={12} sm={12} xs={12}>
      <Grid
        item lg={1} md={1} sm={1} xs={1}
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <IconButton>
          <MarkUnreadChatAltIcon sx={{ color: "white", fontSize: "xl" }} />
        </IconButton>
      </Grid>
      <Grid item lg={11} md={11} sm={11} xs={11}>
        <Typography
          level="h1"
          sx={{ color: "white !important", fontSize: "xl", mb: 0.5 }}
        >
          <b>Chat Support</b>
        </Typography>
      </Grid>
      {/* <Grid item lg={1} md={1} sm={1} xs={1} sx={{ textAlign: "right" }}>
        <IconButton>
          <RemoveIcon sx={{ color: "white", fontSize: "xl" }} />
        </IconButton>
      </Grid> */}
      {/* <Grid item lg={2} md={2} sm={2} xs={2} sx={{ textAlign: "right" }}>
        <IconButton>
          <CloseIcon sx={{ color: "white", fontSize: "xl" }} />
        </IconButton>
      </Grid> */}
    </Grid>
    </CardOverflow>,
  },
};

export default affChatConfig;