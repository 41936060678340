import React,{ useState, useEffect, useRef} from 'react';
import { Box,Grid, TextField, InputLabel, Button, FormControl, Stack } from '@mui/material';
import { Typography, Card, CardOverflow, CardContent, Divider, Option } from '@mui/joy';
import DefaultLayout from '../../../Components/DefaultLayout';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Dialog,DialogActions,DialogContent,FormHelperText,DialogTitle,OutlinedInput,MenuItem,Select  } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Formik } from 'formik';
import * as Yup from 'yup';
import AuthUser from '../../../Components/Auth/AuthUser';
import { Navigate, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Loader from '../../../Components/Loader/Loader';
import LogoEdit from '../../../Components/Cobrand/LogoEdit';
import config from '../../../AppConfig';
import swal from "sweetalert";
import OperationalHoursWithShift from "../CreditSnapshot/OperationalHoursWithShift";
import OperationalHours from '../../../Components/OperationalHours';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import InputMask from 'react-input-mask';
import formatPhoneNumber from '../../../Components/Formats/PhoneNumberValidation';
import { logout } from "../../../redux/authSlice";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
let blankValueIndexes1 = [];
let blankValueIndexes2 = [];
let operationalhoursvalues = [
    { label: "Monday-Friday(Weekdays)", timings: [{ start: "09:00", end: "18:00" }] },
    { label: "Saturday-Sunday(Weekends)", timings: [{ start: "09:00", end: "18:00" }]}];

let logoURL = '';
let logoFlag = 0;
let logoUpdated = 0;
let companyNameCount= 0;
let emailCount= 0;
let phoneNoCount= 0;
let contactNameCount= 0;
let email2Count= 0;
let companywebsiteCount = 0;
let planCount = 0;
let subtitleCount = 0;
let operationalHrsCount = 0;
let specialMsg1Count = 0;
let specialMsg2Count = 0;
let updateFieldCount= 0;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
}));


  
function BootstrapDialogTitle(props) {
    
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
            <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
            >
            <CloseIcon />
            </IconButton>
        ) : null}
        </DialogTitle>
    );
}
  
BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

function CobrandRequest(props) {
    const formikRef = useRef(null);
    const dispatch = useDispatch();
    const { http } = AuthUser();
    const { id } = useParams();
    const theme = useTheme();
    const [age, setAge] = React.useState('');
    const [basePlans, setBasePlans] = useState([]);
    const [aidDetails, setAidDetails] = useState([]);
    const [planVal, setPlanVal] = useState('');
    const [uploadLogo, setUploadLogo] = React.useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [cobrandObj, setCobrandObj] = useState([]);
    const [formikValues, setFormikValues] = useState({});
    const navigate = useNavigate();
    const queryParameters = new URLSearchParams(window.location.search);
    // let updateFlag = queryParameters.has('updateFlag') ? queryParameters.get('updateFlag') : 0;
    const [updateFlag, setUpdateFlag] = useState(queryParameters.has('updateFlag') ? queryParameters.get('updateFlag') : 0);
    const [disable, setDisable] = useState(queryParameters.has('updateFlag') ? true : false); 

    const [planId, setplanAdd] = useState(queryParameters.has('planId') ? queryParameters.get('planId') : 0);
    const [checkUsaTldStatus, setCheckUsaTldStatus] = useState(false);
    const [checkUsaTldAddress2Status, setCheckUsaTldAddress2Status] = useState(false);
    let plans = '';
    let buttonDisable = queryParameters.has('updateFlag')  ? true : false;
    if(planId != 0){
        plans = planId;
        buttonDisable = false;
    }
   
    const handleChange = (event) => {
        setAge(event.target.value);
    };
    
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleUploadLogo = (values) => {
        setUploadLogo(true);
        setFormikValues(values);
    }
    const handleCloseUploadLogo = () => {
        setUploadLogo(false);
    }

    const handleLogoFlag = (flag) => {
        logoFlag = flag;
        if(updateFlag == 1 && logoUpdated == 0){
            logoUpdated = flag;
        }
    }
    const handleLogoName = (name) => {
        console.log(name)
        if(updateFlag == 1 && logoUpdated == 1){
            formikRef.current.values.logo = name;
            formikRef.current.values.isFormDirty = false;
            formikRef.current.setFieldValue('isFormDirty',false);
            formikRef.current.setFieldValue('logo',name);
            updateFieldCount = updateFieldCount + 1;        
        }     
        console.log(formikRef.current.values)   
    }

    const getCobrandData = async() =>{
        const apiUrl = '/cobrandRequest/show/'+id;
		const res = await dispatch(fetchData(apiUrl)).unwrap();
		if(res){
            // alert(res.success);
            if(res.success === true) {
                console.log(JSON.stringify(res))
                setCobrandObj(res.data.cobrandObj);
                setBasePlans(res.data.planObj);
                setAidDetails(res.data.aidData);
                if(res.data.defaultPlan != 0){
                    plans = res.data.defaultPlan;
                    buttonDisable = false;
                }
                logoURL = require("../../../assets/images/uploadLogo.png");
                if(res.data.cobrandObj){
                    if(res.data.cobrandObj.company_logo){
                        logoURL = config.BACKEND_URL+"images/cobrandLogo/"+res.data.cobrandObj.company_logo;
                        logoFlag = res.data.cobrandObj.company_logo ? 1 : 0;
                    }
                    if(res.data.cobrandObj.selected_cobrand_plan == ''){
                        setUpdateFlag(0);
                    }else{
                        setUpdateFlag(1);
                    } 

                    const initialValues = {
                        updateFlag: updateFlag,
                        companyName: res.data.cobrandObj ? res.data.cobrandObj.company_name:'',
                        email: res.data.cobrandObj ? res.data.cobrandObj.email : '',
                        email2: res.data.cobrandObj ? res.data.cobrandObj.email2 : '',
                        contactName: res.data.cobrandObj ? res.data.cobrandObj.name : '',
                        phoneNo: res.data.cobrandObj ? res.data.cobrandObj.phone ? formatPhoneNumber(res.data.cobrandObj.phone) : '' : '',                                
                        timezone: 'PDT',
                        workingHours: '',
                        subtitle: res.data.cobrandObj ? res.data.cobrandObj.subtitle : '',            
                        companywebsite: res.data.cobrandObj ? res.data.cobrandObj.website_url : '',
                        plan: plans,
                        specialMsg1: res.data.cobrandObj ? res.data.cobrandObj.special_message_1 : '<Company Name> offers a comprehensive program that enables customers to improve their credit and better manage their finances.',
                        specialMsg2: res.data.cobrandObj ? res.data.cobrandObj.special_message_2 : '',                                
                        logoFlag: logoFlag,
                        logo: res.data.cobrandObj ? res.data.cobrandObj.company_logo : '',
                        operationalHrs: res.data.cobrandObj ? res.data.cobrandObj.operational_hrs : '',
                        isFormDirty: buttonDisable,
                        
                    }
                    setFormikValues(initialValues);
                }
			    setIsLoading(false);
            }else{
                swal({
                    title: "Failed",
                    text: res.message,
                    icon: "error",
                    button: "Ok",
                })
                dispatch(logout());
                localStorage.clear();
                // console.log(isAuthenticated)
                navigate('/login');
                // window.location.href= config.BASE_URL + 'login' ;
                // return false;
            }
            			
		}
	} 

    const handleLogoURL = (image) => {
        logoURL = image;
    }

    useEffect(() => {
        getCobrandData();   
        setFormikValues(initialValues);
    },[])
    
    const CobrandSchema =  Yup.object().shape({
        updateFlag: Yup.boolean(),
        companyName: Yup.string()
                    .min(2, 'Too Short!')
                    .max(50, 'Too Long!')
                    .required('Please enter Company Name.'),
        contactName: Yup.string()
                    .min(2, 'Too Short!')
                    .max(50, 'Too Long!')
                    .required('Please enter Contact Name.'),
        email: Yup.string()
                    .email('Invalid Email Format.')
                    // .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, "Invalid email format.")
                    .matches(/^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24})$/i, "Invalid email format.")
                    .required('Please enter your Email Address.'),
        phoneNo: Yup.string()
                    .min(14, 'Must be 10 digits.')
                    .max(14, 'Must be 10 digits.')
                    .required('Please enter your mobile number.'),
        plan: Yup.string().when("updateFlag",{
            is: (updateFlag) => updateFlag == 0,
            then: () => Yup.string().required('Please select your Plan.'),
            // otherwise: Yup.string().required('Please select your Plan.'),
        }),
        // plan: Yup.string().required('Please select your Plan.'),
        
    });

    const checkUsaTLD = async(email,callTime) => {
        if(email == '' && callTime == 2){
            setCheckUsaTldAddress2Status(false);
            return false;
        }
        var checkpattern = String(email)
        .match(
            /^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24})$/
        );
        if(checkpattern == null && callTime == 2) {
            setCheckUsaTldAddress2Status(true);
        }
        if (email.includes('@')) {
            const apiUrl = '/checkUsaTLD';
            const args = {
                email: email,
            }
            const res = await dispatch(postData({ apiUrl, args })).unwrap();
            if (res) {
                if (res.success === true) {
                    if(callTime == 1) {
                        setCheckUsaTldStatus(true);
                    }else{
                        setCheckUsaTldAddress2Status(true);
                    }
                    
                } else {
                    if(callTime == 1) {
                        setCheckUsaTldStatus(false);
                    }else{
                        setCheckUsaTldAddress2Status(false);
                    }
                }
            }
        }
    }
    
    const handleChildValue = (value1) => {
        blankValueIndexes1 = []
        blankValueIndexes2 = []
        operationalhoursvalues = value1
        if(value1.length == 2) {
            value1[0]["timings"].forEach((value, index) => {
                if (value.start === "" || value.start === null || value.start === undefined) {
                    blankValueIndexes1.push(index);
                }
                if (value.end === "" || value.end === null || value.end === undefined) {
                    blankValueIndexes1.push(index);
                }
            });
            value1[1]["timings"].forEach((value, index) => {
                if (value.start === "" || value.start === null || value.start === undefined) {
                    blankValueIndexes2.push(index);
                }
                if (value.end === "" || value.end === null || value.end === undefined) {
                    blankValueIndexes2.push(index);
                }
            });
        }else{
            value1[0]["timings"].forEach((value, index) => {
                if (value.start === "" || value.start === null || value.start === undefined) {
                    blankValueIndexes1.push(index);
                }
                if (value.end === "" || value.end === null || value.end === undefined) {
                    blankValueIndexes1.push(index);
                }
            });
        }
       
    };

    const initialValues = {
        updateFlag: updateFlag,
        companyName: cobrandObj ? cobrandObj.company_name:'',
        email: cobrandObj ? cobrandObj.email : '',
        email2: cobrandObj ? cobrandObj.email2 : '',
        contactName: cobrandObj ? cobrandObj.name : '',
        phoneNo: cobrandObj ? cobrandObj.phone ? formatPhoneNumber(cobrandObj.phone) : '' : '',                                
        timezone: 'PDT',
        workingHours: '',
        subtitle: cobrandObj ? cobrandObj.subtitle : '',            
        companywebsite: cobrandObj ? cobrandObj.website_url : '',
        plan: '',
        specialMsg1: cobrandObj ? cobrandObj.special_message_1 : '<Company Name> offers a comprehensive program that enables customers to improve their credit and better manage their finances.',
        specialMsg2: cobrandObj ? cobrandObj.special_message_2 : '',                                
        logoFlag: logoFlag,
        logo: cobrandObj ? cobrandObj.company_logo : '',
        operationalHrs: cobrandObj ? cobrandObj.operational_hrs : '',
        isFormDirty: buttonDisable,
        
    }
    
   

    const ContentLayout=
            <Box p={2}>
                {isLoading?<Loader/>:<></>}   
                <Grid item xs={12}>
                    <Typography level="h5">With a Co-Brand from MyFreeScoreNow you get the following:</Typography>
                    <ul>
                        <li>Your own personalized link which will display your logo and messaging to your clients</li>
                        <li>Special alerts emailed to you about your client's account activity (example: "John Smith score went up +80 points")</li>
                        <li>No more failed login attempts. Our site will pass you the member credentials after the client enrolls. No other company has this feature!</li>
                    </ul>
                </Grid>
                <Grid item xs={12}>                        
                    <Card variant='outlined'>
                        <CardOverflow
                            sx={{
                                bgcolor:'#F6FAFF',
                                borderColor:'#F6FAFF',
                                padding: '1%',                            
                            }}
                        >                            
                            {
                                updateFlag == 1
                                ?
                                <Typography level="h4" color="primary">Cobrand Update Form for AID - {aidDetails.aid} </Typography>
                                :
                                <Typography level="h4" color="primary">Cobrand Request Form for AID - {aidDetails.aid} </Typography>
                            } 
                        </CardOverflow>
                        <Formik 
                            initialValues={formikValues}
                            validationSchema={CobrandSchema}
                            innerRef={formikRef} // Assign the ref to Formik instance
                            onSubmit={ async (values, error) => {
                                if(checkUsaTldStatus == true || checkUsaTldAddress2Status == true){
                                    return false;
                                }
                                if(logoFlag == 0){
                                    swal({
                                        title: "Alert",
                                        text: "Please Upload Logo First",
                                        icon: "warning",
                                        button: "Ok",
                                    })
                                    return false;
                                }
                                if (blankValueIndexes1.length > 0) {
                                    swal({
                                        title: "Alert",
                                        text: "Some fields are missing in weekdays (Monday - Friday) shift either delete the shift or add its valus!",
                                        icon: "error",
                                        button: "Ok",
                                    })
                                } else if (blankValueIndexes2.length > 0) {
                                    swal({
                                        title: "Alert",
                                        text: "Some fields are missing in weekends (Saturday - Sunday) shift either delete the shift or add its valus!",
                                        icon: "error",
                                        button: "Ok",
                                    })
                                }
                                else{
                                    const apiUrl = '/checkAffiliateOfSameCompany';
                                    const args = {
                                        id: id,
                                    }
                                    const response = await dispatch(postData({apiUrl,args})).unwrap();
                                    if(response){                                        
                                        if(response.success === true){
                                            setIsLoading(true);
                                            setDisable(true);
                                            const apiUrl = '/createCobrand'; // Replace with your API endpoint
                                            const args = {
                                                company_name: values.companyName,
                                                email: values.email,
                                                email2: values.email2,
                                                name: values.contactName,
                                                phone: values.phoneNo,
                                                timezone: values.timezone,
                                                workingHours: operationalhoursvalues,
                                                subtitle: values.subtitle,
                                                website_url: values.companywebsite,
                                                plan: values.plan,
                                                special_message_1: values.specialMsg1,
                                                special_message_2: values.specialMsg2,
                                                logo: values.logo,
                                                logoFlag: logoFlag,
                                                aidMasterId: id,
                                                updateFlag: updateFlag,
                                                operational_hrs: updateFlag == 1 ? values.operationalHrs : null,
                                                url:config.BASE_URL,
                                                logoUpdated: logoUpdated,
                                            };
                                            const res = await dispatch(postData({apiUrl,args})).unwrap();
                                            if(res){                                        
                                                if(res.success){
                                                    const loginUser = JSON.parse(localStorage.getItem('user'));
                                                    if(loginUser.roles_id == res.userId) {
                                                        localStorage.setItem('success',res.message)   
                                                    }
                                                    if(loginUser.roles_id === 3) {
                                                        navigate('/cobrandpanel');
                                                    }
                                                    else if(planId != 0){
                                                        navigate('/cobrand/all');
                                                    }else{
                                                        navigate(-1);
                                                    }                                
                                                   
                                                }else{
                                                    setDisable(false);
                                                    const loginUser = JSON.parse(localStorage.getItem('user'));
                                                    if(loginUser.roles_id == res.userId) {
                                                        localStorage.setItem('error',res.message) 
                                                    }  
                                                    if(planId != 0){
                                                         navigate('/cobrand/all');
                                                    }else{
                                                        navigate(-1);
                                                    }                                    
                                                    

                                                }
                                            }
                                        }else{
                                            swal({
                                                title: "Failed",
                                                text: response.message,
                                                icon: "error",
                                                button: "Ok",
                                            })
                                            navigate('/login');
                                            return false;
                                        }
                                    }
                                    
                                    
                                }
                            }}
                            enableReinitialize={true}
                        >
                            {({values, errors,touched,isSubmitting,setFieldValue, handleChange, handleBlur, handleSubmit}) => (
                                <CardContent>
                                    {/* <Box p={5}
                                        sx={{
                                            '& .MuiTextField-root': { m: 1, },
                                            '& .MuiSelect-root': { m: 1, },                                            
                                        }}
                                    > */}
                                        <Grid item md={12} container p={2} >
                                            <Grid item md={4} sx={{display:'flex',justifyContent:'center'}}>
                                                <Box >                                                    
                                                    <Card variant='outlined' size='md' sx={{height:150,width:300}} >
                                                        <CardContent sx={{textAlign:'center',justifyContent:'center'}}>                                                            
                                                            <img className="dashlogo" style={{height:'90px'}} src={logoURL} />                                                            
                                                        </CardContent>
                                                    </Card>                                                    
                                                    <Box py={2} sx={{textAlign:'center'}}>
                                                        <Button variant="contained" size="sm" color="success" onClick={()=>{handleUploadLogo(values)}}>Edit</Button>
                                                    </Box>                                                    
                                                </Box>
                                            </Grid> 
                                            <Grid item md={4} px={2}>     
                                                    <TextField type='hidden' margin='dense' value={values.logoFlag} sx={{display:'none'}} />                                   
                                                    <TextField
                                                        id="outlined-start-adornment"
                                                        type="text"
                                                        margin='dense'
                                                        label="Company Name*"
                                                        autoComplete="companyName"
                                                        value={values.companyName||''}
                                                        fullWidth
                                                        variant="outlined"
                                                        size="small"
                                                        onChange={(e)=>{                                                       
                                                            handleChange('companyName')
                                                            setFieldValue('companyName',e.target.value)
                                                            let msgVal = e.target.value+ ' offers a comprehensive program that enables customers to improve their credit and better manage their finances.';
                                                            setFieldValue('specialMsg1',msgVal) 
                                                            if(e.target.value  == initialValues.companyName){
                                                                companyNameCount = 0;
                                                                updateFieldCount = updateFieldCount - 1; 
                                                                if(updateFieldCount <= 0){
                                                                    setFieldValue('isFormDirty',true)
                                                                }                                                                
                                                            }else{ 
                                                                setFieldValue('isFormDirty',false) 
                                                                if(companyNameCount == 0){
                                                                    companyNameCount = companyNameCount + 1;
                                                                    updateFieldCount = updateFieldCount + 1;                                                       
                                                                }
                                                                 
                                                                                                              
                                                            }
                                                        }}
                                                        InputLabelProps={{
                                                        shrink: Boolean(values.companyName)
                                                        }}
                                                        // onChange={(e)=>{handleChange(e)}}
                                                        onBlur={handleBlur('companyName')}
                                                        helperText={touched.companyName && errors.companyName ? errors.companyName : null}
                                                        error={touched.companyName && errors.companyName ? errors.companyName : null}
                                                    />    

                                                    <TextField
                                                        id="outlined-start-adornment"
                                                        type="text"
                                                        label="Email Address*"                                            
                                                        fullWidth
                                                        margin='dense'
                                                        autoComplete='email1'
                                                        variant="outlined"
                                                        size="small"
                                                        value={values.email||''}
                                                        // helperText={touched.email && errors.email ? errors.email : null}
														error={touched.email && errors.email ? errors.email : null}
                                                        onChange={(e)=>{
                                                            handleChange('email')
                                                            setFieldValue('email',e.target.value)
                                                            checkUsaTLD(e.target.value,1);
                                                            if(e.target.value  == initialValues.email){
                                                                emailCount = 0;
                                                                updateFieldCount = updateFieldCount - 1; 
                                                                if(updateFieldCount <= 0){
                                                                    setFieldValue('isFormDirty',true)
                                                                }
                                                            }else{
                                                                setFieldValue('isFormDirty',false)
                                                                if(emailCount == 0){
                                                                    emailCount = emailCount + 1;
                                                                    updateFieldCount = updateFieldCount + 1;                                                      
                                                                }
                                                                                                                             
                                                            }
                                                        }}
                                                        onBlur={handleBlur('email')}
                                                        InputLabelProps={{
                                                            shrink: Boolean(values.email)
                                                        }}
                                                    />
                                                     <div className='error'>{touched.email && errors.email ?errors.email : checkUsaTldStatus ? "Invalid email format." : '' }</div>                                        
                                                    <InputMask
                                                        id="outlined-start-adornment"
                                                        mask='(999) 999-9999'
                                                        maskChar={null}
                                                        type="text"
                                                        label="Phone No*"
                                                        fullWidth
                                                        margin='dense'
                                                        variant="outlined"
                                                        size="small"
                                                        value={values.phoneNo}
                                                        helperText={touched.phoneNo && errors.phoneNo ? errors.phoneNo : null}
														error={touched.phoneNo && errors.phoneNo ? errors.phoneNo : null}
                                                        onKeyPress={(e) => {
                                                            if (e.target.value && e.target.value.length >= 14) {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        onChange={(e)=>{
                                                            handleChange('phoneNo')
                                                            setFieldValue('phoneNo',e.target.value)
                                                            if(e.target.value  == initialValues.phoneNo){
                                                                phoneNoCount = 0;
                                                                updateFieldCount = updateFieldCount - 1; 
                                                                if(updateFieldCount  <= 0){
                                                                    setFieldValue('isFormDirty',true)
                                                                }
                                                            }else{
                                                                setFieldValue('isFormDirty',false)
                                                                if(phoneNoCount == 0){
                                                                    phoneNoCount = phoneNoCount + 1;
                                                                    updateFieldCount = updateFieldCount + 1;                                                    
                                                                }
                                                               
                                                            }
                                                        }}
                                                        onBlur={handleBlur('phoneNo')}
                                                        InputLabelProps={{
                                                            shrink: Boolean(values.phoneNo)
                                                        }}
                                                    > 
                                                    {(inputProps) =>
                                                        <TextField
                                                        label="Phone Number" variant="outlined"
                                                        {...inputProps}
                                                        />
                                                    }
                                                    </InputMask>
                                            </Grid>
                                            <Grid item md={4} px={2}>
                                                <TextField
                                                    id="outlined-start-adornment"
                                                    type="text"
                                                    label="Contact Name*"
                                                    fullWidth
                                                    autoComplete='contactNames'
                                                    margin='dense'
                                                    variant="outlined"
                                                    size="small"
                                                    value={values.contactName||''}
                                                    helperText={touched.contactName && errors.contactName ? errors.contactName : null}
													error={touched.contactName && errors.contactName ? errors.contactName : null}
                                                    onChange={(e)=>{
                                                        handleChange('contactName')
                                                        setFieldValue('contactName',e.target.value)
                                                        if(e.target.value  == initialValues.contactName){
                                                            contactNameCount =0;
                                                            updateFieldCount = updateFieldCount - 1; 
                                                            if(updateFieldCount <= 0){
                                                                setFieldValue('isFormDirty',true)
                                                            }
                                                        }else{
                                                            setFieldValue('isFormDirty',false)
                                                            if(contactNameCount == 0){
                                                                contactNameCount = contactNameCount + 1;
                                                                updateFieldCount = updateFieldCount + 1;                                                  
                                                            }
                                                        }
                                                    }}
                                                    onBlur={handleBlur('contactName')}
                                                    InputLabelProps={{
                                                        shrink: Boolean(values.contactName)
                                                    }}
                                                />                                           
                                                {/* <TextField
                                                    id="outlined-start-adornment"
                                                    type="text"
                                                    label="Email Address 2"                                            
                                                    fullWidth
                                                    autoComplete='email2'
                                                    margin='dense'
                                                    variant="outlined"
                                                    size="small"
                                                    value={values.email2||''}
                                                    onChange={(e)=>{
                                                        handleChange('email2')
                                                        setFieldValue('email2',e.target.value)
                                                        checkUsaTLD(e.target.value,2);
                                                        if(initialValues.email2 == null){
                                                            initialValues.email2 = '';
                                                        }
                                                        if(e.target.value  == initialValues.email2){
                                                            email2Count = 0;
                                                            updateFieldCount = updateFieldCount - 1; 
                                                            if(updateFieldCount <= 0){
                                                                setFieldValue('isFormDirty',true)
                                                            }
                                                        }else{
                                                            setFieldValue('isFormDirty',false)
                                                            if(email2Count == 0){
                                                                email2Count = email2Count + 1;
                                                                updateFieldCount = updateFieldCount + 1;                                               
                                                            }
                                                            
                                                        }
                                                    }}
                                                    onBlur={handleBlur('email2')}
                                                    InputLabelProps={{
                                                        shrink: Boolean(values.email2)
                                                    }}
                                                />
                                                <div className='error'>{checkUsaTldAddress2Status ? "Invalid email format." : '' }</div> */}
                                                <TextField
                                                    id="outlined-start-adornment"
                                                    type="text"
                                                    label="Company Website(URL)"
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    margin='dense'
                                                    autoComplete='companywebsite'
                                                    value={values.companywebsite||''}
                                                    onChange={(e)=>{
                                                        handleChange('companywebsite')
                                                        setFieldValue('companywebsite',e.target.value)
                                                        if(initialValues.companywebsite == null){
                                                            initialValues.companywebsite = '';
                                                        }
                                                        if(e.target.value  == initialValues.companywebsite){
                                                            companywebsiteCount = 0;
                                                            updateFieldCount = updateFieldCount - 1; 
                                                            if(updateFieldCount  <= 0){
                                                                setFieldValue('isFormDirty',true)
                                                            }
                                                        }else{
                                                            setFieldValue('isFormDirty',false)
                                                            if(companywebsiteCount == 0){
                                                                companywebsiteCount = companywebsiteCount + 1;
                                                                updateFieldCount = updateFieldCount + 1;                                             
                                                            }
                                                           
                                                        }
                                                    }}
                                                    onBlur={handleBlur('companywebsite')}
                                                    InputLabelProps={{
                                                        shrink: Boolean(values.companywebsite)
                                                    }}
                                                />                                         
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                        {
                                            updateFlag == 1                                            
                                            ?
                                            <>
                                            <Grid item md={12} container  pt={2} pl={2} >
                                                <Typography level="h5" >Current Plan Details</Typography>
                                            </Grid>
                                            <Grid item md={12}   pt={2} pl={2} >
                                                {
                                                    cobrandObj.selected_cobrand_plan
                                                    ?
                                                    (cobrandObj.selected_cobrand_plan.map((row,idx)=>{           
                                                        return(
                                                            <>
                                                                <Typography key={idx}>{idx+1}. PID : {row.pid}, Plan : {row.base_plan.plan_name?row.base_plan.plan_name:'N/A'}</Typography>                                                             
                                                            </>
                                                        )                                       
                                                    }))                                        
                                                    :
                                                    null
                                                    
                                                }
                                            </Grid>
                                            </>
                                            :
                                            null
                                        }
                                        <Grid md={12} container item pt={1} >
                                            <Typography level="h5" >{updateFlag == 1 ? 'Request For New Plan' : 'Plan Details'}</Typography>
                                        </Grid>
                                        <Grid container item lg={12} md={12} sm={12} xs={12}  spacing={2} sx={{mb:1}}>  
                                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                                <FormControl fullWidth size='small' margin='dense'
                                                    error={touched.plan && errors.plan ? errors.plan : null}
                                                >
                                                    <InputLabel id="demo-multiple-name-label">Plan</InputLabel>
                                                    <Select
                                                        fullWidth
                                                        labelId="demo-simple-select-label"
                                                        value={values.plan||''}
                                                        MenuProps={MenuProps}
                                                        onChange={(e)=>{
                                                            handleChange('plan')
                                                            setFieldValue('plan',e.target.value)
                                                            if(e.target.value  == initialValues.plan){
                                                                planCount = 0;
                                                                updateFieldCount = updateFieldCount - 1; 
                                                                if(updateFieldCount <= 0){
                                                                    setFieldValue('isFormDirty',true)
                                                                }
                                                            }else{
                                                                setFieldValue('isFormDirty',false)
                                                                if(planCount == 0){
                                                                    planCount = planCount + 1;
                                                                    updateFieldCount = updateFieldCount + 1;                                        
                                                                }
                                                            }
                                                        }}
                                                        onBlur={handleBlur('plan')}
                                                        id="plan"
                                                        input={<OutlinedInput label="Plan" />}
                                                        name="plan"
                                                        disabled={plans !== ''}
                                                        helperText={touched.plan && errors.plan ? errors.plan : null}
                                                        error={touched.plan && errors.plan ? errors.plan : null}
                                                    >
                                                        {basePlans.map((state) => {
                                                            return (
                                                                <MenuItem value={state.id}>{state.plan_name}</MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                    {touched.plan && errors.plan ? <FormHelperText>Please select Plan.</FormHelperText> : null}
                                                </FormControl>
                                            </Grid>   
                                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                                <TextField
                                                    id="outlined-start-adornment"
                                                    type="text"
                                                    label="Co-Branding Sub-Title (E.G. Could Be Your Tag Line, Motto, Your Slogan)"
                                                    fullWidth
                                                    margin='dense'
                                                    variant="outlined"
                                                    autoComplete='subtitle'
                                                    size="small"
                                                    value={values.subtitle}
                                                    InputLabelProps={{
                                                        shrink: Boolean(values.subtitle)
                                                    }}
                                                    onChange={(e)=>{
                                                        handleChange('subtitle')
                                                        setFieldValue('subtitle',e.target.value)
                                                        if(initialValues.subtitle == null){
                                                            initialValues.subtitle = '';
                                                        }
                                                        if(e.target.value  == initialValues.subtitle){
                                                            subtitleCount = 0;
                                                            updateFieldCount = updateFieldCount - 1; 
                                                            if(updateFieldCount  <= 0){
                                                                setFieldValue('isFormDirty',true)
                                                            }
                                                        }else{
                                                            setFieldValue('isFormDirty',false)
                                                            if(subtitleCount == 0){
                                                                subtitleCount = subtitleCount + 1;
                                                                updateFieldCount = updateFieldCount + 1;                                       
                                                            }
                                                           
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        
                                        {/* <OperationalHours /> */}
                                        {
                                            updateFlag == 1
                                            ?
                                            <>
                                            <Grid container item md={12} mt={2}>
                                                <TextField
                                                    id="operationalHrs"
                                                    type="text"
                                                    label="operational Hrs"
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    name="operationalHrs"
                                                    value={values.operationalHrs}
                                                    InputLabelProps={{
                                                        shrink: Boolean(values.operationalHrs),
                                                        }}
                                                    onChange={(e)=>{
                                                        handleChange('operationalHrs')
                                                        setFieldValue('operationalHrs',e.target.value)
                                                        if(e.target.value  == initialValues.operationalHrs){
                                                            operationalHrsCount = 0;
                                                            updateFieldCount = updateFieldCount - 1; 
                                                            if(updateFieldCount  <= 0){
                                                                setFieldValue('isFormDirty',true)
                                                            }
                                                        }else{
                                                            setFieldValue('isFormDirty',false)
                                                            if(operationalHrsCount == 0){
                                                                operationalHrsCount = operationalHrsCount + 1;
                                                                updateFieldCount = updateFieldCount + 1;                                 
                                                            }
                                                            
                                                        }
                                                    }}
                                                    onBlur={handleBlur('operationalHrs')}
                                                />
                                            </Grid>
                                            </>
                                            :
                                            <>
                                            <Divider />                                            
                                            <Grid item md={12} container pt={1} >
                                                <Typography level="h5" >Hours Of Operation: (Include Days Of Operation And Time Zone) *</Typography>
                                            </Grid>
                                            <Grid item md={12} container sx={{mt:0.5}} spacing={2}>
                                                <Grid item md={6} >
                                                <FormControl fullWidth size='small' margin='dense'>
                                                    <InputLabel id="demo-multiple-name-label">Default Timezone</InputLabel>
                                                    <Select
                                                        name="timezone"
                                                        labelId="demo-simple-select-label"
                                                        id="timezone"
                                                        value={values.timezone||''}
                                                        label="Default Timezone"
                                                        fullWidth
                                                        size='small'
                                                        margin='dense'
                                                        onChange={handleChange('timezone')}
                                                        onBlur={handleBlur('timezone')}
                                                        MenuProps={MenuProps}
                                                    >
                                                        <MenuItem value="PST">PST - Pacific Standard Time</MenuItem>
                                                        {/* <MenuItem value="CT">CT - Central Time</MenuItem> */}
                                                        <MenuItem value="EST">EST - Eastern Standard Time</MenuItem>
                                                        <MenuItem value="IST">IST - Indian Standard Time</MenuItem>
                                                        <MenuItem value="EDT">EDT - Eastern Daylight Time</MenuItem>
                                                        <MenuItem value="UTC">UTC - Coordinated Universal Time</MenuItem>
                                                        <MenuItem value="PDT">PDT - Pacific Daylight Time</MenuItem>
                                                        <MenuItem value="WET">WET - Western European Time</MenuItem>
                                                        <MenuItem value="CDT">CDT - Central Daylight Time</MenuItem>
                                                        <MenuItem value="WST">WST - Western Standard Time</MenuItem>
                                                        <MenuItem value="CST">CST - Central Standard Time</MenuItem>
                                                        <MenuItem value="MST">MST - Mountain Standard Time</MenuItem>
                                                    </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: 'space-between' }} >
                                                <OperationalHours onValueChange={handleChildValue} props={operationalhoursvalues}
                                                />
                                            </Grid>                                            
                                            </>
                                        }                                        
                                        
                                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>                                            
                                            <TextField
                                                id="outlined-start-adornment"
                                                type="text"
                                                label="Special Messaging - Paragraph 1"
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                margin='dense'
                                                placeholder="<Company Name> offers a comprehensive program that enables customers to improve their credit and better manage their finances."
                                                value={values.specialMsg1 || ''}
                                                multiline
                                                onChange={(e)=>{
                                                    handleChange('specialMsg1')
                                                    setFieldValue('specialMsg1',e.target.value)
                                                    if(e.target.value  == initialValues.specialMsg1){
                                                        specialMsg1Count = 0;
                                                        updateFieldCount = updateFieldCount - 1; 
                                                        if(updateFieldCount  <= 0){
                                                            setFieldValue('isFormDirty',true)
                                                        }
                                                    }else{
                                                        setFieldValue('isFormDirty',false)
                                                        if(specialMsg1Count == 0){
                                                            specialMsg1Count = specialMsg1Count + 1;
                                                            updateFieldCount = updateFieldCount + 1;                            
                                                        }
                                                    }
                                                }}
                                                onBlur={handleBlur('specialMsg1')}
                                            />
                                        </Grid>                                       
                                        <Grid container item md={12} >
                                            <TextField
                                                id="outlined-start-adornment"
                                                type="text"
                                                label="Special Messaging - Paragraph 2"
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                multiline
                                                margin='dense'
                                                value={values.specialMsg2}
                                                InputLabelProps={{
                                                    shrink: Boolean(values.specialMsg2)
                                                }}
                                                onChange={(e)=>{
                                                    handleChange('specialMsg2')
                                                    setFieldValue('specialMsg2',e.target.value)
                                                    if(initialValues.specialMsg2 == null){
                                                        initialValues.specialMsg2 = '';
                                                    }
                                                    if(e.target.value  == initialValues.specialMsg2){
                                                        specialMsg2Count = 0;
                                                        updateFieldCount = updateFieldCount - 1; 
                                                        if(updateFieldCount  <= 0){
                                                            setFieldValue('isFormDirty',true)
                                                        }
                                                    }else{
                                                        
                                                        setFieldValue('isFormDirty',false)
                                                        if(specialMsg2Count == 0){
                                                            specialMsg2Count = specialMsg2Count + 1;
                                                            updateFieldCount = updateFieldCount + 1;                            
                                                        }
                                                    }
                                                }}
                                                onBlur={handleBlur('specialMsg2')}
                                            />
                                        </Grid>
                                        <Grid container  item md={12} sx={{justifyContent:'space-between'}} py={2}>                                       
                                            <Grid item md={6} p={2} textAlign="center">
                                                <Button variant="contained" sx={{minWidth:"60%"}} color="success" type="submit" size='lg' disabled={values.isFormDirty}  onClick={handleSubmit}>SUBMIT</Button>
                                            </Grid>      
                                            <Grid item md={6} p={2} textAlign="center">
                                                <Button variant="contained" sx={{minWidth:"60%"}} color="primary" type="submit" size='lg' onClick={()=>{navigate(-1)}}>CLOSE</Button>
                                            </Grid>                       
                                        </Grid>
                                    {/* </Box> */}
                                </CardContent>
                            )}
                        </Formik>
                    </Card>
                </Grid>
                
                <BootstrapDialog
                    onClose={(_, reason) => {
                        if (reason !== "backdropClick") {
                            handleClose();
                        }
                    }}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                >
                    <BootstrapDialogTitle id="customized-dialog-title"sx={{ bgcolor:'#F6FAFF'}} onClose={handleClose}>
                    Email to CD
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <Grid container item xs>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Age</InputLabel>
                                    <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={age}
                                    label="Age"
                                    onChange={handleChange}
                                    >
                                    <MenuItem value={10}>Ten</MenuItem>
                                    <MenuItem value={20}>Twenty</MenuItem>
                                    <MenuItem value={30}>Thirty</MenuItem>
                                    </Select>
                            </FormControl>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Save changes
                    </Button>
                    </DialogActions>
                </BootstrapDialog> 
                <Dialog
                    open={uploadLogo}
                    onClose={(_, reason) => {
                        if (reason !== "backdropClick") {
                            handleCloseUploadLogo();
                        }
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                   <LogoEdit id={id} logoEditURL={handleLogoURL} logoName={handleLogoName} updateFlag={updateFlag} logoFlagEdit={handleLogoFlag} setOpen={handleCloseUploadLogo} />
                </Dialog>
            </Box>
   
    return (
        <div>
            <DefaultLayout content={ContentLayout } />
        </div>
    );
}

export default CobrandRequest;