import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import axios from "axios";
import Chatbot from "react-chatbot-kit";
import ActionProvider from "./ActionProvider";
import MessageParser from "./MessageParser";
import AuthUser from "../Auth/AuthUser";
import config from "../../AppConfig";
import affChatConfig from "./affChatConfig";
import "react-chatbot-kit/build/main.css";
import { createChatBotMessage } from "react-chatbot-kit";
import { useMediaQuery,Box } from '@mui/material';

let emailValue = "";
let role = null;

const AffChatbotComponent = forwardRef((props, ref) => {
  const [sessionId, setSessionId] = useState(localStorage.getItem("chatSessionId"));
  const [isChatbotVisible, setIsChatbotVisible] = useState(false);
  const [chatState, setChatState] = useState([]);
	const isMobile = useMediaQuery('(max-width:600px)');
  const [initialMsg, setInitialMsg] = useState([]);
	// const initialMessages = [
	// 	createChatBotMessage("Hello, I have a query"),
	// 	createChatBotMessage("Can you please help me?"),
	// ];
  const { http, user } = AuthUser();

  useImperativeHandle(ref, () => ({
    toggleChatbot,
  }));

  useEffect(() => {
    const initializeChatSession = async () => {
      try {
        if (user && user.email) {
          emailValue = user.email;
          role = user.roles_id;
        }
        // if (localStorage.getItem("chatSessionId") != null) {
			// setSessionId(localStorage.getItem("chatSessionId"));

			http.get('/fetchMessages/'+ sessionId).then((res) => {
				if (res.data.success === true) {
					const messages = res.data.data.map((item) => item.message);
					console.log(messages);
					const initialMessages = messages.map((msg) => createChatBotMessage(msg));
					const allMessages = [...affChatConfig.initialMessages,...initialMessages]; 
					console.log("allMessages:"+allMessages);
					setInitialMsg(allMessages);
					console.log("initialMessages:"+JSON.stringify(allMessages));
				}
				
			})  
		} catch (error) {
			console.error("Error initializing session:", error);
		}
    };

    // if (isChatbotVisible && !sessionId) {
      initializeChatSession();
    // }
  }, []);

  useEffect(() => {
    if (isChatbotVisible) {
		setChatState(initialMsg);
    }
  }, [isChatbotVisible,initialMsg]);

  const toggleChatbot = () => {
	console.log("called")
    setIsChatbotVisible((prevState) => !prevState);
  };

  	// useEffect(() => {
	//   const fetchNewMessages = async () => {
	// 	try {
	// 	  const response = await http.get(`/fetchNewMessages/${sessionId}`);
	// 	  if (response.data.success) {
	// 		const newMessages = response.data.data.map((msg) =>
	// 		  createChatBotMessage(msg.message)
	// 		);

	// 		response.data.data.map((msg) =>
	// 			console.log("msg.message"+msg.message)
	// 		);
  
	// 		// if (newMessages.length > 0) {
	// 		// 	setChatState((prevChatState) => [
	// 		// 	  ...prevChatState,
	// 		// 	  ...newMessages,
	// 		// 	]);
	// 		// }

	// 		if (newMessages.length > 0) {
	// 			setChatState((prevChatState) => {
	// 			  const updatedChatState = [...prevChatState, ...newMessages];
	// 			  console.log("Updated Chat State:", updatedChatState);
	// 			  return updatedChatState;
	// 			});
	// 		}

	// 	  }
	// 	} catch (error) {
	// 	  console.error("Error fetching new messages:", error);
	// 	}
	//   };
  
	//   const interval = setInterval(() => {
	// 	fetchNewMessages();
	//   }, 5000);
  
	//   return () => clearInterval(interval); // Cleanup interval on component unmount
	// }, [sessionId]);

  return (
	<Box
	sx={{
	  position: "fixed",
	  top: 130,
	//   right: 20,

	left:"50%",
	transform: "translateX(-50%)",
	  zIndex: 1000,
	}}
  >
	{/* <img
	  src={require("../../assets/images/chatforbutton.png")}
	  alt="Chatbot Toggle"
	  onClick={toggleChatbot}
	  style={{
		cursor: "pointer",
		width: "50px",
		height: "50px",
		borderRadius: "50%",
		boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
		backgroundColor: "#fff",
	  }}
	/> */}
	{isChatbotVisible && sessionId && chatState.length > 0 &&(
	  <Box
		sx={{
		  position: "fixed",
		  top: isMobile?"170px":"25px",
		//   right: 20,
		left: "50%",
		transform: "translateX(-50%)",
		  boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
		  borderRadius: "8px",
		  backgroundColor: "#fff",
		  zIndex: 9999,
		//   height: "400px", 
		  display: "flex",
		  flexDirection: "column", 
		}}
	  >
		<div
		  className="chatbot-container"
		  style={{
			flex: 1, 
			overflowY: "auto", 
			boxSizing: "border-box",
		  }}
		>
		  <Chatbot
			key={isChatbotVisible ? "visible" : "hidden"}
			config={{
			  ...affChatConfig,
			  initialMessages: initialMsg,
			  state: {
				...affChatConfig.state,
				isAffiliate: true,
			  },
			}}
			messageParser={MessageParser}
			actionProvider={ActionProvider}
			saveMessages={setChatState}
			messageHistory={chatState}
		  />
		</div>
	  </Box>
	)}
  </Box>
  );
});

export default AffChatbotComponent;
