import React, { useMemo, useState, useEffect } from 'react';
import DefaultLayout from '../../../Components/DefaultLayout';
import MaterialReactTable from 'material-react-table';
import AuthUser from '../../../Components/Auth/AuthUser';
import { Box, Tooltip, Button, Grid, DialogContent, DialogActions, DialogContentText, Dialog, DialogTitle } from '@mui/material';
import { Typography } from '@mui/joy';
import { useNavigate } from "react-router-dom";
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader';
import config from '../../../AppConfig';
import formatPhoneNumber from '../../../Components/Formats/PhoneNumberValidation';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import { Table, TableCell, TableContainer, TableRow, Paper,TableBody } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PersonRemoveOutlinedIcon from '@mui/icons-material/PersonRemoveOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PropTypes from 'prop-types';
import swal from "sweetalert";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

let companyname;
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}
const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
];
let csvArray = [];
let aidString = '';
function UserList(props) {
  //neccessary variable declearation start
  const dispatch = useDispatch();
  const { user ,http} = AuthUser();
  const [companylist,setCompanyList] = useState([]);
  const [loader,setLoader] = useState('');
  const [filterCompany, setFilterCompany] = useState([]);
  const [search, setSearch] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(100000);
  const q = search == '' ? 'null' : search;
  const navigate = useNavigate();
  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [showUserBtnText, setShowUserBtnText] = React.useState("Show All Users");
  const tablePreferencesArray = {
  }
 
  const [pidstring, setPIDString] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [disableUserId, setDisableUserId] = useState();
  const [userType, setUserType] = useState("active");
  const [operation, setOperation] = useState("");
  const [openConfrimationBox, setOpenConfirmationBox] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  useEffect(() => {
  }, [rowSelection]);
  ////////////////////////
  useEffect(() => {
    if (Object.values(columnVisibility).length > 0) {
      setColumnVisibility(columnVisibility);
    }
    //do something when the row selection changes...
  }, [rowSelection]);

  useEffect(() => {
    tablePreferences();
  }, [rowSelection]);
  ////////////////////////

  useEffect(() => {
    saveTablePreferences();
  }, [columnVisibility])

  const tablePreferences = async () => {
    const apiUrl = '/fetchTablePreferences/userlist';
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    if (res) {
      if (Object.values(res).length == 0) {
        setColumnVisibility(tablePreferencesArray);
      } else {
        setColumnVisibility((JSON.parse(res.preferences)));
      }
    }
  }

  const saveTablePreferences = async () => {
    if (Object.values(columnVisibility).length > 0) {
      const apiUrl = '/saveTablePreferences';
      const args = {
        columnArray: columnVisibility,
        userId: user.id,
        tableId: 'userlist'
      }
      const res = await dispatch(postData({ apiUrl, args })).unwrap();
    }
  }
  //neccessary variable declearation end
  //loader function and variable start
  const [freez, setFreez] = React.useState(false);
  const handleFreezStop = () => {
    setFreez(false);
  };
  const handleFreezStart = () => {
    setFreez(true);
  };

  //loader function and variable end
  //fetchuserlist function start
  const fetchUserList = async page => {
    handleFreezStart();
    var type = userType ? userType : "active";
    const apiUrl = '/fetchUserList/' + type + '/' + q + '/' + perPage + '/list?page=' + page;
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    if (res) {
      setCompanyList(res.data);
      setFilterCompany(res.data)
      setTotalRows(res.total);
      handleFreezStop();
      setIsLoading(false);
      setLoader('');

    }
  }
  const fetchUserBasePlan = async () => {
    const apiUrl = '/fetchUserBasePlan';
    const res = await dispatch(fetchData(apiUrl)).unwrap();
    if (res) {
      if (res.data != null) {
        setPIDString(res.data.join(", "));
      }
    }
  }

  const createUser = () => {
    var check = checkAdminoRAffiliate();
    if(check === true) {
      navigate('/createuser');
    }
  }

  const editUser = (id,companyId) => {
    var check = checkAdminoRAffiliate();
    console.log("check:" +check);
    if(check === true) {
      const loginUser = JSON.parse(localStorage.getItem('user'));
      if(loginUser.roles_id <= 3 ) {
        if(loginUser.roles_id == 3 && loginUser.company_master_id != companyId) {
            navigate('/login');
            return false;
        }
        navigate('/edituser/' + id);
      }
      
    }
  }

  const checkAdminoRAffiliate = () => {
    var check = 1;
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if(loginUser.roles_id  > 3) {
        check = 0;
    } 
    if(check == 0) {
        navigate('/login');
        return false;
    }

    return true;
  }

  const changeUserStatus = async (id, role, operation,companyId) => {
    
    var check = checkAdminoRAffiliate();
    if(check === true) {
      const loginUser = JSON.parse(localStorage.getItem('user'));
      if(loginUser.roles_id <= 3 ) {
        if(loginUser.roles_id == 3 && loginUser.company_master_id != companyId) {
            navigate('/login');
            return false;
        }
      }
      if (((role == 3 || role == 7) && operation == "disable") || ((role >= 3  && role <=5 ) && operation == "active")) {
        const apiUrl = '/check-company-affiliates';
        const args = {
          id: id,
        }
  
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        if (res) {
          var Msg ="";
          if (res.success == true) {
            var count = res.data.activeAffiliate;
            if (count <= 1) {
              if(res.data.company && res.data.company != ""){
                if(res.data.company.is_disabled == 1) {
                  Msg = "To activate this user, please activate it's company first!";
                }
                else if (role == 3 && operation == "disable") {
                  Msg = "You need to have atleast one active affiliate for a company";
                }
                else if (role == 7 && operation == "disable") {
                  Msg = "You need to have atleast one active partner admin for a software type";
                }
              }
              if(Msg != "") {
                swal({
                  title: "Failed",
                  text: Msg,
                  icon: "error",
                  button: "Ok",
                })
                return false;
              }
              
            }
          }
        }
      }
      if (operation == "disable") {
        setOperation("disabled");
      } else {
        setOperation("active");
      }
      setDisableUserId(id);
      
      setOpen(true);
    }
  }

  const editPassword = async (email) => {
    const apiUrl = '/forgot-password';
    const args = {
        email: email, 
        url: config.BASE_URL
    }
    const res = await dispatch(postData({apiUrl,args})).unwrap();
    if(res) {
        if (res.success === true) {
            swal({
              title: "Success",
              text: res.message.message,
              icon: "success",
              button: "Ok",
            })
        } else {
          swal({
            title: "Failed",
            text: res.message.error,
            icon: "error",
            button: "Ok",
          })
        }
    }
  }

  const enableDisableUser = async () => {
    handleClose();
    var check = checkAdminoRAffiliate();
    if(check === true){
      const apiUrl = '/user-softdelete';
      const args = {
        id: disableUserId,
        requestType: operation,
      }
  
      const res = await dispatch(postData({ apiUrl, args })).unwrap();
      if (res) {
        if (res.success === true) {
          swal({
            title: "Success",
            text: res.message,
            icon: "success",
            button: "Ok",
          })
        } else {
          swal({
            title: "Failed",
            text: res.message,
            icon: "error",
            button: "Ok",
          })
        }
      }
      fetchUserList();
    }
   
  }

  const changeUserType = () => {
    var check = checkAdminoRAffiliate();
    if(check === true) {
      if (userType == "active") {
        setUserType("all");
        setShowUserBtnText("Show Active Users");
      } else {
        setUserType("active");
        setShowUserBtnText("Show All Users");
      }
    }
    
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchUserBasePlan();
    fetchUserList();
  }, [userType])

  const customSortFun = (rowA, rowB) => {
		
		const sortA = rowA.original.company != null && rowA.original.company.marketing_type !=  null ? rowA.original.company.marketing_type : rowA.original.software_name != null ? rowA.original.software_name : "";
		const sortB = rowB.original.company != null && rowB.original.company.marketing_type !=  null ? rowB.original.company.marketing_type : rowB.original.software_name != null ? rowB.original.software_name : "";
    return sortA.localeCompare(sortB, undefined, { sensitivity: 'base' });
	};

  //fetch user details end


  //useeffect start

  //useeffect end
  //fetchuserlist function start
  //deleteuser function start
  const deleteUser = (async (id) => {
    const apiUrl = 'delete-user/' + id;
    const res = await dispatch(fetchData(apiUrl)).unwrap();

  });
  //deleteuser function end

  const deleteLog= async ()=>{
    setOpenConfirmationBox(false)
    handleFreezStart(true)
    const apiUrl = '/user/delete';
    const args = {
      id: deleteId,
    }
		const res = await dispatch(postData({ apiUrl, args })).unwrap();
    if(res) 
		{
      fetchUserList()
      handleFreezStop(false)
      if (res.success === true) {
        swal({
            title: "Success",
            text: res.message,
            icon: "success",
            button: "Ok",
        })
      } else {
        handleFreezStop(false)
        swal({
          title: "error",
          text: res.message,
          icon: "error",
          button: "Ok",
        })
      }
    }
  }

  //columns array start
  const UserListColumns = useMemo(
    //column definitions...
    () => [
      {
        accessorFn: (row) => `${row.name? row.name.trim():''}`,
        header: 'Name',
        size: 50,
        id: 'name',
        minSize: 200,
        maxSize: 400,
        enablePinning: true,
        Cell: ({ renderedCellValue, row }) => (row.original.name ? row.original.name.trim() : 'N/A'),
      },
      {
        accessorFn: (row) => {
          let aidString = ''; // Initialize aidString
          {
            row.aid_for_user ? row.aid_for_user.length > 0 ? row.aid_for_user.map((aid_for_user) => {
              if (row.id == aid_for_user.users_id) {
                aid_for_user.mapped_user_aids.map((data) => {
                  if (aidString == '') {
                    aidString = data.aid;
                  } else {
                    aidString = aidString + ', ' + data.aid;
                  }
                })
              }
            })
              : aidString = '' : aidString = ''}
          return aidString || 'N/A'; // Return aidString or 'N/A' if it's not available
          },
        header: 'Aid List',
        id:"aid_list",
        size: 50,
        minSize: 150,
        maxSize: 400,
        enablePinning: true,
        Cell: ({ renderedCellValue }) => (
          <Box  className={` ${aidString ? 'datatable' : ''}`}>
            {renderedCellValue}
          </Box>
        ),
      },
      {
        accessorFn: (row) => `${row.id}`,
        header: 'ID',
        size: 50,
        id: 'id',
      },
      
      {
        accessorFn: (row) => `${row.email? row.email.trim():''}`,
        accessorKey: 'email', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: true,
        header: 'Email Address',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.email ? row.original.email.trim() : 'N/A'),
      },
      {

        accessorKey: 'phone_no',
        accessorFn: (row) => `${(row.phone_no) || (row.phone_no !== '') ? (formatPhoneNumber(row.phone_no)) : 'N/A'}`,
        filterVariant: 'range',
        header: 'Phone no.',
        size: 50,
        //custom conditional format and styling
      },
      {
        accessorFn: (row) => `${row.role? row.role.role_name ? row.role.role_name.trim():'N/A' : 'N/A'}`,
        accessorKey: 'roles_id',
        filterVariant: 'range',
        header: 'User Type',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.role? row.original.role.role_name ? row.original.role.role_name.trim() : 'N/A':'N/A'),
      },
      {
        accessorFn: (row) => `${row.company}`,
        accessorKey: 'software_name',
        filterVariant: 'range',
        header: 'Software Name',
        size: 50,
        sortingFn: customSortFun,
        Cell: ({ renderedCellValue, row }) => (
          <div className='datatable'>
						{row.original.company != null && row.original.company.marketing_type !=  null ? row.original.company.marketing_type : row.original.software_name != null ? row.original.software_name : "N/A"}
					</div>
        ),
        //custom conditional format and styling
      },


      {
        accessorFn: (row) => `${row.company? row.company.company_name ? row.company.company_name.trim():'N/A' : 'N/A'}`,
        filterVariant: 'range',
        header: 'Company Name',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (row.original.company? row.original.company.company_name ? row.original.company.company_name.trim() : 'N/A' : 'N/A'),
      },
     
      {
        accessorFn: (row) => `${row.is_active}`,
        filterVariant: 'range',
        id: 'status', //id is still required when using accessorFn instead of accessorKey
        header: 'Status',
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
          <Box

          >
            <span>{row.original.is_active ? "Active" : "Deactivated"}</span>
          </Box>
        ),
      },
      {
        accessorFn: (row) => `${row.id}`,
        id: 'actions',
        header: 'Actions',
        Cell: ({ renderedCellValue, row }) => (
          <Box>
            {
                  user.roles_id=== 13 ?
                 (<Tooltip title="Delete" arrow >
                   <img src={require('../../../assets/icons/deleteIcon2.png')}  onClick={()=>{setDeleteId(row.original.id);setOpenConfirmationBox(true)}}/>
                 
                  </Tooltip>) :
                   ( user.id != row.original.id ? 
                    row.original.is_active ?
                      <>
                        <Tooltip title="Edit User" arrow><EditOutlinedIcon onClick={() => {editUser(renderedCellValue,row.original.company_master_id)}} /></Tooltip>
                        <Tooltip title="Deactivate" arrow>
                          <PersonRemoveOutlinedIcon
                            onClick={() => changeUserStatus(row.original.id, row.original.roles_id, 'disable',row.original.company_master_id != null ? row.original.company_master_id : null)}
                          />
                        </Tooltip>

                        {(user.roles_id === 1 ||user.roles_id === 2) && (
                          <Tooltip title="Reset Password Link" arrow>
                            <LockOutlinedIcon 
                              onClick={() => editPassword(row.original.email)} 
                            />
                          </Tooltip>
                        )}
                      </> 
                    : 
                    <Tooltip title="Activate" arrow>
                      <PersonAddAltIcon sx={{ ml: 3 }}
                        onClick={() => changeUserStatus(row.original.id, row.original.roles_id, 'active',row.original.company_master_id != null ? row.original.company_master_id : null)}
                      />
                    </Tooltip>
                  : 
                    '')
            

           
            }
          </Box>
        ),

      },
    ]
  )
//  csv code start here

const csvOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: false,
  fileName: 'User-List',
  headers: ['ID', 'Name', 'Email Address', 'Phone No.', 'User Type', 'Software Name', 'Company Name', 'AID List', 'Status', 'Default Timezone', 'Base PID'],
};
const csvExporter = new ExportToCsv(csvOptions);
const handleExportRows  = (rows) => {
  
  if(localStorage.getItem("admin") != null) {
    navigate('/login');
    return false;
  }
  const exportedData = rows.map((row) => {
    
    let aidString = '';
    if (row.original.aid_for_user && Array.isArray(row.original.aid_for_user) && row.original.aid_for_user.length > 0) {
      row.original.aid_for_user.forEach((aid_for_user) => {
        if (row.original.id === aid_for_user.users_id) {
          aid_for_user.mapped_user_aids.forEach((data) => {
            if (aidString === '') {
              aidString = data.aid;
            } else {
              aidString += ', ' + data.aid;
            }
          });
        }
      });
    }

    let pidString = '';
    if (row.original && row.original.base_plan && row.original.base_plan.base_pid) {
      pidString = row.original.base_plan.base_pid;
    }


    const softwareName = row.original.company?.marketing_type || row.original.software_name || "N/A";


    return {
      id: row.original.id || "N/A",
      name: row.original.name || "N/A",
      email: row.original.email || "N/A",
      phone: formatPhoneNumber(row.original.phone_no) || "N/A",
      role_name: (row.original.role && row.original.role.role_name) ? row.original.role.role_name : "N/A",
      software_name: softwareName,
      company_name: row.original.company?.company_name || "N/A",
      aid: aidString || 'N/A',
      status: row.original.is_active == 1 ? "Active" : "Not Active",
      default_timezone: row ? row.original.default_timezone : "N/A",
      'PID': pidstring,
    };
  });
  
// csvExporter.generateCsv(exportedData);

    // Convert data to CSV format
    const csvContent = convertToCSV(exportedData);

    // Create a Blob object from the CSV data
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

    // Create a download link element
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = csvOptions.fileName + '.csv';

    // Append the link to the document body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the file download
    link.click();

    // Clean up the temporary URL and remove the link from the document
    URL.revokeObjectURL(link.href);
    link.remove();
  };

  const convertToCSV = (data) => {
    const headers = csvOptions.headers.join(',') + '\n';

    const rows = data.map((row) => {
      return Object.values(row).map((value) => {
        // Handle any necessary formatting or escaping of values
        // For simplicity, we assume all values are already properly formatted

        // Wrap the value with double quotes if it contains a comma
        if (typeof value === 'string' && value.includes(',')) {
          return `"${value}"`;
        }

        return value;
      }).join(',');
    }).join('\n');

    return headers + rows;
  };

// csv code end here
  const ContentLayout=
      <>
        <Grid container item lg={12} md={12} sm={12} xs={12}>
          <Grid>
            <Typography level="h4" color="primary">Active Users</Typography>
            {user.roles_id!=13?
            <>
             <Typography level="h5" gutterBottom>
             Edit and create new users. Manage access to the portal for others in your company
             </Typography>
             <Button variant="contained" color="success" onClick={createUser}>
             Create User
             </Button>
               &nbsp;&nbsp;
            <Button variant="contained" color={showUserBtnText == "Show All Users" ? "primary" : "success"}
             onClick={() => { changeUserType() }}
             >
             {showUserBtnText}
           </Button></>:null}
           
          </Grid>
        </Grid>
        <Grid mt={4}>
          <MaterialReactTable
            columns={UserListColumns}
            data={filterCompany}
            filterFns={{
              customSearchFilterFn: (row, id, filterValue) =>
              row.getValue(id).toLowerCase().replace(/\s/g, '').includes(filterValue.toLowerCase().replace(/\s/g, '')),
            }}   
            globalFilterFn="customSearchFilterFn" //exact serach filter functionality
            enableColumnFilterModes
            enableColumnFilters={config.DatatableColumnFilter}
            enableColumnOrdering={false}
            enableRowSelection={user.roles_id != 13}
            enablePinning
            initialState={{ showColumnFilters: false, density: 'compact' ,
              columnPinning: { left: ['mrt-row-expand', 'mrt-row-select', 'name', 'aid_list'] },
            }}
            positionToolbarAlertBanner="bottom"
            enableDensityToggle={false}
            onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
            state={{ columnVisibility, rowSelection, isLoading: isLoading }}
            onColumnVisibilityChange={(state) => { setColumnVisibility(state) }}
            muiTableHeadCellProps={{
              //simple styling with the `sx` prop, works just like a style prop in this example
              sx: {
                backgroundColor: "#F6FAFF",
              },
            }}
            renderDetailPanel={({ row }) => (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                }}
              >
                <>
                  <TableContainer component={Paper}>
                    <Table className="back">
                      <TableBody>
                        <TableRow>
                          <TableCell style={{ fontWeight: 'bold' }}>Name:</TableCell>
                          <TableCell>{row.original.name}</TableCell>
                          <TableCell style={{ fontWeight: 'bold' }}>Email:</TableCell>
                          <TableCell>{row.original.email}</TableCell>
                          <TableCell style={{ fontWeight: 'bold' }}>Phone No:</TableCell>
                          <TableCell>{formatPhoneNumber(row.original.phone_no)}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ fontWeight: 'bold' }}>User Type:</TableCell>
                          <TableCell>{row.original.role ? row.original.role.role_name ? row.original.role.role_name : 'N/A':'N/A'}</TableCell>
                          <TableCell style={{ fontWeight: 'bold' }}>Aid List:</TableCell>
                          <TableCell style={{ width: "350px" }}>
                            <>
                              {aidString = ''}
                              {
                                row.original.aid_for_user ? row.original.aid_for_user.length > 0 ? row.original.aid_for_user.map((aid_for_user) => {
                                  if (row.original.id == aid_for_user.users_id) {
                                    aid_for_user.mapped_user_aids.map((data) => {
                                      if (aidString == '') {
                                        aidString = data.aid;
                                      } else {
                                        aidString = aidString + ', ' + data.aid;
                                      }
                                    })
                                  }
                                })
                                  : aidString = '' : aidString = ''}
                              <Typography>{aidString}</Typography>
                            </>
                          </TableCell>
                          <TableCell style={{ fontWeight: 'bold' }}>Status:</TableCell>
                          <TableCell>{row.original.is_active == 1 ? "Active" : "Not Active"}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ fontWeight: 'bold' }}>Default timezone</TableCell>
                          <TableCell>{row ? row.original.default_timezone ? row.original.default_timezone : 'N/A' : 'N/A'}</TableCell>
                          <TableCell style={{ fontWeight: 'bold' }}>Company Name:</TableCell>
                          <TableCell>{row.original.company ? row.original.company.company_name ? row.original.company.company_name : 'N/A' : 'N/A'}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {/* loader code start */}
                  <div>
                    <Backdrop
                      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                      open={freez}
                      onClick={handleFreezStop}
                    >
                      <Freez />
                    </Backdrop>
                  </div>
                  {/* loader code end */}

                </>
              </Box>
            )}
            renderTopToolbarCustomActions={({ table }) => (
              <Box
                sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
              >
                <Button
                  sx={{ bgColor: "#2A68B3" }}
                  disabled={table.getPrePaginationRowModel().rows.length === 0}
								//export all rows, including from the next page, (still respects filtering and sorting)
                  onClick={() =>
                    handleExportRows(table.getPrePaginationRowModel().rows)
                  }
                  startIcon={<FileDownloadIcon />}
                  variant="contained"
                  className='export'
                >
                  CSV
                </Button>
              </Box>
            )}
          />



          {/* <Pagination/> */}
        </Grid>
        <Dialog
                                fullWidth
                                maxWidth={'sm'}
                                open={openConfrimationBox}
                                // onClose={handleClose}
                                onClose={(_, reason) => {
                                    if (reason !== "backdropClick") {
                                        setOpenConfirmationBox(false);
                                    }
                                }}
                            >
                 
                                <DialogContent>
                                    <Box mt={1} sx={{textAlign:'center'}}>
                                        <Typography level='h4'>Are you sure you want to delete this user?</Typography>
                                    </Box>
                                </DialogContent>
                                <DialogActions sx={{ margin: 'auto' }} >
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button variant="contained" onClick={()=>{deleteLog()}} color="success" sx={{ minWidth: 250 }} >
                                            Delete
                                        </Button>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button variant="contained" onClick={() => setOpenConfirmationBox(false)} color="primary" sx={{ minWidth: 250 }}>
                                            Cancel
                                        </Button>
                                    </Grid>
                                </DialogActions>
                    </Dialog>
      </>
   
  

  return (
    <div>
      <DefaultLayout content={ContentLayout} />
      {/* loader code start */}
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={freez}
        >
          <Freez />
        </Backdrop>
      </div>
      {/* loader code end */}
      <BootstrapDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose} sx={{fontSize:"1.5rem",fontWeight:"bold"}}>
            {operation == "disabled" ? "Deactivate" : "Activate"} User
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: "black" }}>
            Are you sure you want to {operation == "disabled" ? "deactivate" : "activate"} this user?</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button variant="contained" color="primary" sx={{ minWidth: 150 }} onClick={() => { handleClose() }}>
            No
          </Button>
          <Button variant="contained" color='success' sx={{ minWidth: 150 }} onClick={() => { enableDisableUser(); }}>
            Yes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}


export default UserList;