import React, { useState, useEffect } from "react";
import { DialogContent, Grid, Select, FormControl, MenuItem, InputLabel, Box, TextField, Dialog, InputAdornment, DialogActions, OutlinedInput, Backdrop, DialogTitle, Tab } from '@mui/material';
import { Typography, Card, CardContent, CardOverflow, Button, List, ListItem, ListItemDecorator, ListItemContent } from '@mui/joy';
import AuthUser from '../Auth/AuthUser';
import config from '../../AppConfig';
import LogoEdit from '../../Components/Cobrand/LogoEdit';

let logoFlag = 0;

// function UploadSnapshotLogo(props) {
    const UploadSnapshotLogo = ({ creditinfo,firstlogoUrlValue,companyId, fetchCreditInfo}) => {
    const { user } = AuthUser();
    const [uploadLogo, setUploadLogo] = React.useState(false);
    const [firstlogoUrl, setFirstLogoUrl] = useState(firstlogoUrlValue);
console.log("firstlogoUrl :"+firstlogoUrlValue);
    const handleCloseUploadLogo = (value) => {
        setUploadLogo(false);
    }

    const handleLogoURL = (image) => {
        setFirstLogoUrl(image);
    }

    const handleLogoFlag = (flag) => {
        logoFlag = flag;
    }

    return(
        <>
          <Grid container item lg={12} md={12} sm={12} xs={12} mt={1} >
                <Card sx={{width:"100%", boxShadow:"none"}}>
                    <CardOverflow
                        sx={{
                            padding: '1%',
                        }}
                    >
                        <Typography level="h5">Upload Or Choose The Default Logo</Typography>
                    </CardOverflow>
                    <CardContent>
                        <Grid container item lg={12} md={12} sm={12} xs={12} >
                            <Box >
                                <Card size='md' variant="outlined">
                                    <CardContent >
                                        {
                                            creditinfo
                                                ?
                                                firstlogoUrl
                                                    ?
                                                    <img className="dashlogo" alt="#" style={{ height: '90px' }} src={config.BACKEND_URL + "images/companyLogo/" + firstlogoUrl} />
                                                    :
                                                    <img className="dashlogo" alt="#" style={{ height: '90px' }} src={require("../../../src/assets/images/uploadLogo.png")} />
                                                :
                                                <img className="dashlogo" alt="#" style={{ height: '90px' }} src={require("../../../src/assets/images/uploadLogo.png")} />
                                        }
                                    </CardContent>
                                </Card>
                                <Box py={2} sx={{ textAlign: 'center' }}>
                                    {user.roles_id <= 3 ?
                                        <Button size="md" className="CustomButton" sx={{
                                            minWidth: 300,
                                           
                                        }} onClick={(e) => { setUploadLogo(true) }}>{firstlogoUrl ? "EDIT LOGO" : "Upload"}</Button>
                                        : null}
                                    {!firstlogoUrl ? <p style={{ fontSize: '20px' }} className="error">Upload logo to complete step-2</p> : null}
                                </Box>
                            </Box>
                            <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}><Typography>Dimensions of logo should be 300 x 90 pixels and size should not exceed 50 KB</Typography></Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            
            <Dialog
                open={uploadLogo}
                // onClose={handleCloseUploadLogo}
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleCloseUploadLogo();
                    }
                  }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
            <LogoEdit id={companyId} logoEditURL={handleLogoURL} logoFlagEdit={handleLogoFlag} setOpen={handleCloseUploadLogo} source="company" fetchCreditInfo={fetchCreditInfo}/>
        </Dialog>
        </>
    );
}

export default UploadSnapshotLogo;