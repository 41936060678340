import React, { useState, useEffect, useRef } from "react";
import EnrollFooter from '../../Auth/EnrollFooter';
import EnrollHeader from "../../../Components/EnrollHeader";
import AuthUser from '../../../Components/Auth/AuthUser';
import { Modal,List, Paper, Box,ListItem,ListItemText,TextField,DialogActions, DialogContentText, Stepper, Step, StepLabel, 
    useMediaQuery,Grid,Divider, InputAdornment, FormControl, InputLabel, OutlinedInput, 
    IconButton, FormHelperText,Dialog, DialogTitle, DialogContent,Stack,Button  } from '@mui/material';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import { useParams, useNavigate } from "react-router-dom";
import Freez from '../../../Components/Loader/Loader';
import Backdrop from '@mui/material/Backdrop';
import { Typography, Card, CardContent, CardOverflow} from '@mui/joy';
import { Formik, Form } from 'formik';
import swal from "sweetalert";
import * as Yup from 'yup';
import formatPhoneNumber from '../../../Components/Formats/PhoneNumberValidation';
import PrivacyPolicy from '../../FooterPages/PrivacyPolicy';
import TermsAndConditions from '../../FooterPages/TermsAndConditions';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import config from '../../../AppConfig';
import axios from "axios";
import RemoveIcon from '@mui/icons-material/Remove';
import SendIcon from "@mui/icons-material/Send";
import TextareaAutosize from '@mui/base/TextareaAutosize';
const steps = [
    'Step 1',
    'Step 2',
];

let emailValue = "";
let role = null;
let chatSessionId = null;
let score = null;

function EnrollStep1(props) {
    const navigate = useNavigate();
    const { http } = AuthUser();
    const { id } = useParams();
    const [aid, setAID] = useState();
    const [checkUsaTldStatus, setCheckUsaTldStatus] = useState(false);
    const [emailButton, setEmailButton] = useState(false);
    const [creditSnapshotObj, setCreditSnapshotObj] = useState(null);
    // Function to decode base64 string

    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .matches(
                /^(?=[-0-9A-Za-z.#'\s]*$)/,
                'Please enter a valid First Name'
            )
            .required('First Name is required'),
        lastName: Yup.string()
            .matches(
                /^(?=[-0-9A-Za-z.#'\s]*$)/,
                'Please enter a valid Last Name'
            )
            .required('Last Name is required'),
        email: Yup.string()
           
            .matches(/^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24})$/i, "Invalid email format.")
            .required('Email is required'),
        password: Yup.string()
            .min(8, 'Password must be at least 8 characters')
            .matches(
                /^(?=[-a-zA-Z0-9@_*.]*$)/,
                'Please enter a valid Password. *.-_@ are allowed special characters'
            )
            .required('Password is required'),
    });
    //Step1 Function start
    const [Dtoken, setDtoken] = useState();
    const [rtoken, setRtoken] = useState();
    const [paToken, setpaToken] = useState();
    const [userid, setUserId] = useState();
    const [errormsg, setErrorMsg] = useState();
    const [phonenumber, setPhoneNumber] = useState();
    const [companyName, setCompanyName] = useState('');
    const [enrolledStatus, setEnrolledStatus] = useState(0);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [openIframe, setOpenIframe] = useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const [chatEnable, setChatEnable] = React.useState(false);
    const [companyObj, setCompanyObj] = React.useState(false);
    const [isChatOpen, setIsChatOpen] = useState(false);
    const chatbotRef = useRef(null);
    const [startJourneyOpen, setStartJourneyOpen] = useState(false);
    const [isMinimized, setIsMinimized] = useState(true); // Minimize state
    const [sessionId, setSessionId] = useState(localStorage.getItem("chatSessionId") != undefined ? localStorage.getItem("chatSessionId")  : null);
    const [isSessionCleared, setIsSessionCleared] = useState(false);
    const [affName, setAffName] = useState();
    const [openModal, setOpenModal] = useState(false);
    const messagesEndRef = useRef(null);
    const[messages, setMessages] = useState([]);
    const [assistYouFlag, setAssistYouFlag] = useState(false);
    const [emailChatopen, setEmailChatopen] = useState(false);
    const [input, setInput] = useState("");
    const [open, setOpen] = React.useState(false);
    const [msgChatopen, setMsgChatopen] = useState(false);
    const [memberMsg, setMemberMsg] = useState("");
    const [BlackboxObj, setBlackboxObj] = useState(null);
    const { userId } = useParams();
    const [chatEmail, setChatEmail] = useState("");
    const [inputEmail, setInputEmail] = useState("");
    const [isWarningDisplayed, setIsWarningDisplayed] = useState(false);
    const isWarningDisplayedRef = useRef(false);
    useEffect(() => {
        isWarningDisplayedRef.current = isWarningDisplayed;
    }, [isWarningDisplayed]);
    const [isSetMsg, setIsSetMsg] = useState(false);

    const handleSessionContinue = () => {
        localStorage.setItem("lastMessageTime", Date.now()); 
        setMessages((prevMessages) =>
          prevMessages.filter((msg) => msg.type !== "warning")
        );
        setIsWarningDisplayed(false);
        
    };

    const handleSessionClear = () => {
        localStorage.removeItem("lastMessageTime");
        handleClearSession(1); 
        setIsWarningDisplayed(false);
    };

    const [outOfOffc, setOutOfOffc] = useState(false);

    const scrollToBottom = () => {
    if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleIFrame = () => {
    setOpenIframe(true);
  }

  const handleCloseDialog = () => {
    setOpenIframe(false);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
    //Get Phone number by AID API Starts
    const GetPhoneNumber = (receivedaid) => {
        handleFreezStart();
        http.post("/getProfileOnAID", {
            aid: receivedaid
        })
            .then((res) => {
                handleFreezStop();
                setPhoneNumber( res.data.data.phone ?  res.data.data.phone : '');
                setCompanyName( res.data.data.company ?  res.data.data.company : '');
                localStorage.setItem('SnapShot_phone', res.data.data.phone);
            })
    }
    //Get Phone number by AID API Ends
    //loader function and variable start
    const [freez, setFreez] = React.useState(false);
    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
    //loader function and variable end
    const toggleDrawer3 = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setOpen3(open);
    };
    const toggleDrawer4 = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setOpen4(open);
    };
    const SnapShotErollStep1 = (values) => {
        handleFreezStart();
        http.post("/creditSnapshotEnrollStep1", {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            password: values.password,
            source: "default",
            aid: aid
        })
            .then((res) => {
                handleFreezStop();
                if (res.data.success === true) {
                    setDtoken(res.data.data.dtoken)
                    setRtoken(res.data.data.rtoken)
                    setUserId(res.data.data.userId)
                    setpaToken(res.data.data.paToken)
                    localStorage.setItem('Dtoken', res.data.data.dtoken);
                    localStorage.setItem('rtoken', res.data.data.rtoken);
                    localStorage.setItem('paToken', res.data.data.paToken);
                    localStorage.setItem('UserId', res.data.data.userId);
                    localStorage.setItem('SnapShot_firstName', values.firstName);
                    localStorage.setItem('SnapShot_lastName', values.lastName);
                    localStorage.setItem('SnapShot_email', values.email);
                    localStorage.setItem('SnapShot_password', values.password);
                    localStorage.setItem('SnapShot_aid', aid);
                    navigate('/en/creditsnapshot/user/register/'+aid+'/enrollstep2');
                } else {
                    if (res.data.success === false) {
                        if (res.data.data.enrolledStatus == true) {
                            setEnrolledStatus(1);
                        }
                    }
                    handleFreezStop();
                    setErrorMsg(res.data.data.errorByStichCredit)
                }
            })
            .catch((error) => {
                handleFreezStop();
                swal({
                    title: "Failed",
                    text: error.message,
                    icon: "error",
                    button: "Ok",
                })
            });
    }
    //create function end
    //get Snapshot AID function and variable starts
    const loginLink = () => {
        navigate('/snapshotlogin');
    }
    const getSnapAID = () => {
        handleFreezStart();
        http.post("/getsnapshotaid", {
            id: id
        })
            .then((res) => {
                handleFreezStop();
                setAID(res.data.data)
                GetPhoneNumber(res.data.data);
            })
    }
    //get Snapshot AID function and variable ends
    useEffect(() => {
        getSnapAID()
    }, [])

    const checkUsaTLD = (email) => {
        if (email.includes('@')) {
            setEmailButton(true);
            http.post("/checkUsaTLD", {
                email: email,
            })
            .then((res) => {
                if (res.data.success === true) {
                    setCheckUsaTldStatus(true);
                } else {
                    setCheckUsaTldStatus(false);
                }
            })
            setEmailButton(false);
        }
    }

    const getCreditSnapshotDetails = (value) => {
        setCompanyObj(value);
        setChatEnable(value.enable_chat_support == 1 ? true : false);
        localStorage.setItem('SnapShot_chat_support', value.enable_chat_support == 1 ? true : false);
        setCreditSnapshotObj(value);
    }
    const sendMessage = (message) => {
        chatbotRef.current.handleNewUserMessage(message);
    };

    const startCreditRepairJourney = () => {
        setStartJourneyOpen(true);
    }

    const openChatPopup = (e) => {
        setIsChatOpen(true);
        var sessionMessagesObj;
        if(localStorage.getItem("sessionMessages") != undefined) {
            sessionMessagesObj = localStorage.getItem("sessionMessages");
        }
        e.preventDefault();
        const element = document.getElementById("chatbotDiv");
        element.style.display = "block";
        setIsMinimized(!isMinimized);
        
        setStartJourneyOpen(false);
        chatSessionId = localStorage.getItem('chatSessionId');
        if(chatSessionId != null){
            const sessionMessage1 = localStorage.getItem("sessionMessages");
            if (sessionMessage1) {
            const parsedMessages = JSON.parse(sessionMessage1);
                setMessages(parsedMessages);
            }
            
            setAssistYouFlag(true);
        }else{
        const Fname = localStorage.getItem('first_name')?.trim() ? localStorage.getItem('first_name') : 'Friend';
        if(sessionMessagesObj > 1) {
            setMessages(sessionMessagesObj);
        }
        if(!assistYouFlag){
        setMessages((prev) => [
            ...prev,
            { text: "Hello "+`${Fname}`+", Thank You For Reaching Out.", type: "bot" },
        ]);
        var sessionMessages2 = localStorage.getItem('sessionMessages');
        if(sessionMessages2 == null || sessionMessages2 == undefined) {
            var sessionMessage1 = ([
            { text: "Hello "+`${Fname}`+", Thank You For Reaching Out.", type: "bot" },
            ]);
            localStorage.setItem("sessionMessages",JSON.stringify(sessionMessage1)); 
        }
            setAssistYouFlag(true);
        }
    }
    
    // if (chatbotRef.current) {
    //   chatbotRef.current.toggleChatbot();
    // }
    }

    const openEmailChatPopup = () => {
        setStartJourneyOpen(false);
        setEmailChatopen(true);
        setIsMinimized(!isMinimized);
    }

    const closeStartJourneyOpen = () => {
        setStartJourneyOpen(false);
    }

    const closeChatPopup = () => {
        const element = document.getElementById("chatbotDiv");
        element.style.display = "none";
    }

    const closeEmailChatPopup = () => {
        setStartJourneyOpen(false);
        setEmailChatopen(false);
    }

    useEffect(() => {
        scrollToBottom();
      }, [messages,isSessionCleared, outOfOffc]);
      const handleSendMessage = async() => {
        chatSessionId = localStorage.getItem('chatSessionId');
        setIsSetMsg(true);
        if(chatSessionId == null) {
        //   setFreez(true);
          if (chatEmail != null) {
            emailValue = chatEmail;
            role = null;
          }
          // localStorage.setItem("chatbot_email", emailValue);
          const Fname = localStorage.getItem('first_name')?.trim() ? localStorage.getItem('first_name') : 'Friend';
          const response = await axios.post(
            config.API_BASE_URL + "/initialize-chat-session",
            { email: emailValue, role: role, member_name: Fname, companyId : companyObj.company_master_id }
          );
          setSessionId(response.data.session_id);
          localStorage.setItem("chatSessionId", response.data.session_id);
          localStorage.setItem("chatbot_email", chatEmail);
        //   setFreez(false);
         
        }
        chatSessionId = localStorage.getItem('chatSessionId');
        emailValue = localStorage.getItem('chatbot_email');
        if (input.trim() && chatSessionId) {
              var sessionMessagesObj = localStorage.getItem("sessionMessages"); 
              var sessionMessage1 = JSON.parse(sessionMessagesObj);
              sessionMessage1.push({ text: input, type: "user" });
              localStorage.getItem("sessionMessages",JSON.stringify(sessionMessage1));
              localStorage.setItem("sessionMessages",JSON.stringify(sessionMessage1));  
    
            setMessages([...messages, { text: input, type: "user" }]);
            var sessionMessages2 = localStorage.getItem('sessionMessages');
            setInput("");
            
            const response2 = await axios.post( 
              config.API_BASE_URL + "/saveMemberMessage", { message: input, session_id: chatSessionId, email : emailValue });
            
        }
        setIsSetMsg(false);
      };
    
      const handleClearSession = async(expired=0) => {
        var sessionId = localStorage.getItem('chatSessionId');
        const response2 = await axios.post(
          `${config.API_BASE_URL}/clearSession/${sessionId}`,{expired:expired,reason:"Clear Session due to inactivity"}
        );
        if (response2.data.success == true) {
          if(isSessionCleared)
          {
            setIsSessionCleared(false);
          }else {
            setIsSessionCleared(true);
          }
        }
      };
    
        useEffect(() => {
        if (chatEmail) {
            if (!localStorage.getItem("lastMessageTime")) {
                localStorage.setItem("lastMessageTime", Date.now());
            }
            const sessionMesgs = JSON.parse(localStorage.getItem('sessionMessages'));
            if (sessionMesgs && Array.isArray(sessionMesgs) && sessionMesgs.length > 0) {
                sessionMesgs.forEach(msg => {
                if (msg.type === "outofoffice") {
                    setOutOfOffc(true);
                }
                });
            }
          const fetchNewMessages = async () => {
          try {
            chatSessionId = localStorage.getItem('chatSessionId');
            emailValue = chatEmail;
            if(chatSessionId != null) {
              const response = await http.get(`/fetchNewMessages/${chatSessionId}/member`);
              if (response.data.success) {
                const newMessages = response.data.data.map(item => ({
                    text: item.message,
                    type: item.is_affiliate === '1' ? "bot" : item.is_affiliate === '2' ? "outofoffice" :  "user",
                }));
                response.data.data.map(elem => {
                  if(elem.is_affiliate === '2' ){
                    setOutOfOffc(true);
                  }else{
                    setOutOfOffc(false);
                  }
                })
                        
                  if (newMessages.length > 0) {
                    localStorage.setItem('lastMessageTime', Date.now());
                    var sessionMessagesObj = localStorage.getItem("sessionMessages"); 
                    var sessionMessage1 = JSON.parse(sessionMessagesObj);
                    if (response.data.sessionObj && response.data.sessionObj.affiliate_name != null) {
                        const affiliateName = response.data.sessionObj.affiliate_name;
                        setAffName(affiliateName);
                        const joinMessageExists = sessionMessage1.some(
                          (msg) => msg.text === `${affiliateName} has joined the chat`
                        );
      
                        if (!joinMessageExists) {
                          const joinMessage = {
                            text: `${affiliateName} has joined the chat`,
                            type: "info", 
                          };
      
                          sessionMessage1.push(joinMessage);
                          localStorage.setItem("sessionMessages", JSON.stringify(sessionMessage1));
                          setMessages((prevMessages) => [...prevMessages, joinMessage]);
                        }
                    }
                    newMessages.forEach((message) => {
                      sessionMessage1.push(message);
                    });
    
                    localStorage.setItem("sessionMessages",JSON.stringify(sessionMessage1)); 
                    setMessages((prevMessages) => [...prevMessages.filter((msg) => msg.type !== "warning"), ...newMessages]);
                  }
                  if (response.data.sessionObj && response.data.sessionObj.is_expired == 1)
                  {
                      setIsSessionCleared(true);
                  }else if(response.data.sessionObj && response.data.sessionObj.is_expired == 0){
                      setIsSessionCleared(false);
                  }
              }
            }
           
          } catch (error) {
            console.error("Error fetching new messages:", error);
          }
          };
        
          const interval = setInterval(() => {
              fetchNewMessages();
          }, 10000);

          const inactivityCheck = setInterval(() => {
            if( !outOfOffc && !isSessionCleared){
              const lastMessageTime = localStorage.getItem("lastMessageTime");
              if (lastMessageTime) {
                const timeElapsed = Date.now() - lastMessageTime;
                if (timeElapsed > 2 * 60 * 1000 && !isWarningDisplayedRef.current) {
                    setIsWarningDisplayed(true);
                    isWarningDisplayedRef.current = true;
                  // Add inactivity warning to chat
                  const inactivityWarning = {
                    text: "You have been inactive for 2 minutes. Do you want to continue the chat?",
                    type: "warning",
                    actions: [
                      { label: "Yes", action: "continueSession" },
                      { label: "No", action: "clearSession" },
                    ],
                  };
                  setMessages((prevMessages) => [...prevMessages, inactivityWarning]);
                }
                if (timeElapsed > 5 * 60 * 1000) { 
                  localStorage.removeItem("lastMessageTime");
                  handleClearSession(1);
                }
              }
            }
          }, 10000);
            return () => {clearInterval(interval); clearInterval(inactivityCheck);}; 
        }
        }, [chatEmail]);  

    const Upgradeto3B = () => {
        setStartJourneyOpen(false);
        handleFreezStart();
        var BlackboxObj1 = null;
        if (window.IGLOO && typeof window.IGLOO.getBlackbox === 'function') {
            BlackboxObj1 = window.IGLOO.getBlackbox();
            setBlackboxObj(BlackboxObj1);
            console.log('Blackbox generated:', BlackboxObj1);
        } else {
            console.error('IGLOO or getBlackbox function is not available.');
        }
    
        localStorage.setItem('aid', score ? score.aid : 'seo');
        localStorage.setItem('pid', score ? score.pid : '00017');
        http.post("/registerEnrollment", {
            ugradeToCD: true,
            userId: userId,
            blackboxCode: BlackboxObj1 ? BlackboxObj1.blackbox : "",
            step: 1
        })
            .then((res) => {
            handleFreezStop();
            if (res.data.success === true) {
                localStorage.setItem("trackingToken", res.data.data.trackingToken);
                localStorage.setItem('customerToken', res.data.data.customerToken);
                localStorage.setItem('Step', res.data.data.nextstep);
                localStorage.setItem('idQuestions', JSON.stringify(res.data.data.idQuestions));
                localStorage.setItem("UpgradeTo3B", true);
                localStorage.setItem('Main_firstName', score ? score.userObj.first_name : '');
                localStorage.setItem('Main_lastName', score ? score.userObj.last_name : '');
                localStorage.setItem('Main_email', score ? score.email : '');
                window.location.href = config.BASE_URL + `enroll?pid=` + localStorage.getItem('pid') + `&aid=` + localStorage.getItem('aid') + `&sid=&tid=&adid=&from=crsleads`;
            } else {
                handleFreezStop();
                const errorObj = res.data.data.errorByCD;
                swal({
                title: "Failed",
                text: errorObj[0] && errorObj[0].message ? errorObj[0].message : res.data.data.errorByCD,
                icon: "error",
                button: "Ok",
                })
            }
            })
        }
        const sendEmailChatSchema = Yup.object().shape({
            subject: Yup.string()
              .min(2, 'Too Short!')
              .required('Please enter subject'),
            description : Yup.string()
              .min(2, 'Too Short!')
              .required('Please enter Description'),
            email: Yup.string()
              .email('Please enter a valid email address') 
              .required('Please enter your email address'),
          });
        
    return (
        <>
            <Box>
                <EnrollHeader aid={aid} logoName={'creditSnapShot'} onValueChange={getCreditSnapshotDetails}/>
            </Box>
            <Box mt={3} mb={3} 
            // sx={{ paddingLeft: "5%", paddingRight: "5%" }}
            >
                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
                    {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Stepper activeStep={1} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Grid> */}
                    {/* <Grid item lg={8} md={6} sm={12} xs={12} sx={{ display: "flex", justifyContent: "center" }}> */}
                            {/* <PhoneInTalkOutlinedIcon />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Typography>
                                Call a Credit Specialist Now at {formatPhoneNumber(phonenumber)} We can help!
                            </Typography>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                          

                    {/* </Grid> */}
                </Grid>

                
                <Card sx={{boxShadow:"none"}}>
                    <CardContent >
                        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3}>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Card className="backImgMain" sx={{boxShadow:"none",display:"flex",justifyContent:"center",borderRight:isMobile?"none":"1px solid black",borderRadius:"0%"}}>
                                <Grid container item lg={12} md={12} sm={12} xs={12} >
                                    <p style={{ textAlign: "center" }}>
                                        <span className="accountFont1" style={{ fontWeight: 600, fontSize: "2rem" }}>Smart,</span> <span className="font4" style={{ fontWeight: 700, fontSize: "1.1rem" }}>It’s Always A Good Idea To Stay On Top Of Your Credit.
                                            We Provide Score Consultations And A Game Plan Every Day.</span>
                                    </p>
                                </Grid>
                                {/* <Card className="backImgMain" sx={{boxShadow:"none",display:"flex",justifyContent:"center"}}> */}
                                    <Grid container item lg={12} md={12} sm={12} xs={12} className="centering" >
                                        <Typography sx={{fontSize:"20px"}} className="centering">
                                            Get Your FREE Online Credit Consultation
                                            Now In 3 Easy Steps.
                                        </Typography>
                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                                        {
                                             creditSnapshotObj && creditSnapshotObj.step_1_link ?
                                            <>
                                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                                    <Grid container item lg={12} md={12} sm={12} xs={12} className="centering">
                                                        <img alt="#" src={require("../../../assets/images/Credit-Report.png")} style={{width:"150px",height:"100%"}}/>
                                                    </Grid>
                                                    <Grid container item lg={12} md={12} sm={12} xs={12} className="centering">
                                                        <Typography sx={{textAlign:"center"}}><b>Free Credit Report Summary</b></Typography>
                                                    </Grid>
                                                </Grid>
                                            </>
                                         :
                                         <>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} className="centering" >
                                                <Grid container item lg={12} md={12} sm={12} xs={12} className="centering">
                                                    <img alt="#" src={require("../../../assets/images/Credit-Report.png")} style={{width:"150px",height:"100%"}}/>
                                                </Grid>
                                                <Grid container item lg={12} md={12} sm={12} xs={12} className="centering">
                                                    <Typography   sx={{textAlign:"center"}}><b>Free Credit Report Summary</b></Typography>
                                                </Grid>
                                            </Grid>
                                         </>
                                        }
                                        
                                        {
                                            creditSnapshotObj && creditSnapshotObj.step_1_link ?
                                            <>
                                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                                        <Grid container item lg={12} md={12} sm={12} xs={12} className="centering">
                                                        <a href="#" onClick={handleIFrame}><img alt="#" src={require("../../../assets/images/play.png")} style={{width:"100px",height:"100%"}}/></a>
                                                    </Grid>
                                                    <Grid container item lg={12} md={12} sm={12} xs={12} className="centering">
                                                        <Typography sx={{textAlign:"center"}}><b>Learn More About Us</b></Typography>
                                                    </Grid>
                                                    {/* {openIframe && (
                                                        <div style={{ marginTop: "20px" }}>
                                                            <iframe
                                                                width="560"
                                                                height="315"
                                                                src={creditSnapshotObj.step_1_link}
                                                                title="Video Player"
                                                                // frameBorder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                allowFullScreen
                                                            ></iframe>
                                                        </div>
                                                        )} */}
                                                </Grid>
                                            </>
                                            : ''
                                        }
                                    </Grid>
                                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} className="centering">
                                                <img alt="#" src={require("../../../assets/images/credit-card.png")} style={{width:"150px",height:"100%"}} />
                                            </Grid>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} className="centering">
                                                <Typography sx={{textAlign:"center"}}><b>No Credit Card Required</b></Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Grid container item lg={12} md={12} sm={12} xs={12} className="centering">
                                                <img alt="#" src={require("../../../assets/images/meter.png")} style={{width:"150px",height:"100%"}}/>
                                            </Grid>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} className="centering">
                                                <Typography sx={{textAlign:"center"}}><b>Free Credit Score</b></Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>
                                </Grid>
                                
                            </Grid>
                            {/* <Grid item lg={1} md={1} sm={12} xs={12}>
                                <Divider orientation="vertical" flexItem style={{
                                height: '100%',  // Ensures the divider stretches to the full height of its container
                                }}/>
                            </Grid> */}
                            <Grid item lg={6} md={6} sm={12} xs={12}>

                                <Grid container item lg={12} md={12} sm={12} xs={12} alignItems="center" 
                                        justifyContent="center" sx={{px:isMobile?0:10}}>
                                             <Stack direction="row" justifyContent="center" sx={{px:isMobile?0:10}}>
                                    <Grid item lg={3} md={3} sm={3} xs={3} sx={{ textAlign: 'center',paddingLeft:isMobile?0:"150px" }}>
                                        <Button sx={{backgroundColor:"#C7E0FF",color:"black",minWidth:isMobile?60:100,borderRadius:"3px",fontSize:isMobile?"15px":"20px",
                                            '&:hover': {
                                            backgroundColor: "#C7E0FF",
                                            color:"black"
                                            },
                                        }}>Step 1</Button>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6}
                                        sx={{
                                            display: 'flex', 
                                            alignItems: 'center', 
                                            }}>
                                        <Divider 
                                        sx={{
                                            width: '100%', 
                                            borderStyle: 'dotted', // Makes the line dotted
                                            borderColor: 'black',  // Optional: Customize the color
                                            borderWidth: '1px',    // Optional: Adjust the thickness
                                            }} />
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={3} xs={3} sx={{ textAlign: 'center' ,paddingLeft:0, paddingRight:isMobile?0:"150px" }}>
                                    <Button sx={{backgroundColor:"#D2D3D5",color:"black",minWidth:isMobile?60:100,borderRadius:"3px",fontSize:isMobile?"15px":"20px",
                                         '&:hover': {
                                            backgroundColor: "#D2D3D5",
                                            color:"black"
                                            },
                                    }}>Step 2</Button>
                                    </Grid>
                                    </Stack>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <p className="font4" style={{ fontWeight: 600, fontSize: "1.1rem", textAlign: "center" }}>
                                        First We Need Some Details To Help You Retrieve Your Credit Information.
                                    </p>
                                </Grid>
                                <Formik
                                    initialValues={{ firstName: '', lastName: '', email: '', password: '' }}
                                    validationSchema={validationSchema}
                                    onSubmit={(values, { setSubmitting }) => {
                                        // Handle form submission logic here
                                        if(emailButton === true || checkUsaTldStatus === true) {
                                            return false;
                                        }
                                        setSubmitting(false);
                                        SnapShotErollStep1(values);
                                    }}
                                >
                                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                                        <Form>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <p style={{ textAlign: "center" }}>
                                                    Your Information Is Transmitted Securely And <span style={{ fontWeight: 700 }}>Checking Your Own Credit Won’t Hurt Your Score.</span>
                                                </p>
                                                <p style={{ fontWeight: 700, display: "flex", justifyContent: "center" }}>
                                                    No Credit Card Required. Yes We Said It’s FREE.
                                                </p>
                                                <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3}>
                                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                                        
                                                        <FormControl variant="outlined" fullWidth
                                                            margin="dense" size="small">
                                                            <InputLabel>First Name*</InputLabel>
                                                            <OutlinedInput
                                                                id="firstName"
                                                                name="firstName"
                                                                label="First Name*"
                                                                variant="outlined"
                                                                size="small"
                                                                fullWidth
                                                                margin="dense"
                                                                autoComplete="firstName"
                                                                error={touched.firstName && !!errors.firstName}
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            edge="end"
                                                                        >
                                                                            {touched.firstName && !errors.firstName ? (
                                                                                <CheckIcon style={{ color: 'green' }} />
                                                                            ) : (
                                                                                touched.firstName && errors.firstName ? (
                                                                                    <CloseIcon style={{ color: 'red' }} />
                                                                                ) : null
                                                                            )}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }
                                                                value={values.firstName}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            />
                                                            <FormHelperText style={{ color: 'red' }}>
                                                                {touched.firstName && errors.firstName}
                                                            </FormHelperText>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                                        
                                                        <FormControl variant="outlined" fullWidth
                                                            margin="dense" size="small">
                                                            <InputLabel>Last Name*</InputLabel>
                                                            <OutlinedInput
                                                                id="lastName"
                                                                name="lastName"
                                                                label="Last Name*"
                                                                autoComplete="lastName"
                                                                error={touched.lastName && !!errors.lastName}
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            edge="end"
                                                                        >
                                                                            {touched.lastName && !errors.lastName ? (
                                                                                <CheckIcon style={{ color: 'green' }} />
                                                                            ) : (
                                                                                touched.lastName && errors.lastName ? (
                                                                                    <CloseIcon style={{ color: 'red' }} />
                                                                                ) : null
                                                                            )}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }
                                                                value={values.lastName}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            />
                                                            <FormHelperText style={{ color: 'red' }}>
                                                                {touched.lastName && errors.lastName}
                                                            </FormHelperText>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3}>
                                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                                       
                                                        <FormControl variant="outlined" fullWidth
                                                            margin="dense" size="small">
                                                            <InputLabel>Email*</InputLabel>
                                                            <OutlinedInput
                                                                id="email"
                                                                name="email"
                                                                label="Email*"
                                                                autoComplete="emails"
                                                                error={touched.email && !!errors.email}
                                                                helperText={touched.email && errors.email}
                                                                defaultValue={values.email}
                                                                onChange={(e)=>{
                                                                    checkUsaTLD(e.target.value);   
                                                                    handleChange('email');
                                                                    setFieldValue('email', e.target.value, true)
                                                                }}
                                                                onBlur={handleBlur}
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            edge="end"
                                                                        >
                                                                            {(touched.email && !errors.email) &&  checkUsaTldStatus == false ? (
                                                                                <CheckIcon style={{ color: 'green' }} />
                                                                            ) : (
                                                                               ( touched.email && errors.email) || checkUsaTldStatus == true ? (
                                                                                    <CloseIcon style={{ color: 'red' }} />
                                                                                ) : null
                                                                            )}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }
                                                            />
                                                            <FormHelperText style={{ color: 'red'}}>
                                                                {touched.email && errors.email ?errors.email : checkUsaTldStatus ? "Invalid email format." : '' }
                                                            </FormHelperText>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                                       
                                                        <FormControl variant="outlined" fullWidth
                                                            margin="dense" size="small">
                                                            <InputLabel>Password*</InputLabel>
                                                            <OutlinedInput
                                                                id="password"
                                                                name="password"
                                                                label="Password*"
                                                                autoComplete="password"
                                                                type={showPassword ? 'text' : 'password'}
                                                                error={touched.password && !!errors.password}
                                                                helperText={touched.password && errors.password}
                                                                defaultValue={values.password}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            edge="end"
                                                                        >
                                                                            {touched.password && !errors.password ? (
                                                                                <CheckIcon style={{ color: 'green' }} />
                                                                            ) : (
                                                                                touched.password && errors.password ? (
                                                                                    <CloseIcon style={{ color: 'red' }} />
                                                                                ) : null
                                                                            )}
                                                                        </IconButton>
                                                                        <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            onClick={handleClickShowPassword}
                                                                            onMouseDown={handleMouseDownPassword}
                                                                            edge="end"
                                                                        >
                                                                            {showPassword ? (
                                                                            <VisibilityOffIcon />
                                                                            ) : (
                                                                            <VisibilityIcon />
                                                                            )}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }
                                                            />
                                                            <FormHelperText style={{ color: 'red' }}>
                                                                {touched.password && errors.password}
                                                            </FormHelperText>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                {errormsg ? <>
                                                    <Typography sx={{ color: "red" }} ><b>
                                                        Error : - {errormsg}  {enrolledStatus == 1 ? <><Button color="success" variant="contained" onClick={loginLink} >Login</Button></> : null}</b>
                                                    </Typography>
                                                </>
                                                    : null}
                                                <Grid container item lg={12} md={12} sm={12} xs={12}>
                                                    <Grid item lg={4} md={4} sm={12} xs={12} sx={{marginRight: '-7%'}}>
                                                    <Typography>By Clicking 'NEXT' I Agree To: </Typography>
                                                    </Grid>
                                                    <Grid item lg={8} md={8} sm={12} xs={12}>
                                                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                                                        <Typography> (1) Be Contacted By <b>{companyName ? companyName : ''}</b> Email, Or By SMS Text</Typography>
                                                        </Grid>
                                                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                                                            <Typography>
                                                            (2) The
                                                        {/* &nbsp;<a href="#" onClick={toggleDrawer3(true)} style={{ cursor: "pointer" }}>Privacy Policy</a>
                                                        <SwipeableDrawer
                                                            anchor={'bottom'}
                                                            open={open3}
                                                            onClose={toggleDrawer3(false)}
                                                            onOpen={toggleDrawer3(true)}
                                                        >
                                                            <PrivacyPolicy closeVal={toggleDrawer3(false)} />
                                                        </SwipeableDrawer>
                                                        &nbsp;and&nbsp; */}
                                                        <a href="#" onClick={toggleDrawer4(true)} style={{ cursor: "pointer" }}> Terms & Conditions</a>
                                                        <SwipeableDrawer
                                                            anchor={'bottom'}
                                                            open={open4}
                                                            onClose={toggleDrawer4(false)}
                                                            onOpen={toggleDrawer4(true)}
                                                        >
                                                            <TermsAndConditions closeVal={toggleDrawer4(false)} />
                                                        </SwipeableDrawer>.
                                                    </Typography>
                                                        </Grid>
                                                    </Grid>
                                                   
                                                    
                                                </Grid>
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex", justifyContent: "right" }} mt={2}>
                                                <Button variant="contained" type="submit" disabled={isSubmitting} sx={{  minWidth: 200, }}>NEXT</Button>
                                            </Grid>
                                        </Form>
                                    )}

                                </Formik>
                                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{mt:isMobile?4:0}}>
                                    {
                                      chatEnable  && !isChatOpen ?
                                        <Grid item lg={4} md={4} sm={12} xs={12} mt={1} sx={{ display:"flex",justifyContent:"center",marginTop:isMobile?2:0 }}>
                                        <Button varient="outlined"
                                            onClick={openChatPopup}
                                        startIcon={<img src={require("../../../assets/images/chatforbutton.png")} alt="icon" style={{ width: 30, height: 30 }} />}
                                        sx={{
                                            textTransform: "none !important",
                                            border:"1px solid #2E67BC",
                                            minWidth:'80%' ,
                                            maxWidth:'100%',
                                            color:"black",
                                            alignItems:"center",
                                            fontWeight:"bold"// Prevents uppercase transformation
                                        }}>Chat With Us Now</Button>
                                        </Grid>
                                        : ''
                                    }
                                    <Grid item lg={4} md={4} sm={12} xs={12}  mt={1} sx={{ display:"flex",justifyContent:"center",marginTop:isMobile?2:0 }}>
                                        <Button  startIcon={<img src={require("../../../assets/images/Emailforbutton.png")} alt="icon" style={{ width: 30, height: 30 }} />}
                                        onClick={openEmailChatPopup}
                                        sx={{
                                        textTransform: "none",
                                        border:"1px solid #2E67BC",
                                        minWidth:'80%' ,
                                        maxWidth:'100%',
                                        color:"black",
                                        alignItems:"center",
                                        fontWeight:"bold" // Prevents uppercase transformation
                                        }}>Email Us Now</Button>
                                </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
            <Box>
                <EnrollFooter />
            </Box>

            {/* Iframe Dialog Start */}
            <Dialog open={openIframe}
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                        handleCloseDialog();
                    }
                }}
                fullWidth={fullWidth}
                maxWidth={maxWidth}>
                <DialogTitle sx={{ bgcolor: "#F6FAFF" }}>
                    <Grid container item xs sx={{ justifyContent: "space-between" }}>
                        <Grid item xs>
                            <Typography level="h4" color="primary">Learn More About Us</Typography>
                        </Grid>
                        <Grid item xs sx={{ textAlign: "right" }}>
                            <IconButton
                                aria-label="close"
                                onClick={handleCloseDialog}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} sx={{ justifyContent : "center" }}>
                        <iframe
                            width="560"
                            height="315"
                            src={creditSnapshotObj ? creditSnapshotObj.step_1_link : ''}
                            title="Video Player"
                            // frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </Grid>
                </DialogContent>
                {/* </DialogActions> */}
            </Dialog>
            {/* Iframe Dialog End */}
            {/* loader code start */}
            {/* <ChatbotComponent ref={chatbotRef}/> */}
            {/* {
                creditSnapshotObj.enable_chat_support ? 
                <>
                <div id="chatbotDiv"> 
                <DefaultQuestions sendMessage={sendMessage} />
                <ChatbotComponent ref={chatbotRef}/>
                </div>
                </>
                : ''
            } */}
            <Grid  id="chatbotDiv" sx={{ width: "100%",display:"none"}}>
            
            {isMinimized ? null : (
            <Grid
            container
            justifyContent="flex-end"
            alignItems="flex-end"
            sx={{ position: "fixed", width: "100%", zIndex: 1000, bottom: "0%", padding: "1%", }}
            >
            <Grid item xs={12} sm={8} md={6} lg={4} sx={{display:"flex",justifyContent:"flex-end"}}>
                <Card sx={{
                    boxShadow:
                    "0 0px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 12px 0 rgba(0, 0, 0, 0.19)",
                    borderRadius: "0px"
                }}>
                    <CardOverflow sx={{ backgroundColor: "#435AC2", borderRadius: "0px", padding: "3%", cursor: isMinimized ? "pointer" : "default",  }}
                    onClick={() => {
                    if (isMinimized) {
                        setIsMinimized(false); // Expand the chatbot when clicking on CardOverflow
                        setTimeout(() => {
                        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
                        }, 0);
                    }
                    }}>
                    <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ alignItems: "center"}}>
                    <Grid item lg={1} md={1} sm={1} xs={1} sx={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                        <img src={require("../../../assets/images/chatforbutton.png")} 
                            alt="Chat Icon" 
                            style={{ width: "35px", height: "35px" }} />
                    </Grid>
                    <Grid item lg={9} md={9} sm={9} xs={9}>
                        <Typography level="h1" sx={{ color: "white !important", fontSize: 'xl', mb: 0.5 }}><b>Chat With Us!</b></Typography>
                    </Grid>
                    {/* <Grid item lg={1} md={1} sm={1} xs={1} sx={{ textAlign: "right" }}>
                        <IconButton onClick={() => setIsMinimized(!isMinimized)}>
                        <RemoveIcon sx={{ color: "white", fontSize: 'xl' }} />
                        </IconButton>
                    </Grid> */}
                    <Grid item lg={2} md={2} sm={2} xs={2} sx={{ textAlign: "right" }}>
                        <IconButton onClick={() => setIsMinimized(!isMinimized)}>
                        <CloseIcon sx={{ color: "white", fontSize: 'xl' }}/>
                        </IconButton>
                    </Grid>

                    </Grid>

                </CardOverflow>
                {!isMinimized && (
                    <CardContent>
                    {/* Message Area */}
                    {chatEmail ? (
                            <>
                    <Box
                        sx={{
                        maxHeight: 300,
                        minHeight: 300,
                        overflowY: "auto",
                        // p: 1,
                        // border: "1px solid #ddd",
                        borderRadius: 2,
                        // mt: 2,
                        // backgroundColor: "#f9f9f9",
                        }}
                    >
                        <List>
                        {messages.map((message, index) => (
                            <ListItem
                            key={index}
                            sx={{
                                justifyContent:
                                message.type === "user"  ? "flex-end"  : message.type === "info" ? "center" : message.type === "warning" ? "center" :"flex-start",
                            }}
                            >
                            <Paper
                                sx={{
                                p: '5px 15px',
                                backgroundColor:
                                message.type === "user" ? "#1976d2" : message.type === "info" ? "#e0f7fa" : message.type === "warning" ? "#fff8e1" :"#eee",
                                color: message.type === "user" ? "#fff" : message.type === "info" ? "#00796b" : message.type === "warning" ? "#ff9800" : "#000",
                                borderRadius: 2,
                                }}
                            >
                                <ListItemText primary={message.text} />
                                {message.type === "warning" && (
                                <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
                                    {message.actions.map((action, idx) => (
                                    <Button
                                        key={idx}
                                        variant="contained"
                                        size="small"
                                        onClick={() => {
                                        if (action.action === "continueSession") handleSessionContinue();
                                        if (action.action === "clearSession") handleSessionClear();
                                        }}
                                        disabled={isSessionCleared}
                                        sx={{
                                        backgroundColor: action.action === "continueSession" ? "#4caf50" : "#f44336",
                                        color: "#fff",
                                        }}
                                    >
                                        {action.label}
                                    </Button>
                                    ))}
                                </Box>
                                )}
                            </Paper>
                            </ListItem>
                        ))}
                        {isSessionCleared && (
                        <ListItem sx={{ justifyContent: "center" }}>
                            <Paper
                            sx={{
                                p: 1,
                                backgroundColor: "#e0f7fa",
                                color: "#00796b",
                                borderRadius: 2,
                            }}
                            >
                            <ListItemText
                                primary={`The session has been ended`}
                                primaryTypographyProps={{
                                align: "center",
                                fontSize: "0.9rem",
                                fontWeight: "bold",
                                }}
                            />
                            </Paper>
                        </ListItem>
                        )}
                        {
                            outOfOffc && (
                            <ListItem sx={{ justifyContent: "center" }}>
                                <Button  startIcon={<img src={require("../../../assets/images/Emailforbutton.png")} alt="icon" style={{ width: 30, height: 30 }} />}
                                onClick={openEmailChatPopup}
                                sx={{
                                textTransform: "none",
                                border:"1px solid #2E67BC",
                                minWidth:'80%' ,
                                maxWidth:'100%',
                                color:"black",
                                alignItems:"center",
                                fontWeight:"bold" // Prevents uppercase transformation
                                }}>Email Us Now</Button>
                            </ListItem>
                            )
                        }
                        <div ref={messagesEndRef}></div>
                        </List>
                    </Box>
                    {/* Input Area */}
                    <Box
                        sx={{
                        display: "flex",
                        alignItems: "center",
                        mt: 2,
                        }}
                    >
                        <TextField
                        fullWidth
                        placeholder="Type your message..."
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === "Enter" && input.trim()) handleSendMessage();
                        }}
                        sx={{ mr: 1 }}
                        disabled={isSessionCleared || outOfOffc }
                        />
                        <IconButton
                        sx={{ border: "1px solid #3779eb", borderRadius: "6px", backgroundColor: isSessionCleared || outOfOffc || !input.trim() ? "#ccc !important" : "#435AC2 !important", color: "white", padding: "2.3%" }}
                        onClick={handleSendMessage}
                        disabled={isSessionCleared || outOfOffc || !input.trim() || isSetMsg} 
                        >
                        <SendIcon sx={{ fontSize: "1.7rem" }} />
                        </IconButton>
                    </Box>
                    </> ) : (
                            <>
                            <h3>Please provide your email below to start chatting with us.</h3>
                            <input
                                type="email"
                                placeholder="Enter your email"
                                value={inputEmail}
                                onChange={(e) => setInputEmail(e.target.value)}
                                style={{
                                padding: "10px",
                                fontSize: "16px",
                                width: "300px",
                                marginBottom: "20px",
                                borderRadius: "5px",
                                border: "1px solid #ccc",
                                }}
                            />
                            <button
                                onClick={() => {
                                if (inputEmail.trim() !== "") {
                                    setChatEmail(inputEmail); // Save email and show chat UI
                                } else {
                                    alert("Please enter a valid email!");
                                }
                                }}
                                style={{
                                marginLeft: "10px",
                                padding: "10px",
                                backgroundColor: "#1976d2",
                                color: "#fff",
                                border: "none",
                                borderRadius: "5px",
                                cursor: "pointer",
                                }}
                            >
                                Submit
                            </button>
                            </>
                        )}
                    </CardContent>
                )}
                </Card>
        
            </Grid>
            </Grid>
            )}
        </Grid>
        {isChatOpen && (
        <Box
            sx={{
                position: "fixed",
                bottom: "2%",
                right: "2%",
                cursor: "pointer",
            }}
            // onClick={() => setIsMinimized(!isMinimized)}
            onClick={openChatPopup}
            >
            <img
                src={require("../../../assets/images/chatforbutton.png")}
                alt="Chat Icon"
                style={{
                width: "70px",
                height: "70px",
                borderRadius: "50%",
                boxShadow:
                    "0px 4px 8px rgba(0, 0, 0, 0.2), 0px 8px 16px rgba(0, 0, 0, 0.19)",
                }}
            />
            </Box>
            )}
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
        >
            <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={freez}
            >
                <Freez />
            </Backdrop>
            </div>
            <Grid>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            </Grid>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={3}>
                <Typography level="h2"><span style={{ color: "red" }}><i>WAIT!</i></span> READY TO SEE IT ALL? </Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={3}>
                <Typography level="h5" sx={{ color: "#76C044" }}>You've taken the first step to staying on top of your credit - DON'T STOP HERE!</Typography>
                </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={3}>
                <Typography level="h4" sx={{ color: "#2D6DB4", fontWeight: 900 }}>SCORES CAN VERY BY BUREAU. CHECK YOUR SCORES FROM <u>ALL 3 BUREAUS NOW!</u></Typography>
                </Grid>
            </DialogContentText>
            </DialogContent>
        </Dialog>

        {/* Start Credit Repair Journey */}
        <Dialog
            open={msgChatopen}
            onClose={(_, reason) => {
            if (reason !== "backdropClick") {
                // setDeleteOpen(false)
            }
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            {/* Delete Pid */}
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }}>
                <Grid item lg={10} md={10} sm={10} xs={10}>
                </Grid>
                <Grid item lg={2} md={2} sm={2} xs={2} sx={{ textAlign: "right" }}>
                <CloseIcon onClick={closeStartJourneyOpen} />
                </Grid>
            </Grid>
            </DialogTitle>
            <DialogContent>
            {/* <DialogContentText id="alert-dialog-description">
                <span style={{fontWeight : '700', color: '#000000'}}>Great, We need some information to get started, But first, Let us get your 3B report.</span>
            </DialogContentText> */}
            </DialogContent>
            <DialogActions >
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                label="member Msg"
                type="text"
                size="small"
                fullWidth
                name='memberMsg'
                autoComplete='memberMsg'
                id='memberMsg'
                onChange={(e) => { setMemberMsg(e.target.value); }}
                value={memberMsg}
                // onBlur={handleBlur('FirstName')}
                />
            </Grid>
            <Grid item>
                <Button variant="contained" sx={{ minWidth: 200 }} color="success"
                // onClick={handleSubmit}
                onclick={sendMessage}
                >
                Send1
                </Button>
            </Grid>
            </DialogActions>
        </Dialog>

        <Dialog
            open={startJourneyOpen}
            onClose={(_, reason) => {
            if (reason !== "backdropClick") {
                // setDeleteOpen(false)
            }
            }}
            maxWidth="md"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            {/* Delete Pid */}
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "space-between" }}>
                <Grid item lg={10} md={10} sm={10} xs={10}>
                </Grid>
                <Grid item lg={2} md={2} sm={2} xs={2} sx={{ textAlign: "right" }}>
                <CloseIcon onClick={closeStartJourneyOpen} />
                </Grid>
            </Grid>
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <Grid container item lg={12} md={12} sm={12} xs={12} className="centering"><img alt="#" src={require("../../../assets/images/bureau-logos.png")}/> </Grid>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                <Typography sx={{ fontWeight: '600', color: '#000000',textAlign:"center",fontSize:"30px" }}>Great, We Need Some Information To Get Started, But first, Let's Get Your 3B Report.</Typography>
                </Grid>
            </DialogContentText>
            </DialogContent>
            <DialogActions >
            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center" }}>
                <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center" }}>
                <Button variant="contained"
                    size="large"
                    // color="warning"
                    sx={{
                    backgroundColor: "#2E67BC", borderColor: "#FF9400", minWidth: 150, minHeight: 40, fontSize: "20px", fontWeight: "bold",
                    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                    }}
                    onClick={() => { Upgradeto3B() }}
                >
                    GET ALL 3 REPORTS AND SCORES
                </Button>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} mt={2} sx={{ textAlign: "center" }}>
                <a target="_blank" href={creditSnapshotObj && creditSnapshotObj.credit_snapshot_link ? creditSnapshotObj.credit_snapshot_link : "#"}> I’ll Do That Later, Continue To Auto Signup Now</a>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display:"flex", justifyContent:"center",alignItems: "center",marginTop:"2%" }} >
                <Stack direction="row">
                    {
                        creditSnapshotObj && creditSnapshotObj.enable_chat_support ?
                        <>
                        <Card varient="outlined" className="centering" sx={{boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",flex: "1", minWidth:150, maxWidth: "300px",}}>
                        <Grid container item lg={12} md={12} sm={12} xs={12} className="centering">
                            <a href="#" onClick={openChatPopup}><img alt="#" src={require("../../../assets/images/Chat-bot-Icon.png")} style={{ marginLeft: "auto", marginRight: "auto", height: "auto" ,width:"56%"}} /></a>
                        </Grid>
                        
                        <Grid container item lg={12} md={12} sm={12} xs={12} className="centering">
                            <Typography level="h5" sx={{textAlign:"center"}}>Chat With Us</Typography>
                        </Grid>
                        </Card>
                        </> : ''
                    }
                    
                    &nbsp; &nbsp;
                    <Card varient="outlined" className="centering" sx={{boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",flex: "1",minWidth:150,maxWidth: "300px",}}>
                    <Grid container item lg={12} md={12} sm={12} xs={12} className="centering">
                    <a href="#" onClick={openEmailChatPopup}><img alt="#" src={require("../../../assets/images/email-multiple-outline.png")} style={{ marginLeft: "auto", marginRight: "auto", height: "auto",width:"100%" }} /></a>
                    </Grid>
                    <Grid container item lg={12} md={12} sm={12} xs={12} className="centering">
                    <Typography level="h5" sx={{textAlign:"center"}}>Email Us</Typography>
                    </Grid>
                    </Card>
                    </Stack>
                </Grid>
            </Grid>
            </DialogActions>
        </Dialog>


        {/* Email Chat DialogBox */}
        <Formik
            initialValues={{
            subject: '',
            description : '',
            email : ''
            }}
            validationSchema={sendEmailChatSchema}
            onSubmit={async (values, { resetForm }) => {
            http.post("/sendChatEmail", {
                email : values.email,
                subject : values.subject,
                description : values.description,
                companyId : companyObj.company_master_id
            })
            .then((res) => {
                if (res.data.success === true) {
                swal({
                    title: "Success",
                    text: res.data.message,
                    icon: "success",
                    button: "Ok",
                })
                }else{
                // alert("something went wrong");
                }
            })
            closeEmailChatPopup();
            resetForm();
            }}
        >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <Dialog
                open={emailChatopen}
                onClose={(_, reason) => {
                if (reason !== "backdropClick") {
                    // setDeleteOpen(false)
                }
                }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {/* Delete Pid */}
                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{justifyContent:"space-between"}}>
                <Grid item lg={10} md={10} sm={10} xs={10}>
                    <Typography level="h5" sx={{textAlign:"center"}}>Send Us The Email</Typography>
                </Grid>
                <Grid item lg={2} md={2} sm={2} xs={2} sx={{textAlign:"right"}}>
                    <CloseIcon  onClick={closeEmailChatPopup}/>
                </Grid>
                </Grid>
            </DialogTitle>
            <DialogActions >
            <Grid container item lg={12} md={12} sm={12} xs={12} >
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField
                        id="outlined-start-adornment"
                        type="text"
                        label="From Email Address"
                        fullWidth
                        variant="outlined"
                        size="small"
                        margin="dense"
                        autoComplete="email"
                        value={values.email}
                        onChange={handleChange('email')}
                        onBlur={handleBlur('email')} 
                        InputLabelProps={{
                            shrink: Boolean(values.email), 
                        }}
                        />
                        {touched.email && errors.email ? <div className="error">{errors.email}</div> : null}
                    </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField
                    id="outlined-start-adornment"
                    type="text"
                    label="Subject"
                    fullWidth
                    variant="outlined"
                    size="small"
                    margin='dense'
                    autoComplete='subject'
                    value={values.subject}
                    onChange={handleChange('subject')}
                    onBlur={handleBlur('subject')}
                    InputLabelProps={{
                        shrink: Boolean(values.subject)
                    }}
                    /> 
                    {touched.subject && errors.subject ? <div className='error'>{errors.subject}</div> : null}
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <TextareaAutosize
                        placeholder="Message"
                        type="text"
                        size="small"
                        fullWidth
                        name='description'
                        autoComplete='description'
                        id='description'
                        onChange={handleChange('description')}
                        onBlur={handleBlur('description')}
                        value={values.description}
                        maxRows={7}
                        minRows={4}
                        
                        style={{
                        width: '100%', // Ensures full width
                        // padding: '8px', // Optional for better spacing
                        boxSizing: 'border-box', // Ensures padding doesn't affect width
                    }}
                    />
                    {touched.description && errors.description ? <div className='error'>{errors.description}</div> : null}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} mt={2} sx={{ justifyContent: "center", display: "flex"}}>
                <Button variant="contained" sx={{ minWidth: 200 }} color="success" 
                    onClick={handleSubmit}
                    >
                    Send
                </Button>
                </Grid>
            </Grid>
            </DialogActions>
            </Dialog>
        )}
        </Formik>
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                >
                    <Freez />
                </Backdrop>
            </div>
            {/* loader code end */}
        </>
    )
}
export default EnrollStep1;