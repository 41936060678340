import { createChatBotMessage } from "react-chatbot-kit";
import axios from "axios";
import config from "../../AppConfig";

class ActionProvider {
  constructor(createChatBotMessage, setStateFunc) {
  // constructor(createChatBotMessage, setStateFunc, sessionId) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
    // this.sessionId = sessionId;
  }



  async handleApiResponse(userMessage) {
    // const botMessage = this.createChatBotMessage("Let me find that for you...");
    // this.setState((prev) => ({ ...prev, messages: [...prev.messages, botMessage] }));

    try {
      var sessionId = localStorage.getItem('chatSessionId');
      var chatbotEmail = localStorage.getItem("chatbot_email");
      // console.log("this.state.sessionId: "+this.state.sessionId);
      const response = await axios.post( 
        config.API_BASE_URL + "/saveMemberMessage", { message: userMessage, session_id: sessionId, email : chatbotEmail });
        // config.API_BASE_URL + "/saveMemberMessage", { message: userMessage });
      // const replyMessage = this.createChatBotMessage(response.data.reply);

      // this.setState((prev) => ({ ...prev, messages: [...prev.messages, replyMessage] }));
    } catch (error) {
      console.log("error:" +error);
      const errorMessage = this.createChatBotMessage("Oops! Something went wrong. Please try again.");
      this.setState((prev) => ({ ...prev, messages: [...prev.messages, errorMessage] }));
    }
  };

  handleBotMessage = (text) => {
    const botMessage = this.createChatBotMessage(text);
    this.updateChatState(botMessage);
    // this.setState((prev) => ({
    //   ...prev,
    //   messages: [...prev.messages, botMessage],
    // }));
  };


  updateChatState = (message) => {
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };

  checkRole = () => {
    const userRole = localStorage.getItem("role");
    const chatbotEmail = localStorage.getItem('chatbot_email');

    if (userRole === null && chatbotEmail == "") {
      const emailPrompt = this.createChatBotMessage("Please enter your email to continue.");
      this.setState((prevState) => ({
        ...prevState,
        messages: [...prevState.messages, emailPrompt],
      }));
    }
  };

  handleDefault = (message) => {
    const defaultMessage = this.createChatBotMessage(
      "I'm sorry, I didn't understand that. Please enter a valid email."
    );
    this.setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, defaultMessage],
    }));
  };

  handleReactQuestion = () => {
    const message = this.createChatBotMessage(
      "Score between 661 to 715 known as fair score."
    );
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };

  handleChatbotQuestion = () => {
    const message = this.createChatBotMessage(
      "Score between 716 to 747 known as fair score."
    );
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };

  handleUsageQuestion = () => {
    const message = this.createChatBotMessage(
      "Score between 748 to 850 known as fair score."
    );
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };

  handleUnknownQuestion = () => {
    const message = this.createChatBotMessage(
      "I'm sorry, I didn't understand that. Can you ask something else?"
    );
    this.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };

  async updateAffiliateName(userMessage) {
    // const botMessage = this.createChatBotMessage("Let me find that for you...");
    // this.setState((prev) => ({ ...prev, messages: [...prev.messages, botMessage] }));

    try {
      var sessionId = localStorage.getItem('chatSessionId');
      
      const response = await axios.post( 
        config.API_BASE_URL + "/chatbot/add-affiliate-name", { name: userMessage, sessionId: sessionId});
      } catch (error) {
        console.log("error:" +error);
        const errorMessage = this.createChatBotMessage("Oops! Something went wrong. Please try again.");
        this.setState((prev) => ({ ...prev, messages: [...prev.messages, errorMessage] }));
      }
  };

}

export default ActionProvider;
