import React, { Fragment, useEffect, useState } from 'react';
import DefaultLayout from '../../../Components/DefaultLayout';
import { useParams, useNavigate } from "react-router-dom";
import AuthUser from '../../../Components/Auth/AuthUser';
import { Typography, Autocomplete, Box, Grid, Table,TableContainer, useMediaQuery, TableBody, TableHead, TextField, TableRow, Button, 
    TableCell, Tooltip, Stack, Alert, IconButton,InputLabel,Select,OutlinedInput,MenuItem,FormControl } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import Switch from '@mui/material/Switch';
import { Card, CardContent, CardOverflow, Sheet } from '@mui/joy';
import swal from "sweetalert";
import Backdrop from '@mui/material/Backdrop';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Moment from 'moment';
import Freez from '../../../Components/Loader/Loader'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import formatPhoneNumber from '../../../Components/Formats/PhoneNumberValidation'
import LinkIcon from '@mui/icons-material/Link';
import config from '../../../AppConfig';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import BounceEmailDailog from '../../../Components/BounceEmail/BounceEmailDailog';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
//modal style start
let aid1;
let RequestFormInitialValues = {
    SoftwareUsed : '',
    othersofttype : ''
  };
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',

    p: 4,
};

//modal style end
function CompanyDetails1(props) {
    const dispatch = useDispatch();
    //Add aid modal variable and function start
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');

    const handleOpen = () => {
        var check = checkUserRole();
        if(check === true) {
            setOpen(true);
        }
    };
    
    //loader function and variable start
    const [freez, setFreez] = React.useState(false);

    const handleFreezStop = () => {
        setFreez(false);
    };
    const handleFreezStart = () => {
        setFreez(true);
    };
    //loader function and variable end
    //Add aid modal variable and function end
    const handleMaxWidthChange = (event) => {
        setMaxWidth(
            // @ts-expect-error autofill of arbitrary value is not handled.
            event.target.value,
        );
    };

    const handleFullWidthChange = (event) => {
        setFullWidth(event.target.checked);
    };
    //referal code modal fuunction and variable start
    // rb stands for Refered by
    const [rbopen, setrbOpen] = useState(false);
    const [rbOption, setrbOption] = useState([]);
    const [scrollX, setScrollX] = useState(0);
    const [openCommEditEmailPopup, setopenCommEditEmailPopup] = useState(false);
	const [email,setEmail] = useState('');
	const [companyName,setcompanyName] = useState('');
    const [companyId,setcompanyId] = useState('');
	const [communicationEmail,setcommunicationEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [taxFlag, setTaxFlag] = useState(false);
    const [softwares, setSoftwares] = useState([]);
    const [mtOpen,setMtOpen] =useState(false);
    const [checkOtherStatus, setCheckOtherStatus] = useState(false);
    
    let storedReferralValue = null;
    const isMobile = useMediaQuery('(max-width:600px)');
    const [epicRroOpen, setEpicRroOpen] = useState(false);
    const rbhandleOpen = async () => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
		if(loginUser.roles_id  > 2) {
			navigate('/login');
			return false;
		}
        handleFreezStart();
        const apiUrl = "/fetch-refered-by-list";
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            console.log(res);

            const additionalCompanyListOptions = [
                { referral_code: "mfsnhomepage", company_name: "MFSN Home Page" },
                { referral_code: 'mfsnloginpage', company_name: 'MFSN Login Page' },
            ];
            const allOptions = [...res, ...additionalCompanyListOptions];

            // Sort the options array by the company_name 
            allOptions.sort((a, b) => a.company_name.localeCompare(b.company_name));
            setrbOption(allOptions);

            if (rbOption.length > 0) {
                setrbOpen(true)
                handleFreezStop();
            }
        }
    };

    const openChangeMarketingType = async () => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
		if(loginUser.roles_id  >= 4) {
			navigate('/login');
			return false;
		}
        handleFreezStart();
        const apiUrl = "/getSoftwareInfo";
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            if(res.success === true) {
                setSoftwares(res.data);
            }
        }
        if(company_details.marketing_type) {
            if(company_details.marketing_type.includes("Other") || company_details.marketing_type.includes("others")) {
                RequestFormInitialValues.SoftwareUsed = "Other";
                var originalString = company_details.marketing_type;
                var startIndex = originalString.indexOf('Other');
                var endIndex = startIndex + 'Other'.length;
                var newString = originalString.slice(0, startIndex) + originalString.slice(endIndex);
                var firstIndex = newString.indexOf("(");
                var lastIndex = newString.lastIndexOf(")");
                if (firstIndex !== -1 && lastIndex !== -1) {
                    newString = newString.slice(0, firstIndex) + newString.slice(firstIndex + 1, lastIndex) + newString.slice(lastIndex + 1);
                } else {
                    newString = newString;
                }
                RequestFormInitialValues.othersofttype = newString;
    
            }else{
                RequestFormInitialValues.SoftwareUsed = company_details.marketing_type;
                RequestFormInitialValues.othersofttype = '';
            }
        }
        
        handleFreezStop();
        setMtOpen(true);
    };

    const mThandleClose = (resetForm) => {
        resetForm();
        setMtOpen(false);
    };

    const setSoftwareType = (softwareType) => {
        if (softwareType == 'Other') {
            document.getElementById("othersofttypes").style.display = "block";
            setCheckOtherStatus(true);
            // setDisable(true)
        } else {
            document.getElementById("othersofttypes").style.display = "none";
            setCheckOtherStatus(false);
            // setDisable(false)

        }
    }
    const handleothersoft = (event) => {
        if (event != '') {
            setCheckOtherStatus(false);
        } else {
            setCheckOtherStatus(true);
        }
    }

    const fetchReferredByList = async () => {
        handleFreezStart();
        const apiUrl = "/fetch-refered-by-list";
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            setrbOption(res);
            if (rbOption.length > 0) {
                setrbOpen(true)
                handleFreezStop();
            }
        }
    };

    const rbhandleClose = () => {
        setrbOpen(false);
    };

    const epicProHandleClose = () => {
        setEpicRroOpen(false);
    };

    const rbhandleSave = async () => {
        // Handle save logic here
        rbhandleClose();
        handleFreezStart();
        const apiUrl = '/update-refered-by';
        const args = {
            companyId: id,
            referredBy: storedReferralValue
        }
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        if (res) {
            rbhandleClose();
            fetchCompanyDetails();
            handleFreezStop();
        }
    };

    const updateMarketingType = async (values,resetForm) => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
		if(loginUser.roles_id  >= 4) {
			navigate('/login');
			return false;
		}
        if(values.SoftwareUsed == "Other" && values.othersofttype == "") {
            return false;
        }
        setMtOpen(false);
        handleFreezStart();
        const apiUrl = '/update-company-marketing-type';
        const args = {
            companyId: id,
            softwareTobeUsed: values.SoftwareUsed,
            otherSoftType: values.othersofttype
        }
        const res = await dispatch(postData({ apiUrl, args })).unwrap();
        if(res) {
            if (res.success === true) {
                swal({
                    title: "Success",
                    text: res.message,
                    icon: "success",
                    button: "Ok",
                })
                fetchCompanyDetails();
                handleFreezStop();
            }
        }
        resetForm();
    };

    const rbhandleChange = (data) => {
        storedReferralValue = data.referral_code
    };


    //Fetch Company Bank Details end

    const [bank_details, setBankDetails] = useState([]);
    const [tax_details, setTaxDetails] = useState([]);

    const fetchCompanyBankDetails = async () => {
        handleFreezStart();
        const apiUrl = '/fetch-company-bank-details/' + id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            handleFreezStop();
            if (res.companyBankDetails && res.companyBankDetails.company_master_id && res.companyBankDetails.company_master_id != null) {
                setBankDetails(res.companyBankDetails);
            } else {
                handleFreezStop();
            }
        }

    };

    const fetchTaxDetails = async () => {
        handleFreezStart();
        const apiUrl = '/company-tax-details/' + id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            handleFreezStop();
            if (res.success == true) {
                setTaxFlag(true);
                setTaxDetails(res.company1099Info);
            } else {
                handleFreezStop();
            }
        }
        
    };

    const checkUserRole = () => {
        var check = 1;
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id  > 2) {
            check = 0;
        } 
        if(loginUser.roles_id >= 3 && loginUser.roles_id <= 5) {
            if(loginUser.company_master_id != id) {
                check = 0;
            }else{
                check = 1;
            }
        }
        if(check == 0) {
            navigate('/login');
            return false;
        }
        return true;
    };

    const viewBankDetails = (compnyId) => {
        var check = checkUserRole();
        if(check === true) {
            window.location.href= config.BASE_URL + 'BankDetails/' + compnyId ;
        }
       
    }

    const viewTaxForm = () => {
        window.location.href= config.BASE_URL + 'Form1099';
    }

    //Fetch Company Bank Details end
    const columns = [
        {
            field: 'id',
            renderHeader: () => (
                <strong>
                    ID
                </strong>
            ),
            
            Width: 20,
            fontWeight: "bold",

        },
        {
            field: 'name',

            flex: isMobile ? 0 : 1,

            renderHeader: () => (
                <strong>
                    Name
                </strong>
            ),
          
            fontWeight: "bold",


        },
        {
            field: 'email',
            renderHeader: () => (
                <strong>
                    Email Address
                </strong>
            ),

            flex: isMobile ? 0 : 1,

            
            fontWeight: "bold",
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.email}
                        {params.row.email ? params.row.bounce_email_flag ? 
                            <Tooltip title="Emails may not be delivered to this address, but you can login using this email." arrow>
                            <ErrorOutlineIcon color={'error'} />
                            </Tooltip> 
                        : null : null}
                    </div>
                );
            },
        },
        {
            field: 'phone_no',
            renderHeader: () => (
                <strong>
                    Phone
                </strong>
            ),

            flex: isMobile ? 0 : 1,

           
            fontWeight: "bold",
            renderCell: (params) => {
                return (
                    <div>
                        {formatPhoneNumber(params.row.phone_no)}
                    </div>
                );
            },
        },
        {
            field: 'roles_id',
            renderHeader: () => (
                <strong>
                    User Role
                </strong>
            ),

           
            fontWeight: "bold",
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.role.role_name}
                    </div>
                );
            },
        },
        {
            field: 'is_active',
            renderHeader: () => (
                <strong>
                    Status
                </strong>
            ),

            
            fontWeight: "bold",
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.is_active == 1 ? "Active" : "Deactivated"}
                    </div>
                );
            },
        },
        {
            field: 'created_at',
            renderHeader: () => (
                <strong>
                    Created Date
                </strong>
            ),
            flex: isMobile ? 0 : 1,


            // minWidth: '120',
            fontWeight: "bold",

            renderCell: (params) => {
                return (
                    <div>
                        {Moment(params.row.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY')}
                    </div>
                );
            },
        },

        {

            field: 'action',
            renderHeader: () => (
                <strong>
                    Action
                </strong>
            ),

            // flex: isMobile ? 0 : 1,

            Width: 25,
            sortable: false,
            disableClickEventBubbling: true,


            renderCell: (params) => {
                const onClick = (e) => {
                    var check = checkUserRole();
                    if(check === true) {
                        const currentRow = params.row;
                        navigate('/edituser/' + currentRow.id)
                    }
                };
                return (
                    <div>
                        {company_details.is_disabled == 0 ? user.roles_id <= 3 ?
                        user.id != params.row.id ?

                            <Tooltip title="Edit User" arrow><EditOutlinedIcon onClick={onClick} /></Tooltip>
                            :' ' :  <Typography>none</Typography> : ''}

                    </div>
                );
            },
        }


    ];


   
    //variable declearation start

    const { http, user } = AuthUser();
    const { id } = useParams();
    const [statusMessage, setStatusMesage] = useState("");
    const navigate = useNavigate();
    const [company_details, setcompanyDetails] = useState([]);
    const [epicProStatus, setEpicProStatus] = React.useState(false);
    const [aids, setAids] = useState([]);
    const [pids, setPids] = useState([]);
    const [aid, setAid] = useState();
    const [aidusers, setAidUsers] = useState([]);
    const [cobrandpids, setCobrandPids] = useState([]);
    const [logoImg, setLogoImg] = useState();
    
    //variable declearation ends
    useEffect(() => {

        fetchCompanyDetails();
        fetchCompanyBankDetails();
        fetchReferredByList();
        fetchTaxDetails();
    }, []);
   
    
   
    // fectch function starts
    const fetchCompanyDetails = async () => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
        if(loginUser.roles_id >= 3 && loginUser.roles_id <= 5) {
            if(loginUser.company_master_id != id) {
                navigate('/login');
            }
        }
        handleFreezStart();
        const apiUrl = '/companyDetails/' + id;
        const res = await dispatch(fetchData(apiUrl)).unwrap();
        if (res) {
            if (res.company_details.is_disabled == 1) {
                navigate('/companypanel');
            }
            handleFreezStop();
            setcompanyDetails(res.company_details);
            setEpicProStatus(res.company_details.epic_pro_status);
            setLogoImg(res.company_details.company_logo_url)
            setPids(res.pids);
            setAids(res.aids);
            setAidUsers(res.aid_users);
            setCobrandPids(res.coBrand);
        }
    };
    const ContactSchema = Yup.object().shape({
        AID: Yup.string()
            .min(2, 'Too Short!')
            .max(60, 'Length Of AID can be maximum 60 Chars/digits!')
            .matches(/^[^,\s]*$/, 'No commas or spaces allowed')
            .matches(/^[a-zA-Z0-9]*$/, 'Only alphanumeric characters allowed')
            .required('Please enter AID.'),

    });

    const marketingTypeSchema = Yup.object().shape({
        SoftwareUsed: Yup.string()
            .required('Please enter Software.')
        });

    const handleChangeSwitch = () => {
        setEpicRroOpen(true);
    }

    const mobileDialogModalStyles = {
        position: 'absolute',
        left: `${scrollX}px`,
    };

    const editCommunicationEmailPopup = async(companyId , companyName , email , communication_email) => {
		setEmail(email);
		setcompanyName(companyName);
		setcompanyId(companyId);
		setcommunicationEmail(communication_email);
		setopenCommEditEmailPopup(true);
	};
	const editCommunicationEmailPopupClose = async() =>{
		setopenCommEditEmailPopup(false);
	};

    const setErrorMessageFun = (successMessage , errorMessage) => {

        setErrorMessage(errorMessage)
        setStatusMesage(successMessage)
        setTimeout(function () {
            setStatusMesage('')
            setErrorMessage('')
        }, 5000);
                    
    } 

    const refreshPageAfterEmailChange = () =>{
		fetchCompanyDetails();
	}

    const changeEpicProStaus = () => {
        const loginUser = JSON.parse(localStorage.getItem('user'));
		if(loginUser.roles_id  > 2) {
			navigate('/login');
			return false;
		}
        handleFreezStart();
        setEpicProStatus(!epicProStatus);
        const apiUrl = "/saveEpicProStatus";
        const args = {
            companyId: id,
            status: !epicProStatus
        }
        const res = dispatch(postData({ apiUrl, args })).unwrap();
        if (res) {
            handleFreezStop();
            swal({
                title: "Success",
                text: `Epic PRO status has been ${epicProStatus ? "disabled" : "enabled"} successfully`,
                icon: "success",
                button: "Ok",
            })
            if (res.success == true) {
                setStatusMesage(res.message);
                setTimeout(() => {
                    setStatusMesage('');
                }, 3000);
            }
        }
        setEpicRroOpen(false);
    }

    // const getPandaDoc = async (id) => {
	// 	const loginUser = JSON.parse(localStorage.getItem('user'));
	// 	if(loginUser.roles_id  > 2) {
	// 		navigate('/login');
	// 		return false;
	// 	}
	// 	window.open(
	// 					"https://app.pandadoc.com/a/#/document/v1/editor/" + id,
	// 					'_blank' // <- This is what makes it open in a new window.
	// 				);
		
	// }
    const getPandaDoc = async (id) => {
		const loginUser = JSON.parse(localStorage.getItem('user'));
		if(loginUser.roles_id  > 2) {
			navigate('/login');
			return false;
		}
		window.open(
			config.BASE_URL+"AffiliateAgreement/"+id+"/YWZmaWxpYXRl"+"/2" ,
						'_blank' // <- This is what makes it open in a new window.
					);
		
	}
	const getPandaDocNew = async (name) => {
		const loginUser = JSON.parse(localStorage.getItem('user'));
		if(loginUser.roles_id  > 2) {
			navigate('/login');
			return false;
		}
		const apiUrl = `/get-pdf/${name}`;
		window.open(config.API_BASE_URL+apiUrl); 

	}
    // fetch function ends
    const ContentLayout =
            <>
            <Box>

           
                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{alignItems:'center'}}>
                    <Grid lg={6} md={6} sm={6} xs={6} item>
                        {user.roles_id < 3 ?
                            <Typography variant="h6" color="primary">Company Information</Typography> :
                            <Typography variant="h6" color="primary">My Company</Typography>}
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}  className='companyLogo' pt={5}>
                   { logoImg
                        ?
                        <img src={config.BACKEND_URL + "images/companyLogo/" + logoImg} />
                        :
                        <img src={require("../../../assets/images/uploadLogo.png")} />}
                       
                       
                    </Grid>
                </Grid>
                <Box pb={1} sx={{ width: '100%' }}>
                    {statusMessage ? <Alert severity="success">{statusMessage}</Alert> : ''}
                </Box>
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={1} >
                    <Card className='cardstyle' sx={{width:'100%'}}>
                        <CardOverflow
                            sx={{
                                bgcolor: '#F6FAFF',
                                borderColor: '#F6FAFF',
                            }}
                        >
                            <Grid container item>
                                <Typography variant="h6" color="primary">Company Details</Typography>
                            </Grid>
                        </CardOverflow>
                        <CardContent>
                            <Table  size="sm" variant="plain">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="borderStyle custom-table-cell" >Company Name</TableCell>
                                        <TableCell className="borderStyle custom-table-cell">Company Website</TableCell>
                                        <TableCell className="borderStyle custom-table-cell">Title</TableCell>
                                        <TableCell className="borderStyle custom-table-cell">Business Started Year</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell className="borderStyle details" ><Typography variant="subtitle1" gutterBottom><b>{(company_details.company_name) || "N/A"}</b></Typography></TableCell>
                                        <TableCell className="borderStyle datatable"><Typography variant="subtitle1" gutterBottom><b>{(company_details.company_website) || "N/A"}</b></Typography></TableCell>
                                        <TableCell className="borderStyle datatable"><Typography variant="subtitle1" gutterBottom><b>{(company_details.title) || "N/A"}</b></Typography></TableCell>
                                        <TableCell className="borderStyle"><Typography variant="subtitle1" gutterBottom><b>{(company_details.business_started_year) || "N/A"}</b></Typography></TableCell>
                                    </TableRow>
                                </TableBody>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className=" borderStyle custom-table-cell" >Marketing Type</TableCell>
                                        <TableCell className="borderStyle custom-table-cell" >Referral Code</TableCell>
                                        <TableCell className="borderStyle custom-table-cell">Referral Enrolled Date</TableCell>
                                        <TableCell className="borderStyle custom-table-cell">Referred By</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell className="borderStyle details">
                                            <Typography variant="subtitle1" gutterBottom>
                                                <b>{(company_details.marketing_type) || "N/A"}</b>
                                                {user.roles_id <= 3 ?
                                                    <Tooltip title="Edit" arrow>
                                                        <IconButton color="primary" onClick={openChangeMarketingType}>
                                                            <EditOutlinedIcon fontSize="small" />
                                                        </IconButton></Tooltip> : ""}
                                            </Typography></TableCell>
                                        <TableCell className="borderStyle"><Typography variant="subtitle1" gutterBottom><b>{(company_details.referral_code) || "N/A"}</b></Typography></TableCell>
                                        <TableCell className="borderStyle"><Typography variant="subtitle1" gutterBottom><b>{company_details.referral_enrolled_date ? Moment(company_details.referral_enrolled_date).format('MM-DD-YYYY') : ''}</b></Typography></TableCell>
                                        <TableCell className="borderStyle  datatable" >
                                            <Typography variant="subtitle1" gutterBottom><b>{company_details.referred_by}
                                                {user.roles_id < 3 ?
                                                    <Tooltip title="Edit" arrow><IconButton color="primary" onClick={rbhandleOpen}>
                                                        <EditOutlinedIcon fontSize="small" />
                                                    </IconButton></Tooltip> : ""}</b></Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                                <TableHead>
                                    <TableRow>

                                        <TableCell className="borderStyle custom-table-cell">Snapshot Status</TableCell>
                                        <TableCell className="borderStyle custom-table-cell">Company Status</TableCell>
                                        <TableCell className="borderStyle custom-table-cell">Affiliate Name</TableCell>
                                        <TableCell className="borderStyle custom-table-cell" >Agreement ID</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>

                                        <TableCell className="borderStyle"><Typography variant="subtitle1" gutterBottom><b>{company_details.snapshot_flag == 1 ? "Active" : "Not Active"}</b></Typography></TableCell>
                                        <TableCell className="borderStyle"><Typography variant="subtitle1" gutterBottom><b>{company_details.is_disabled == 1 ? "Disabled" : company_details.company_status == 1 ? "Active" : "Not Active"}</b></Typography></TableCell>                                            
                                        <TableCell className="borderStyle details"><Typography variant="subtitle1" gutterBottom><b>{company_details.reference_affiliates ? company_details.reference_affiliates.first_name != null && company_details.reference_affiliates.last_name != null ? company_details.reference_affiliates.first_name + ' ' + company_details.reference_affiliates.last_name : 'N/A' : 'N/A'} </b></Typography></TableCell>
                                        <TableCell className="borderStyle datatable" >
                                            <Typography variant="subtitle1" gutterBottom>
                                                <b>
                                                    {company_details.reference_affiliates && company_details.reference_affiliates.pandadoc_id ? (
                                                        <>
                                                            {company_details.reference_affiliates.pandadoc_id}
                                                            {user.roles_id < 3 && (
                                                                <Tooltip title="View Agreement" arrow>
                                                                    <LinkIcon
                                                                        color="primary"
                                                                        onClick={() => {
                                                                            {company_details.reference_affiliates.reference_affiliate_status_master_id==4||company_details.reference_affiliates.reference_affiliate_status_master_id==5?getPandaDocNew(company_details.company_name):getPandaDoc(company_details.reference_affiliates.pandadoc_id) }
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                            )}
                                                        </>
                                                    ) : 'N/A'}
                                                </b>
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                                <TableHead>
                                    <TableRow>

                                        <TableCell className="borderStyle custom-table-cell">Referral Status</TableCell>
                                        {user.roles_id < 3 ? <TableCell className="borderStyle custom-table-cell" >Epic PRO status</TableCell> : null}
                                        <TableCell className="borderStyle custom-table-cell">Phone No.</TableCell>
                                        <TableCell className="borderStyle custom-table-cell " >Email</TableCell>
                                        { user.roles_id > 2 ?<TableCell className="borderStyle custom-table-cell" >Communication Email</TableCell> : null}

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>

                                        <TableCell className="borderStyle custom-table-cell"><Typography variant="subtitle1" gutterBottom ><b>{company_details.referral_flag == 1 ? "Active" : "Not Active"}</b></Typography></TableCell>
                                        {user.roles_id < 3 ?
                                            <TableCell className="custom-table-cell">
                                                <Stack direction="row" alignItems="center">
                                                    <Typography variant="subtitle1" gutterBottom><b>Off</b></Typography>
                                                    <Switch checked={epicProStatus}
                                                        onChange={handleChangeSwitch}
                                                        inputProps={{ 'aria-label': 'Switch A' }}
                                                    />
                                                    <Typography variant="subtitle1" gutterBottom><b>On</b></Typography>
                                                </Stack>
                                            </TableCell>
                                            : null
                                        }
                                        <TableCell className="borderStyle custom-table-cell"><Typography variant="subtitle1" gutterBottom><b>{formatPhoneNumber(company_details.reference_affiliates ? company_details.reference_affiliates.phone_no : 'N/A')}</b> </Typography></TableCell>
                                        <TableCell className="borderStyle custom-table-cell datatable " ><Typography variant="subtitle1" gutterBottom><b>{company_details.reference_affiliates ? company_details.reference_affiliates.email : 'N/A'}</b> </Typography></TableCell>
                                        {
                                            user.roles_id > 2 ?
                                            <TableCell className="borderStyle custom-table-cell" >
                                                <Typography variant="subtitle1" gutterBottom className='datatable12'><b>{company_details.reference_affiliates ? company_details.reference_affiliates.communication_email : 'N/A'}
                                            {company_details.reference_affiliates ? company_details.reference_affiliates.bounce_email_flag === 1 ? 
                                                <Tooltip title="Emails are not getting delivered to this address. Please update the communication email" arrow>
                                                <ErrorOutlineIcon color={'error'} />
                                                </Tooltip> 
                                            : null : null}
                                            {
                                                 user.roles_id <= 3  ?
                                                 <Tooltip title="Edit Communication Email" arrow>
                                                    <EditOutlinedIcon
                                                        onClick={() => {editCommunicationEmailPopup(company_details.id ,company_details.company_name , company_details.reference_affiliates.email, company_details.reference_affiliates.communication_email)}}
                                                    />
                                                </Tooltip>
                                                : ''
                                            }
                                            
                                            </b> </Typography></TableCell>
                                             :''
                                        }


                                    </TableRow>
                                </TableBody>
                                <TableHead>
                                    <TableRow>
                                        {user.roles_id < 3 ? <TableCell className="borderStyle custom-table-cell" >Communication Email</TableCell> : ''}
                                        <TableCell className="borderStyle custom-table-cell" colSpan={2}>Enabled PIDs</TableCell>
                                      
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                    {
                                           user.roles_id < 3  ?
                                            <TableCell className="borderStyle custom-table-cell datatable " ><Typography variant="subtitle1" gutterBottom><b>{company_details.reference_affiliates ? company_details.reference_affiliates.communication_email : 'N/A'}
                                            {company_details.reference_affiliates ? company_details.reference_affiliates.bounce_email_flag === 1 ? 
                                                <Tooltip title="Emails are not getting delivered to this address. Please update the communication email" arrow>
                                                <ErrorOutlineIcon color={'error'} />
                                                </Tooltip> 
                                            : null : null}
                                            {company_details.reference_affiliates ? company_details.reference_affiliates.communication_email != null ?
                                            <>
                                            {
                                                user.roles_id <= 3  ?
                                                <Tooltip title="Edit Communication Email" arrow>
                                                    <EditOutlinedIcon
                                                        onClick={() => {editCommunicationEmailPopup(company_details.id ,company_details.company_name , company_details.reference_affiliates.email, company_details.reference_affiliates.communication_email)}}
                                                    />
                                                </Tooltip>
                                            : ''
                                            }
                                             
                                            </>
                                            : 'N/A':'N/A'}
                                           
                                            </b> </Typography></TableCell>
                                            :''
                                        }   
                                        <TableCell className="borderStyle custom-table-cell" colSpan={2}>
                                            <Typography variant="subtitle1" gutterBottom>
                                                <b>
                                                  
                                                    
                                                    {
                                                        (() => {
                                                            let isValidItemFound = false;
                                                            let enablePids = ''; 
                                                            const items = cobrandpids.map((item, i) => {
                                                                if (item.cobranPid !== 0 && item.cobranPid !== null && item.cobrandStatus === 1 && item.isDisabled === 0) {
                                                                    isValidItemFound = true;
                                                                    enablePids += item.cobranPid + ","; 
                                                                }
                                                                return null;
                                                            });
                                                            return isValidItemFound ? enablePids.endsWith(',') ? enablePids.slice(0,-1) : enablePids : "N/A";
                                                        })()
                                                    }
                                                </b>
                                            </Typography>
                                        </TableCell>

                                    </TableRow>
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>

                </Grid>
                {user.roles_id <= 4 ?
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                    <Card className='cardstyle' sx={{width:'100%'}}>
                        <CardOverflow
                            sx={{
                                bgcolor: '#F6FAFF',
                                borderColor: '#F6FAFF',
                            }}
                        >
                            <Grid container item sx={{ justifyContent: 'space-between' }}>
                                {
                                    <Typography variant="h6" color="primary">Bank Details</Typography>
                                }
                                {user.roles_id <= 4 ?
                                <Tooltip title="Edit" arrow><EditOutlinedIcon onClick={() => viewBankDetails(id)} sx={{ mt: 0.5 }} /></Tooltip>
                                : ''
                                }
                            </Grid>
                        </CardOverflow>
                        <CardContent>
                            <Table  size="sm" variant="plain">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="borderStyle custom-table-cell">Bank Name</TableCell>
                                        <TableCell className="borderStyle custom-table-cell">Bank Routing Number</TableCell>
                                        <TableCell className="borderStyle custom-table-cell">Name on Account</TableCell>
                                        <TableCell className="borderStyle custom-table-cell">Account Number</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell className="borderStyle"><Typography variant="subtitle1" gutterBottom><b>{bank_details.bank_name ? bank_details.bank_name : "N/A"}</b></Typography></TableCell>
                                        <TableCell className="borderStyle"><Typography variant="subtitle1" gutterBottom><b>{bank_details.routing_number ? bank_details.routing_number : "N/A"}</b></Typography></TableCell>
                                        <TableCell className="borderStyle"><Typography variant="subtitle1" gutterBottom><b>{bank_details.name_on_account ? bank_details.name_on_account : "N/A"}</b></Typography></TableCell>
                                        <TableCell className="borderStyle datatable"><Typography variant="subtitle1" gutterBottom><b>{bank_details.account_number ? bank_details.account_number : "N/A"}</b></Typography></TableCell>
                                    </TableRow>
                                </TableBody>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className=" borderStyle custom-table-cell">ACH date</TableCell>
                                        <TableCell className="borderStyle custom-table-cell">Type of Account</TableCell>
                                        <TableCell className="borderStyle custom-table-cell">Is Account</TableCell>
                                        <TableCell className="borderStyle custom-table-cell">ACH Added</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell className="borderStyle" ><Typography variant="subtitle1" gutterBottom><b>{bank_details.last_ach_updated ? Moment(bank_details.last_ach_updated).format('MM-DD-YYYY') : "N/A"}</b></Typography></TableCell>
                                        <TableCell className="borderStyle"><Typography variant="subtitle1" gutterBottom><b>{bank_details.account_type ? bank_details.account_type : "N/A"}</b></Typography></TableCell>
                                        <TableCell className="borderStyle"><Typography variant="subtitle1" gutterBottom><b>{bank_details.account_category ? bank_details.account_category : "N/A"}</b></Typography></TableCell>
                                        <TableCell className="borderStyle"><Typography variant="subtitle1" gutterBottom><b>{bank_details.ach_info_added_to_bank ? bank_details.ach_info_added_to_bank == 1 ? "Added" : "Not Added" : "N/A"}</b></Typography></TableCell>
                                    </TableRow>
                                </TableBody>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="borderStyle custom-table-cell" >Email Address to Notify</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell className="borderStyle" ><Typography variant="subtitle1" gutterBottom><b>{bank_details.email_to_notify ? bank_details.email_to_notify : "N/A"}</b></Typography></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                </Grid>
                : null}
                {/* Tax Details Start here */}
                {user.roles_id <= 3 && taxFlag ?
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                        <Card className='cardstyle' sx={{width:'100%'}}>
                            <CardOverflow
                                sx={{
                                    bgcolor: '#F6FAFF',
                                    borderColor: '#F6FAFF',
                                }}
                            >
                                <Grid container item sx={{ justifyContent: 'space-between' }}>
                                    {
                                        <Typography variant="h6" color="primary">Tax Details</Typography>
                                    }
                                {
                                    user.roles_id == 3 ?
                                    <Tooltip title="Edit" arrow><EditOutlinedIcon onClick={() => viewTaxForm()} sx={{ mt: 0.5 }} /></Tooltip>
                                    : ''}
                                </Grid>
                            </CardOverflow>
                            <CardContent>
                                <Table  size="sm" variant="plain">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="borderStyle">Company Name</TableCell>
                                            <TableCell className="borderStyle">Name</TableCell>
                                            <TableCell className="borderStyle">Phone Number</TableCell>
                                            <TableCell className="borderStyle">Mobile Number</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className="borderStyle"><Typography variant="subtitle1" gutterBottom><b>{tax_details.company_name ? tax_details.company_name : "N/A"}</b></Typography></TableCell>
                                            <TableCell className="borderStyle"><Typography variant="subtitle1" gutterBottom><b>{tax_details.name ? tax_details.name : "N/A"}</b></Typography></TableCell>
                                            <TableCell className="borderStyle"><Typography variant="subtitle1" gutterBottom><b>{tax_details.phone_number ? tax_details.phone_number : "N/A"}</b></Typography></TableCell>
                                            <TableCell className="borderStyle datatable"><Typography variant="subtitle1" gutterBottom><b>{tax_details.mobile_number ? tax_details.mobile_number : "N/A"}</b></Typography></TableCell>
                                        </TableRow>
                                    </TableBody>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className=" borderStyle">email</TableCell>
                                            <TableCell className="borderStyle">street address</TableCell>
                                            <TableCell className="borderStyle">city</TableCell>
                                            <TableCell className="borderStyle">state</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className="borderStyle" ><Typography variant="subtitle1" gutterBottom><b>{tax_details.email ? tax_details.email : "N/A"}</b></Typography></TableCell>
                                            <TableCell className="borderStyle"><Typography variant="subtitle1" gutterBottom><b>{tax_details.street_address ? tax_details.street_address : "N/A"}</b></Typography></TableCell>
                                            <TableCell className="borderStyle"><Typography variant="subtitle1" gutterBottom><b>{tax_details.city ? tax_details.city : "N/A"}</b></Typography></TableCell>
                                            <TableCell className="borderStyle"><Typography variant="subtitle1" gutterBottom><b>{tax_details.state_code ? tax_details.state_code  : "N/A"}</b></Typography></TableCell>
                                        </TableRow>
                                    </TableBody>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="borderStyle" >Zip</TableCell>
                                            <TableCell className="borderStyle" >Country</TableCell>
                                            <TableCell className="borderStyle" >Fax</TableCell>
                                            <TableCell className="borderStyle" >TIN</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className="borderStyle" ><Typography variant="subtitle1" gutterBottom><b>{tax_details.zip ? tax_details.zip : "N/A"}</b></Typography></TableCell>
                                            <TableCell className="borderStyle" ><Typography variant="subtitle1" gutterBottom><b>{tax_details.country ? tax_details.country : "N/A" }</b></Typography></TableCell>
                                            <TableCell className="borderStyle" ><Typography variant="subtitle1" gutterBottom><b>{tax_details.fax ? tax_details.fax : "N/A" }</b></Typography></TableCell>
                                            <TableCell className="borderStyle" ><Typography variant="subtitle1" gutterBottom><b>{tax_details.tax_id ? tax_details.tax_id : "N/A" }</b></Typography></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>
                    </Grid>
                    : ''
                }
                 {/* Tax Details end here */}
                <Typography variant="h6" color="primary" mb={1} mt={1}>Company Users</Typography>
                <div style={{ width: '100%' }}>
                    {aidusers == null ? "" :
                        <DataGrid
                            rows={aidusers}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            disableSelectionOnClick={true}
                            sx={{
                                minHeight: 250, maxHeight: 200, width: '100%',
                            }}
                        />
                    }
                </div>
                <Grid item container lg={12} md={12} sm={12} xs={12} spacing={2} mt={1}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Card className='cardstyle' sx={{ minHeight: 300, maxHeight: 150 }}>
                            <CardOverflow
                                sx={{
                                    bgcolor: '#F6FAFF',
                                    borderColor: '#F6FAFF',
                                }}
                            >
                                <Grid container item >
                                    <Typography variant="h6" color="primary">Cobrand PIDs</Typography>
                                </Grid>
                            </CardOverflow>
                            <CardContent>
                                {/* <TableContainer> */}
                                <Sheet sx={{ height: 200, overflow: 'auto' }}>
                                    <Table
                                        aria-label="table with sticky header"
                                        stickyHeader
                                        stripe="odd"
                                        >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ width: "15%" }}><Typography><b>AID</b></Typography></TableCell>
                                                <TableCell sx={{ width: "20%" }}><Typography><b>Cobrand Pid</b></Typography></TableCell>
                                                <TableCell sx={{ width: "20%" }}><Typography><b>Base Pid</b></Typography></TableCell>
                                                <TableCell sx={{ width: "15%" }}><Typography><b>Type</b></Typography></TableCell>
                                                <TableCell sx={{ width: "30%" }}><Typography><b>Cobrand Status</b></Typography></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {cobrandpids == null ? "" :
                                            cobrandpids.map(function (item, index) {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <TableBody >
                                                            <TableRow>
                                                                <TableCell className='datatable'>{item.aid}</TableCell>
                                                                <TableCell>{item.cobranPid === 0 || item.cobranPid === null ? '' : item.cobranPid}</TableCell>
                                                                <TableCell>{item.basePid === 0 || item.basePid === null ? '' : item.basePid}</TableCell>
                                                                <TableCell>{item.pidType}</TableCell>
                                                                <TableCell>{item.cobrandStatus == 1 && item.isDisabled == 0 ?  "Active" : "Inactive"}</TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </React.Fragment>
                                                )
                                            })
                                        }

                                    </Table>
                                </Sheet>
                                {/* </TableContainer> */}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Card className='cardstyle' sx={{ minHeight: 300, maxHeight: 150 }}>
                            <CardOverflow
                                sx={{
                                    bgcolor: '#F6FAFF',
                                    borderColor: '#F6FAFF',
                                }}
                            >
                                <Grid container item sx={{ justifyContent: 'space-between' }} >
                                    <Typography variant="h6" color="primary">AIDs</Typography>
                                    {user.roles_id <= 2 ?
                                        <Tooltip title="Create AID" arrow><AddCircleOutlineIcon onClick={handleOpen} sx={{ mt: 0.5 }} /></Tooltip>
                                        : null}
                                </Grid>
                            </CardOverflow>
                            <CardContent>
                                <TableContainer>
                                    <Sheet sx={{ height: 250, overflow: 'auto' }}>
                                        <Table aria-label="table with sticky header"
                                            stickyHeader
                                            stripe="odd"
                                            >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ width: "25%" }}><Typography ><b>AID</b></Typography></TableCell>
                                                    <TableCell sx={{ width: "35%" }}><Typography ><b>Created by</b></Typography></TableCell>
                                                    <TableCell sx={{ width: "25%" }}><Typography ><b>Created at</b></Typography></TableCell>
                                                    <TableCell sx={{ width: "15%" }}><Typography ><b>Status</b></Typography></TableCell>

                                                </TableRow>
                                            </TableHead>
                                            {aids == null ? "" :
                                                aids.map(function (item, index) {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <TableBody >
                                                                <TableRow>
                                                                    <TableCell className='datatable'>{item.aid}</TableCell>
                                                                    <TableCell>{item.user ? item.user.name : "System Generated"}{item.user ? item.user.roles_id == "1" ? "(Super Admin)" : item.user.roles_id == "2" ? "(Admin)" : item.user.roles_id == "3" ? "(Affiliate)" : "(No Roles)" : ""}</TableCell>
                                                                    <TableCell>{Moment(item.created_at).tz('America/Los_Angeles').format('MM-DD-YYYY')}</TableCell>
                                                                    <TableCell>{item.aid_status == 1 ? "Active" : "Disabled"}</TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                        </Table>
                                    </Sheet>
                                </TableContainer>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                                            
                <Formik
                    initialValues={{
                        AID: aid1,
                    }}
                    validationSchema={ContactSchema}
                    onSubmit={async (values, errors) => {
                        handleFreezStart();
                        const apiUrl = '/addCompanyAID';
                        const args = {
                            company_master_id: id,
                            aid: values.AID
                        }
                        const res = await dispatch(postData({ apiUrl, args })).unwrap();
                        let shouldClosePopup = false;
                        if (res) {
                            if (res.success === true) {
                                handleFreezStop();
                                setOpen(false);
                                swal({
                                    title: "Success",
                                    text: res.message,
                                    icon: "success",
                                    button: "Ok",
                                    showBackdrop: true, // Show backdrop
                                    closeOnClickOutside: false, // Disable closing on click outside
                                }).then((ok) => {
                                    if (ok) {
                                        handleClose();
                                        fetchCompanyDetails();
                                        shouldClosePopup = true;
                                    }
                                });
                            } else {
                                handleFreezStop();
                                setOpen(false);
                                swal({
                                    title: "Failed",
                                    text: "AID Already Exists.",
                                    icon: "error",
                                    button: "Ok",
                                    showBackdrop: true,
                                    closeOnClickOutside: false,
                                });
                            }
                        }
                        document.querySelector(".swal-modal").addEventListener("click", (event) => {
                            event.stopPropagation();
                        });

                        // Listen for clicks outside the popup and close it if needed
                        document.body.addEventListener("click", () => {
                            if (shouldClosePopup) {
                                swal.close();
                            }
                        });
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                        <>
                            <div>
                                <Dialog
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                    fullWidth={fullWidth}
                                    maxWidth={maxWidth}
                                >
                                    {/* <Box sx={style}> */}
                                    <>
                                        <DialogTitle textAlign={'center'} id="responsive-dialog-title" p={5} sx={{ backgroundColor: "#F6FAFF" }}>
                                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                                <Typography variant="h5" color='primary'>Enter new Aid</Typography>
                                            </Grid>
                                        </DialogTitle>
                                        <DialogContent>
                                            <Grid container item lg={12} md={12} sm={12} xs={12}>
                                                <TextField
                                                    label="New AID"
                                                    type="text"
                                                    size="small"
                                                    fullWidth
                                                    margin='dense'
                                                    value={values.AID}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                    }}
                                                    // onChange={(e) => { handlechange1(e.target.value) }}
                                                    name='AID'
                                                    id='AID'
                                                    helperText={touched.AID && errors.AID ? errors.AID : null}
                                                    error={touched.AID && errors.AID ? errors.AID : null}
                                                />
                                            </Grid>
                                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }} spacing={3} mt={2}>
                                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                                    <Button variant="contained" sx={{ minWidth: 200 }} color="success" onClick={handleSubmit}>Create</Button>
                                                </Grid>
                                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                                    <Button variant="contained" sx={{ minWidth: 200 }} color="primary" onClick={handleClose}>Close</Button>
                                                </Grid>
                                            </Grid>
                                        </DialogContent>
                                    </>
                                    {/* </Box> */}
                                </Dialog>
                            </div>
                        </>
                    )}
                </Formik>
                {/* refered by modal code starts */}
                <Dialog
                    open={rbopen}
                    onClose={rbhandleClose}
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                >
                    <DialogTitle variant="h6" color='primary'>Change Referred By</DialogTitle>
                    <DialogContent>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            <Autocomplete
                                options={rbOption}
                                getOptionLabel={(option) => option.company_name || ''}
                                onChange={(event, referredValue) => { rbhandleChange(referredValue) }}
                                fullWidth
                                margin="dense"
                                renderInput={(params) => <TextField {...params} label="Company Names" />}
                            />
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }} spacing={2} mt={1}>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                <Button variant="contained" onClick={rbhandleClose} sx={{ minWidth: 200, backgroundColor: "#2A68B3", color: "white" }} color="primary">
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                <Button variant="contained" onClick={rbhandleSave} sx={{ minWidth: 200, backgroundColor: "#76C044", color: "white" }} color="success">
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
                {/* refered by modal code ends */}

                 {/* epic pro status modal code starts */}
                 <Dialog
                    open={epicRroOpen}
                    onClose={(_, reason) => {
                        if (reason !== "backdropClick") {
                            epicProHandleClose();
                        }
                    }}
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                >
                    <DialogTitle variant="h6" color='primary'>Change Epic PRO Status</DialogTitle>
                    <DialogContent>
                        <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                            {
                                epicProStatus ? 
                                <>
                                    If you disable Epic PRO status, Affiliate will not be able to view their respective member's Epic PRO report in memberlist.
                                </>
                                : 
                                <>
                                If you enable Epic PRO status, Affiliate will be able to view their respective member's Epic PRO report in memberlist.
                                </>
                               
                            }

                            <br/><br/>
                            Are you sure, you want to {epicProStatus ? "disable" : "enable"} Epic PRO status ?

                            <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }} spacing={2} mt={1}>
                            
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <Button variant="contained" onClick={epicProHandleClose} sx={{ minWidth: 200, backgroundColor: "#2A68B3", color: "white" }} color="primary">
                                        No
                                    </Button>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <Button variant="contained" onClick={changeEpicProStaus} sx={{ minWidth: 200, backgroundColor: "#76C044", color: "white" }} color="success">
                                        Yes
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
                {/* epic pro status modal code ends */}

                {/* communication email edit code starts */}
                <Dialog
					onClose={(_, reason) => {
						if (reason !== "backdropClick") {
							editCommunicationEmailPopupClose();
						}
					}}
					fullWidth={fullWidth}
					maxWidth={maxWidth}
					open={openCommEditEmailPopup}
					aria-labelledby="responsive-dialog-title"
					style={isMobile ? mobileDialogModalStyles : null}
				>
					
					<Card>
						<CardOverflow
							sx={{
								bgcolor:'#F6FAFF',
								borderColor:'#F6FAFF',
								padding: '1%',
							}}>
								{statusMessage ? <Alert severity="success">{statusMessage}</Alert> : ''}
								{errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ''}
								<Grid container item lg={12} md={12} sm={12} xs={12}>
								<Typography gutterBottom color="primary" variant='h5'><b>Update communication email</b></Typography> 
								</Grid>
						</CardOverflow>
						<CardContent>
							
							<Grid container item lg={12} md={12} sm={12} xs={12}>
								<Grid item lg={4} md={4} sm={4} xs={4}>
									<Typography  variant="h6" gutterBottom>Company Name:
									</Typography>
								</Grid>
                            <Grid item lg={8} md={8} sm={8} xs={8}>
							<Typography  variant="h6" gutterBottom><b>{companyName }</b></Typography>
                                    </Grid>
                                </Grid>
								<Grid container item lg={12} md={12} sm={12} xs={12}>
							<Grid item lg={4} md={4} sm={4} xs={4}>
								<Typography  variant="h6" gutterBottom>Registered Email:
								</Typography>
								</Grid>
								<Grid item lg={8} md={8} sm={8} xs={8}>
										<Typography  variant="h6" gutterBottom><b>{email}</b></Typography>
								</Grid>
							</Grid>
						<BounceEmailDailog  id={companyId} email={email} communication_email = {communicationEmail} onValueChange={setErrorMessageFun} closeModal = {editCommunicationEmailPopupClose} fetchData = {refreshPageAfterEmailChange}/>
					</CardContent>
					</Card>
				</Dialog>
                {/* communication email edit code ends */}

                 {/* marketing type modal code starts */}
                <Formik
                    initialValues={RequestFormInitialValues}
                    validationSchema={marketingTypeSchema}
                    onSubmit={async (values, { resetForm }) => {
                        updateMarketingType(values,resetForm);
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue,resetForm }) => (
                        <>
                        <Dialog
                            open={mtOpen}
                            onClose={mThandleClose}
                            fullWidth={fullWidth}
                            maxWidth={maxWidth}
                        >
                            <DialogTitle variant="h6" color='primary'>Change Software to be Used</DialogTitle>
                            <DialogContent>
                                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2}>
                                    <FormControl fullWidth>
                                    <InputLabel id="demo-multiple-name-label">Software to be Used</InputLabel>
                                        <Select
                                            // required
                                            labelId="demo-multiple-name-label"
                                            id="SoftwareUsed"
                                            input={<OutlinedInput label="Software to be Used" />}
                                            name="SoftwareUsed"
                                            value={values.SoftwareUsed?values.SoftwareUsed:''}
                                            onChange={(e) => {
                                                handleChange(e);
                                                setSoftwareType(e.target.value); values.othersofttype = "";
                                            }}
                                            onBlur={handleBlur}
                                            MenuProps={MenuProps}
                                        >
                                            {softwares.map((software,index) => (
                                                <MenuItem
                                                key={index}
                                                    value={software.link_name}
                                                >
                                                    {software.software_name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} mt={2} display={RequestFormInitialValues.SoftwareUsed == "Other" ? "block" :"none"} id="othersofttypes">
                                    <FormControl fullWidth>
                                        <TextField
                                            // required
                                            autoComplete="othersofttype"
                                            name="othersofttype"
                                            onChange={(e) => { handleChange(e); handleothersoft(e.target.value); }}
                                            value={values.othersofttype}
                                            onBlur={handleBlur('othersofttype')}
                                            fullWidth
                                            id="othersofttype"
                                            label=" Other Software Name "
                                        />
                                    <p style={{ color: '#d32f2f', fontFamily: "Roboto", fontWeight: 400, fontSize: "0.90rem" }}>{checkOtherStatus === true ? "Please Enter Software Name" : ''}</p>
                                </FormControl>
                            </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "center", width: "100%" }} spacing={2} mt={1}>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button variant="contained" 
                                        // onClick={mThandleClose} 
                                        onClick={(e)=>{
                                            mThandleClose(resetForm);
                                          }}
                                        sx={{ minWidth: 200, backgroundColor: "#2A68B3", color: "white" }} color="primary">
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Button variant="contained" onClick={handleSubmit} sx={{ minWidth: 200, backgroundColor: "#76C044", color: "white" }} color="success">
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </Dialog>
                        </>
                    )}
                </Formik>
                </Box>
                {/* marketing type modal code ends */}
            </>
        // )
    // }

    return (
        <div>
            <DefaultLayout content={ContentLayout} />
            {/* loader code start */}
            <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                // onClick={handleFreezStop}
                >
                    <Freez />
                </Backdrop>
            </div>
            {/* loader code end */}
        </div>
    );
}

export default CompanyDetails1;