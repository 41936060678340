import React,{useState,useEffect} from 'react';
import { Typography, Card, CardOverflow, CardContent } from '@mui/joy';
import { Box, Grid, TextField, Button, FormControl, InputLabel,MenuItem,Select,OutlinedInput,FormHelperText, Collapse, Alert } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';
import { useDispatch } from 'react-redux';
import { fetchData, postData } from '../../../redux/apiSlice';
import Backdrop from '@mui/material/Backdrop';
import Freez from '../../../Components/Loader/Loader';
import DefaultLayout from '../../../Components/DefaultLayout';
import AuthUser from '../../../Components/Auth/AuthUser';
import {useNavigate} from 'react-router-dom';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

function CRCLeadSimulation(props) {
    const dispatch = useDispatch();
    const { http,user } = AuthUser();
    const [stateCode, setStateCode] = useState('');
    const [states, setStates] = useState([]);
    const [checkEmailStatus, setCheckEmailStatus] = useState(false);
	const [checkCompanyStatus, setCheckCompanyStatus] = useState(false);
    const [successMsg, setSuccessMsg] = useState('');
    const [openSuccess, setOpenSuccess] = useState(false);
    const navigate = useNavigate();
    const [checkUsaTldStatus, setCheckUsaTldStatus] = useState(false);
	const [emailButton, setEmailButton] = useState(false);
//loader function and variable start
const [freez, setFreez] = React.useState(false);
const handleFreezStop = () => {
    setFreez(false);
};
const handleFreezStart = () => {
    setFreez(true);
};
//loader function and variable end 
    const fetchState =async () => {
        const apiUrl = "/fetchStates";
        const res = await dispatch(fetchData(apiUrl)).unwrap();
		if(res) {
			setStates(res.data);
		};
	}

    const handleChangeState = (event) => {
		setStateCode(event.target.value);
	};

    useEffect(() => {
        if(user.roles_id < 3) {
            fetchState();		
        }else{
            navigate('/login');
        }
	}, [])

    //Check email is already exist or not;
	const checkEmail = async (email) => {	
        if(email == '') {
            setCheckEmailStatus(false);
        }
		if (email.includes('@')) {
            
            const apiUrl = "/checkEmail";
            const args = {
                email: email,
            }
            const res = await dispatch(postData({apiUrl,args})).unwrap();
           	
            if(res){
                if (res.success) {
                    setCheckEmailStatus(true);
                } else {
                    setCheckEmailStatus(false);
                }
            }		
		}
	}
    //Check company is already exist or not;
	const checkCompany = async (company) => {
        setEmailButton(true);
        const apiUrl = "/checkCompany";
        const args = {
            company: company,
        }
        const res = await dispatch(postData({apiUrl,args})).unwrap();
        if(res){
            if (res.success) {
                setCheckCompanyStatus(true);
            } else {
                setCheckCompanyStatus(false);
            }
        }
        setEmailButton(false);
	}

    const SignupSchema = Yup.object().shape({

		firstName: Yup.string()
			.min(2, 'Too Short!')
			.max(50, 'Too Long!')
			.required('Please enter your first Name.'),

		lastName: Yup.string()
			.min(2, 'Too Short!')
			.max(50, 'Too Long!')
			.required('Please enter your last Name.'),

		email: Yup.string()
            .matches(/^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24})$/i, "Invalid email format.")
			.required('Please enter your email address.'),

		phoneNo: Yup.string()
			.min(14, 'Must be 10 digits.')
			.max(14, 'Must be 10 digits.')
			.required('Please enter your mobile number.'),

		zip: Yup.string()
			.min(5, 'Must be in 5 digits.')
			.max(5, 'Wrong zip code.')
			.required('Please enter zip code.'),

		address: Yup.string()
			.min(2, 'Too Short!')
			.max(50, 'Too Long!')
			.required('Please enter your Street Address.'),

		city: Yup.string()
			.min(2, 'Too Short!')
			.max(50, 'Too Long!')
			.required('Please enter your City.'),

		state: Yup.string()
			.min(2, 'Too Short!')
			.max(50, 'Too Long!')
			.required('Please select your State.'),

		companyName: Yup.string()
			.min(2, 'Too Short!')
			.max(50, 'Too Long!')
			.required('Please enter your Business Name.'),

	});
    const fetchLocationInfo = async (zipcode, setFieldValue) => {
        const apiUrl = "/zipcode/"+zipcode;
        setFieldValue('city','');
        setFieldValue('state','');
        if(zipcode.length == 5)
        {
            setFieldValue('city','...');
            setFieldValue('state','...');
            handleFreezStart(); 
            const res = await dispatch(fetchData(apiUrl)).unwrap();
            if(res.success)
            {
                setFieldValue('city', res.data.places[0]['place name']);
                setFieldValue('state', res.data.places[0]['state abbreviation']);  
                handleFreezStop();
            }
            else
            {
                setFieldValue('city','');
                setFieldValue('state','');
                 handleFreezStop();
            }   
        }
    };

    const checkUsaTLD = async(email) => {
        if (email.includes('@')) {
			setEmailButton(true);
            const apiUrl = '/checkUsaTLD';
            const args = {
                email: email,
            }
            const res = await dispatch(postData({ apiUrl, args })).unwrap();
            if (res) {
                if (res.success === true) {
                    setCheckUsaTldStatus(true);
                } else {
                    setCheckUsaTldStatus(false);
                }
            }
			setEmailButton(false);
        }
    }

    const ContentLayout=
            <Box>
                <Card variant="outlined" size="lg">
                    <CardOverflow
                        sx={{
                            bgcolor:'#F6FAFF',
                            borderColor:'#F6FAFF',
                            padding: '1%',
                        }}
                    >
                        <Typography level="h3" sx={{ mb: 0.5 }}>CRC Lead Request Form</Typography>
                    </CardOverflow>  
                    {
                        successMsg ? 
                        <Collapse in={openSuccess}>
                            <Alert fullWidth onClose={() => { setOpenSuccess(false) }}>{successMsg}</Alert>
                        </Collapse>                        
                        :
                        null
                    }
                    <Grid noValidate  >            
                    <Formik 
                        initialValues={{
                            firstName: '',
                            lastName: '',
                            email: '',
                            phoneNo: '',
                            companyName: '',
                            address: '',
                            city: '',
                            state: '',
                            zip: '',                            
                        }}
                        validationSchema={SignupSchema}
                       
                        onSubmit={ async (values,actions) => {
                            const loginUser = JSON.parse(localStorage.getItem('user'));
                            if(loginUser.roles_id >= 3 ) {
                                navigate('/login');
                                return false;
                            }
                            handleFreezStart(); 
                            console.log(values);
                            const apiUrl = "/storeCRCLead";
                            const args = {
                                firstName: values.firstName,
                                lastName: values.lastName,
                                companyName: values.companyName,
                                email: values.email,
                                phoneNo: values.phoneNo,
                                address: values.address,
                                city: values.city,
                                state: values.state,
                                zip: values.zip,
                            }
                            const res = await dispatch(postData({apiUrl,args})).unwrap();
                            console.log(res)
                            if(res){
                                actions.resetForm();
                                setSuccessMsg(res.message);
                                setOpenSuccess(true);
                                setTimeout(() => {
                                    setOpenSuccess(false);
                                }, 5000);                                
                            }
                            handleFreezStop();
                        }}
                        enableReinitialize={false}
                    >
                        {({values,errors,touched,handleChange,handleBlur,handleSubmit,setFieldValue}) =>(
                            <div>
                            <CardContent>
                                <Grid p={5} px={20} container alignItems="flex-start" spacing={2}>
                                    <Grid item py={2} container xs={12} spacing={2}>
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <TextField
                                                label="First Name*"
                                                type="text"
                                                fullWidth
                                                name='firstName'
                                                id='firstName'
                                                onChange={handleChange('firstName')}
                                                value={values.firstName}
                                                onBlur={handleBlur('firstName')}
                                                helperText={touched.firstName && errors.firstName ? errors.firstName : null}
												error={touched.firstName && errors.firstName ? errors.firstName : null}
                                                autoComplete="firstName"
                                            /> 

                                        </Grid>  
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <TextField
                                                label="Last Name*"
                                                type="text"
                                                fullWidth
                                                name='lastName'
                                                id='lastName'
                                                onChange={handleChange('lastName')}
                                                value={values.lastName}
                                                onBlur={handleBlur('lastName')}
                                                helperText={touched.lastName && errors.lastName ? errors.lastName : null}
												error={touched.lastName && errors.lastName ? errors.lastName : null}
                                                autoComplete="lastName"
                                            />                                
                                        </Grid>   
                                    </Grid> 
                                    <Grid item container xs={12} py={2} spacing={2}>
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <TextField
                                                label="Email*"
                                                type="text"
                                                fullWidth
                                                name='email'
                                                autoComplete="emails"
                                                id='email'
                                                onChange={(e)=>{
                                                    handleChange(e);
                                                    checkEmail(e.target.value);
                                                    checkUsaTLD(e.target.value);
                                                }}
                                                value={values.email}
                                                onBlur={handleBlur('email')}
                                                // helperText={touched.email && errors.email ? errors.email : checkEmailStatus ? "Email Address already exists." : null}
												error={touched.email && errors.email ? errors.email : checkEmailStatus ? "Email Address already exists." : null }
                                            />
                                             <div style={{ color: '#d32f2f', fontFamily: "Roboto", fontWeight: 400, fontSize: "0.90rem" }}>{checkEmailStatus ? "Email address already exists." : checkUsaTldStatus ? "Invalid email format." : ''}</div>                                          
                                        </Grid>  
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <InputMask
                                                mask='(999) 999-9999'
                                                maskChar={null}
                                                type="text"
                                                required                                                
                                                fullWidth
                                                autoComplete="phoneNo"
                                                id="phoneNo"
                                                label="Phone Number"
                                                name="phoneNo"
                                                onChange={handleChange('phoneNo')}
                                                value={values.phoneNo}
                                                onBlur={handleBlur('phoneNo')}
                                                helperText={touched.phoneNo && errors.phoneNo ? errors.phoneNo : null}
                                                error={touched.phoneNo && errors.phoneNo ? errors.phoneNo : null}
                                                >
                                                {(inputProps) =>
                                                    <TextField
                                                    label="Phone Number" variant="outlined"
                                                    {...inputProps}
                                                    
                                                    />
                                                }
                                            </InputMask>                                                                        
                                        </Grid>                                   
                                    </Grid> 
                                    <Grid item py={2} container xs={12} spacing={2}>
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <TextField
                                                label="Name of Business*"
                                                type="text"
                                                fullWidth
                                                autoComplete="companyName"
                                                name='companyName'
                                                id='companyName'
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    checkCompany(e.target.value)
                                                }}
                                                value={values.companyName}
                                                onBlur={handleBlur('companyName')}
                                                helperText={touched.companyName && errors.companyName ? errors.companyName : checkCompanyStatus ? "Company Already Registered" : null}
												error={touched.companyName && errors.companyName ? errors.companyName : checkCompanyStatus ? "Company Already Registered" : null}
                                            />                                
                                        </Grid>  
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <TextField
                                                label="Street Address*"
                                                type="text"
                                                fullWidth
                                                name='address'
                                                autoComplete="address"
                                                id='address'
                                                onChange={handleChange('address')}
                                                value={values.address}
                                                onBlur={handleBlur('address')}
                                                helperText={touched.address && errors.address ? errors.address : null}
												error={touched.address && errors.address ? errors.address : null}
                                                /> 
                                        </Grid>                        
                                    </Grid>  
                                    <Grid item py={2} container xs={12} spacing={2}>
                                        <Grid item lg={4} md={4} sm={12} xs={12}>
                                            <InputMask
                                                mask='99999'
                                                maskChar={null}
                                                label="Zip*"
                                                type="text"
                                                autoComplete="zip"
                                                fullWidth
                                                name='zip'
                                                id='zip'
                                                onChange={(e) => {
                                                    handleChange('zip')(e);
                                                    
                                                    fetchLocationInfo(e.target.value, setFieldValue);
                                                }}
                                                value={values.zip}
                                                onBlur={handleBlur('zip')}
                                                helperText={touched.zip && errors.zip ? errors.zip : null}
												error={touched.zip && errors.zip ? errors.zip : null}
                                                >
                                                {(inputProps) =>
                                                    <TextField
                                                        label="Zip Code" variant="outlined"
                                                        {...inputProps}
                                                    />
                                                    
                                                }
                                            
                                            </InputMask>
                                        </Grid>  
                                        <Grid item lg={4} md={4} sm={12} xs={12}>
                                            <TextField
                                                label="City*"
                                                type="text"
                                                fullWidth
                                                name='city'
                                                id='city'
                                                autoComplete="city"
                                                onChange={handleChange('city')}
                                                value={values.city}
                                                onBlur={handleBlur('city')}
                                                helperText={touched.city && errors.city ? errors.city : null}
												error={touched.city && errors.city ? errors.city : null}
                                            />                                
                                        </Grid>  
                                        <Grid item lg={4} md={4} sm={12} xs={12}>
                                        <FormControl fullWidth
                                                error={touched.state && errors.state ? errors.state : null}
                                            >
                                                <InputLabel id="demo-multiple-name-label">State</InputLabel>
                                                <Select
                                                    fullWidth
                                                    labelId="demo-simple-select-label"
                                                    value={values.state}
                                                    onChange={handleChange('state')}
                                                    onBlur={handleBlur('state')}
                                                    id="state"
                                                    autoComplete="state"
                                                    input={<OutlinedInput label="State" />}
                                                    name="Stastatetstatee"
                                                    error={touched.state && errors.state ? errors.state : null}
                                                    MenuProps={MenuProps}
                                                >
                                                    {states.map((state, index) => {
                                                        return (
                                                            <MenuItem key={index} value={state.state_code}>{state.state_name}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                                {touched.state && errors.state ? <FormHelperText>Please select State.</FormHelperText> : null}
                                            </FormControl> 
                                        </Grid>                                          
                                    </Grid> 
                                    <Grid item container xs={12} py={2} spacing={2}>
                                        <Grid item xs={12}>
                                            <Button variant="contained" 
                                                sx={{minWidth:250}} color="success" type="submit" 
                                                onClick={checkEmailStatus === false && checkCompanyStatus === false && checkUsaTldStatus == false && emailButton == false ? handleSubmit : ''}
                                            > Submit  </Button>
                                        </Grid>
                                    </Grid>             
                                </Grid>
                            </CardContent>
                            </div>
                        )}

                    </Formik>
                    </Grid>      
                </Card>

            </Box>
    

    return(
        <div>
            <DefaultLayout content={ContentLayout} />
             {/* loader code start */}
             <div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={freez}
                    // onClick={handleFreezStop}
                >
                    <Freez />
                </Backdrop>
            </div>
            {/* loader code end */}
        </div>
    );
}

export default CRCLeadSimulation;