import React, { useState, useEffect } from "react";
import { DialogContent, Grid, Select, FormControl, MenuItem, InputLabel, Box, TextField, Dialog, InputAdornment, DialogActions, OutlinedInput, Backdrop, DialogTitle, Tab, Tooltip } from '@mui/material';
import { Typography, Card, CardContent, CardOverflow, Button, List, ListItem, ListItemDecorator, ListItemContent } from '@mui/joy';
import AuthUser from '../Auth/AuthUser';
import config from '../../AppConfig';
import LogoEdit from '../../Components/Cobrand/LogoEdit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { QRCodeCanvas } from "qrcode.react";
import ShareIcons from './MediaShareIcons';
let logoFlag = 0;

// function UploadSnapshotLogo(props) {
    const SnapshotSocialMediaLink = ({ creditinfo,firstlogoUrlValue,companyId,receivedpid,link}) => {
    const { http, user } = AuthUser();
    const [uploadLogo, setUploadLogo] = React.useState(false);
    const [firstlogoUrl, setFirstLogoUrl] = useState(firstlogoUrlValue);
    const [copySuccess, setCopySuccess] = useState('');
    const [showIcons, setShowIcons] = useState(false);
    const handleShareClick = () => {
        setShowIcons((prev) => !prev); // Toggle the state
      };
    console.log("firstlogoUrl :"+firstlogoUrlValue);
    const handleCloseUploadLogo = (value) => {
        setUploadLogo(false);
    }

    const handleLogoURL = (image) => {
        setFirstLogoUrl(image);
    }

    const handleLogoFlag = (flag) => {
        logoFlag = flag;
    }

    return(
        <>
        <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
            
        
            {firstlogoUrl && receivedpid ? (
              <>
                <Card  sx={{width:"100%", boxShadow:"none",border:"1px solid #2E67BC"}}>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={1} mb={1} >
                        <Typography level="h5">Here Is Your Credit Snapshot Link</Typography>
                    </Grid>
                    <Card sx={{width:"100%",boxShadow:" 0 0px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19)",borderRadius:"10px"}}>
                        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2} p={0}>
                            <Grid item lg={9} md={9} sm={9} xs={9} className="link-word-wrap">
                                {link}
                            </Grid>
                            <Grid item lg={3} md={3} sm={3} xs={3} sx={{ textAlign: "right" }} pr={2}  >
                                <Tooltip title="Copy">
                                <ContentCopyIcon
                                    onClick={() => {
                                    navigator.clipboard.writeText(link);
                                    setCopySuccess(link);
                                    setTimeout(() => {
                                        setCopySuccess("");
                                    }, 3000);
                                    }}
                                    disabled={copySuccess === link}
                                    sx={{ cursor: "pointer" }}
                                />
                                </Tooltip>
                                {copySuccess === link ? "Copied!" : ""}
                            </Grid>
                        </Grid>
                    </Card>
                    <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                        <Typography>
                            Copy Your Credit Snapshot Link To Post On Social Media Or Share With Your Client.
                        </Typography>
                    </Grid>
                </Card>
                
                <Grid container item lg={12} md={12} sm={12} xs={12} mt={2} >
                    <Card  sx={{width:"100%", boxShadow:"none",border:"1px solid #2E67BC"}}>
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                            <Grid item lg={2} md={2} sm={4} xs={4}>
                                    <QRCodeCanvas
                                    id="qrCodeCanvas"
                                    value={link}
                                    size={150}
                                    />
                            </Grid>
                            <Grid item lg={10} md={10} sm={8} xs={8}>
                                <Grid container item lg={12} md={12} sm={12} xs={12} sx={{alignItems: "center",justifyContent:"center", display: "flex"}}>
                                    <Grid item lg={2} md={2} sm={12} xs={12}>
                                        <Button  className="CustomButton" size="lg" sx={{ minWidth: 200}} onClick={handleShareClick}  >SHARE</Button>
                                    </Grid>
                                    
                                    <Grid item lg={10} md={10} sm={12} xs={12}>
                                    {showIcons ? 
                                        <ShareIcons/>
                                    :""}
                                    </Grid>
                                </Grid>
                                <Grid container item lg={12} md={12} sm={12} xs={12} mt={1}>
                                    <Button  className="CustomButton" size="lg" sx={{ minWidth: 200}}
                                    onClick={() => {
                                        const canvas = document.getElementById("qrCodeCanvas");
                                        const url = canvas.toDataURL("image/png");
                                        const link = document.createElement("a");
                                        link.href = url;
                                        link.download = "QRCode.png";
                                        link.click();
                                    }}>SAVE</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
              </>
            ) : <>
            <Typography level="h5"><b>Complete "Setup Credit Snapshot" Step First</b></Typography></>}
         
        </Grid>
        
        
        <Dialog open={uploadLogo} onClose={handleCloseUploadLogo} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <LogoEdit id={companyId} logoEditURL={handleLogoURL} logoFlagEdit={handleLogoFlag} setOpen={handleCloseUploadLogo} source="company"/>
        </Dialog>
      </>      
    );
}

export default SnapshotSocialMediaLink;