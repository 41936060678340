import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import axios from "axios";
import Chatbot from "react-chatbot-kit";
import ActionProvider from "./ActionProvider";
import MessageParser from "./MessageParser";
import Box from "@mui/material/Box";
import AuthUser from "../Auth/AuthUser";
import config from "../../AppConfig";
import chatConfig from "./chatConfig";
import "react-chatbot-kit/build/main.css";
import { createChatBotMessage } from 'react-chatbot-kit';

import IconButton from '@mui/material/IconButton';
import SendIcon from "@mui/icons-material/Send";
import {
	TextField,
  } from '@mui/material';
let emailValue = "";
let role = null;

const ChatbotComponent = forwardRef((props, ref) => {
  const [sessionId, setSessionId] = useState(localStorage.getItem("chatSessionId") != undefined ? localStorage.getItem("chatSessionId")  : null);
  const [isChatbotVisible, setIsChatbotVisible] = useState(false);
  const [chatState, setChatState] = useState([]);
  const { http, user } = AuthUser();
	const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const handleSendMessage = () => {
	if (input.trim()) {
	  setMessages([...messages, { text: input, type: "user" }]);
	  setInput("");

	  // Simulate bot response
	  setTimeout(() => {
		setMessages((prev) => [
		  ...prev,
		  { text: "Hello! How can I assist you?", type: "bot" },
		]);
	  }, 1000);
	}
  };
  useImperativeHandle(ref, () => ({
    toggleChatbot,
  }));

  useEffect(() => {
    const initializeChatSession = async () => {
      try {
        if (user && user.email) {
          emailValue = user.email;
          role = user.roles_id;
        }
        if (localStorage.getItem("chatbot_email") != null) {
          emailValue = localStorage.getItem("chatbot_email");
		  console.log("emailValue:"+emailValue);
          role = null;
        }
        // localStorage.setItem("chatbot_email", emailValue);
        const response = await axios.post(
          config.API_BASE_URL + "/initialize-chat-session",
          { email: emailValue, role: role }
        );
        setSessionId(response.data.session_id); // Set sessionId from API response
		// setSessionId("12345");
        localStorage.setItem("chatSessionId", response.data.session_id);
        console.log("session:" + response.data.session_id);
      } catch (error) {
        console.error("Error initializing session:", error);
      }
    };

    if (isChatbotVisible && !sessionId) {
      initializeChatSession();
    }
  }, [isChatbotVisible, sessionId]);
  
  useEffect(() => {
    const fetchNewMessages = async () => {
      try {
		if(sessionId != null) {
			const response = await http.get(`/fetchNewMessages/${sessionId}`);
			if (response.data.success) {
			  const newMessages = response.data.data.map((msg) =>
				createChatBotMessage(msg.message, { sender: "bot" })
			  );
	
			  if (newMessages.length > 0) {
				setChatState((prevChatState) => [...prevChatState, ...newMessages]);
			  }
			}
		}
        
      } catch (error) {
        console.error("Error fetching new messages:", error);
      }
    };

    const interval = setInterval(() => {
      fetchNewMessages();
    }, 5000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [sessionId]);

  useEffect(() => {
    if (isChatbotVisible) {
      setChatState([...chatConfig.initialMessages]);
    }
  }, [isChatbotVisible]);

  const toggleChatbot = () => {
	console.log("called")
    setIsChatbotVisible((prevState) => !prevState);
  };

  return (
	<Box
	sx={{
	  position: "fixed",
	  bottom: 20,
	  right: 20,
	  zIndex: 1000,
	}}
  >
	<img
	  src={require("../../assets/images/chatforbutton.png")}
	  alt="Chatbot Toggle"
	  onClick={toggleChatbot}
	  style={{
		cursor: "pointer",
		width: "50px",
		height: "50px",
		borderRadius: "50%",
		boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
		backgroundColor: "#fff",
	  }}
	/>
	{isChatbotVisible && sessionId && chatState.length > 0 && (
	  <Box
		sx={{
		  position: "fixed",
		  bottom: "60px",
		  right: 20,
		  boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
		  borderRadius: "8px",
		  backgroundColor: "#fff",
		  zIndex: 9999,
		//   height: "400px", 
		  display: "flex",
		  flexDirection: "column",
		//   width: "400px", 
		}}
	  >
		<div
		  className="chatbot-container"
		  style={{
			flex: 1, 
			overflowY: "auto", 
			boxSizing: "border-box",
			// width:"400px"
		  }}
		>
		  <Chatbot
			key={isChatbotVisible ? "visible" : "hidden"}
			config={{
			  ...chatConfig,
			}}
			messageParser={MessageParser}
			actionProvider={ActionProvider}
			saveMessages={setChatState}
			messageHistory={chatState}
		  />
		</div>
		{/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "10px",
              borderTop: "1px solid #ddd",
            }}
          >
            <TextField
              fullWidth
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="Type your message"
              variant="outlined"
              size="small"
            />
            <IconButton
              sx={{
                marginLeft: "10px",
                border: "1px solid #3779eb",
                borderRadius: "6px",
                backgroundColor: "#435AC2 !important",
                color: "white",
                padding: "10px",
              }}
              onClick={handleSendMessage}
            >
              <SendIcon sx={{ fontSize: "1.7rem" }} />
            </IconButton>
          </Box> */}
        
	  </Box>
	)}
  </Box>
  );
});

export default ChatbotComponent;
