class MessageParser {
  constructor(actionProvider, state) {
    this.actionProvider = actionProvider;
    this.state = state;
  }

  parse(message) {
    const trimmedMessage = message.trim();

    const userMessage = {
      message,
      type: "user",
      sender: "user",
      id: Date.now(),
    };

    if (!trimmedMessage) {
      this.actionProvider.handleBotMessage("Please enter a valid message.");
      return;
    }

    // // Process the valid message
    // this.actionProvider.handleApiResponse(trimmedMessage);
    // this.actionProvider.checkRole();

    const lowerCaseMessage = message.toLowerCase();
    if (this.state.isAffiliate && this.state.isFirstMessage) {
      console.log("User's first message:", message);
      // Update the state to mark that the first message is handled
      this.state.isFirstMessage = false;
      this.actionProvider.updateAffiliateName(trimmedMessage);
    }
    // else if (lowerCaseMessage.includes("fair")) {
    //   this.actionProvider.handleReactQuestion();
    // } else if (lowerCaseMessage.includes("very good")) {
    //   this.actionProvider.handleChatbotQuestion();
    // } else if (lowerCaseMessage.includes("excellent")) {
    //   this.actionProvider.handleUsageQuestion();
    // } 
    else {
      // this.actionProvider.handleUnknownQuestion();
    this.actionProvider.handleApiResponse(trimmedMessage);
    this.actionProvider.checkRole();
    }

    // this.actionProvider.updateChatState(userMessage);
  }
}

export default MessageParser;